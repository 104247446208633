/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Loading Container
 *
 * @file LoadingContainer.jsx
 * @author Gladson Samuel S
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const LoadingContainer = ({
  isReadyToRender,
  loader,
  children
}) => (
  <Fragment>
    {isReadyToRender
      ? (
        <Fragment>
          {children}
        </Fragment>
      )
      : (
        <Fragment>
          {loader}
        </Fragment>
      )
    }
  </Fragment>
);

LoadingContainer.defaultProps = {
  isReadyToRender: false
};

LoadingContainer.propTypes = {
  isReadyToRender: PropTypes.bool,
  loader: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default LoadingContainer;
