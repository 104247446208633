/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * QA-INT environment overrides.
 *
 * @author Hari Gangadharan
 */

const qaInt = {
  ENVIRONMENT: 'qaInt',
  PARTNER_ENV: 'qaInt',
  GTM_AUTH: 'neDCHn1snhB9TDBHGb9iJA',
  GTM_PREVIEW: 'env-4',
  CONSOLE_URL: 'https://console-qa.pearsoned.com',
  PRISM_API_BASE_URL: 'https://prism-qa.pearsoned.com/',
  MARIN_API_BASE_URL: 'https://plus-qa-int.pearson.com/marin/api/1.0',
  STPAPER_API_BASE_URL: 'https://stpaperapiqa.stg-prsn.com/etext/v2',
  ZEPPELIN_URL: 'https://zeppelin-qa.dev-openclass.com/products',
  TENANT_ID_ETEXT: '2b4af3fc-97b4-41a3-af7e-bc9354d35fed',
  TENANT_KEY_ETEXT: '28da38d3-5160-4ce2-b90b-597a5fefbd40',
};

export default qaInt;
