/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateD
 *
 * @file TemplateD.jsx
 * @author Manimaran S
 */

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, CardContent, Popper, Typography, CardMedia, Button, Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TemplateDStyles from './styles/TemplateDStyles';
import CommonUtils from '../../../common/utils/CommonUtils';
import { OPEN_EREADER_COMPONENT } from '../../../common/constants';

const TemplateD = ({ data, callbackForBraze }) => {
  const {
    styles,
    title,
    description,
    arrowPosition,
    backgroundImage,
    backgroundColor,
    action,
    campaignClickAction,
    targetSelector,
    showDismiss
  } = data;
  const {
    onViewCard, onDismiss, logContentCardClick, openEreaderComponentFromBraze
  } = callbackForBraze;
  const classes = TemplateDStyles(styles)();
  const [openCard, setOpenCard] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);

  const dismiss = (event = null) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenCard(false);
    onDismiss();
  };

  const handleCtaClick = (actionData) => {
    const {
      target, launchURL, allowDismiss, logClickEvent, type, componentToShow
    } = actionData;

    if (logClickEvent) {
      logContentCardClick();
    }
    if (type && componentToShow && type.toUpperCase() === OPEN_EREADER_COMPONENT) {
      openEreaderComponentFromBraze(componentToShow);
    } else if (launchURL) {
      window.open(launchURL, target || '_self');
    }
    if (allowDismiss) {
      dismiss();
    }
  };

  const checkUpdates = () => new Promise(() => {
    // eslint-disable-next-line no-undef
    const observer = new MutationObserver((mutations) => {
      if (mutations) {
        const element = document.querySelector(targetSelector);
        if (element) {
          setAnchorElement(element);
        }
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });

  useEffect(() => {
    CommonUtils.waitForElm(targetSelector).then((element) => {
      setAnchorElement(element);
      setOpenCard(true);
      checkUpdates();
    });
    if (data) {
      onViewCard();
    }
  }, [data && data.show]);

  return (
    data && (
    <Popper
      className={classes.popper}
      style={{ zIndex: 1299 }}
      placement={arrowPosition}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport'
        },
        offset: {
          enabled: true,
          offset: '0,15'
        }
      }}
      open={openCard}
      anchorEl={anchorElement}
      onClick={() => (campaignClickAction ? handleCtaClick(campaignClickAction) : null)}
    >
      <>
        <span key={0} className={classes.arrow} ref={setArrowRef} />
        <Box>
          {showDismiss
            && (
            <IconButton onClick={event => dismiss(event)} size="small" classes={{ root: classes.iconRoot }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
            )
          }
          <CardMedia
            classes={{ root: classes.cardMedia }}
            style={{ backgroundColor }}
            image={backgroundImage}
          >
            <CardContent className={classes.rootCardContent}>
              <div className={classes.contentCardContanier}>
                <div className={classes.contentArea}>
                  {title && (
                    <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                      {title}
                    </Typography>
                  )}
                  {description && (
                  <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
                    {description}
                  </Typography>
                  )}
                  {action && action.map((actionData, index) => (
                    <Button
                      classes={{ root: classes.buttonContainer }}
                      aria-label={`${actionData.text}`}
                      role="link"
                      key={index}
                      onClick={() => handleCtaClick(actionData)}
                    >
                      {actionData.text}
                    </Button>
                  ))}
                </div>
              </div>
            </CardContent>
          </CardMedia>
        </Box>
      </>
    </Popper>
    )
  );
};

TemplateD.defaultProps = {
  data: {
    styles: {},
    imageUrl: '',
    backgroundImage: '',
    action: [],
    campaignClickAction: {},
    showDismiss: false
  },
  callbackForBraze: {
    onViewCard: () => {},
    onDismiss: () => {},
    openEreaderComponentFromBraze: () => {}
  }
};

TemplateD.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    styles: PropTypes.object,
    position: PropTypes.string,
    backgroundImage: PropTypes.string,
    action: PropTypes.array,
    campaignClickAction: PropTypes.object,
    showDismiss: PropTypes.bool
  }).isRequired,
  callbackForBraze: PropTypes.shape({
    onViewCard: PropTypes.func,
    onDismiss: PropTypes.func,
    openEreaderComponentFromBraze: PropTypes.func
  }).isRequired
};

export default memo(TemplateD);
