/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';

import {
  DISPLAY_ORDER_CONTENT_REQUESTED,
  DISPLAY_ORDER_CONTENT_SUCCESS,
  DEFAULT_TEMPLATE
} from '../../../common/constants';

/**
* @Author Mohamed yasar arabath M
*/

export default class DisplayOrderService extends BaseService {
  constructor() {
    super(DISPLAY_ORDER_CONTENT_REQUESTED, 'dispalyordestatus');
    this.error = false;
    this.getPersonalisedTemplate = '';
  }

  handleEvent(eventName, event) {
    const {
      locale, templateVariant
    } = event;

    this.getPersonalisedTemplate = '';
    if (templateVariant && templateVariant !== DEFAULT_TEMPLATE) {
      this.getPersonalisedTemplate = `&version=${templateVariant}`;
    }
    return this.getClient('marin').get(`/templates/home?locale=${locale}${this.getPersonalisedTemplate}`);
  }

  handleResponse(response) {
    this.error = false;
    const { data } = response;
    if (data) {
      const version = this.getPersonalisedTemplate;
      Framework.getEventManager().publish(DISPLAY_ORDER_CONTENT_SUCCESS, data, version);
    }
  }

  handleError(error) {
    this.error = true;
    Framework.getEventManager().publish(DISPLAY_ORDER_CONTENT_SUCCESS, {});
    console.log(error, 'displayorder Content service error');
  }
}
