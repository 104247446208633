/**

 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc. All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/**
 * Bread crumbs component
 *
 * @file const LibraryRightSideTabs.jsx
 * @author Ujjwal Sana
 */


import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const LibraryRightSideTabs = ({
  libraryL2Tabs,
  activeLibraryL2Tab,
  handleLibraryTabClick
}) => (
  <div className='l2-parent-wrapper'>
    <div className="libraryL2-tab-button-wrapper">
      {libraryL2Tabs.map((tab, index) => (
        <Button
          className={
            activeLibraryL2Tab?.toLowerCase() === tab?.id?.toLowerCase()
              ? 'libraryL2-modal-tab-button'
              : 'libraryL2-modal-tab-button library-tab-inactive-gradient'
          }
          onClick={() => { handleLibraryTabClick(tab.id); }}
        >
          {tab.name}
          <span className="libraryL2-tab-count">
            {tab.count}
          </span>
        </Button>
      ))}
    </div >
  </div>
);


LibraryRightSideTabs.propTypes = {
  libraryL2Tabs: PropTypes.array.isRequired,
  activeLibraryL2Tab: PropTypes.string.isRequired,
  handleLibraryTabClick: PropTypes.func.isRequired
};


export default LibraryRightSideTabs;
