/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { BaseService } from '@greenville/framework';
import { osName } from 'react-device-detect';
import TimeOnTaskUtils from '../utils/TimeOnTaskUtils';
import env from '../env';
import * as constants from '../constants';
import CommonUtils from '../utils/CommonUtils';
/**
 * A service to fetch the course details.
 * @author Sarathkumar V
 */

const APP_ACTIVITY_AREA_CODE = 'Learning';
const COURSE_TYPE_REGISTRAR = 'Registrar';
const COURSE_TYPE_SMS = 'SMS';
const DEVICE_TYPE = 'Desktop';
const LOAD_MESSAGE_VERSION = '1.8.0';
const MESSAGE_TRANSFER_TYPE = 'LiveStream';
const NAMESPACE = 'common';
const NOT_AVAILABLE = 'NotAvailable';
const ORGANIZATION_ID_TYPE = 'Organization';
const PERSON_ID_TYPE = 'PI';
const PRODUCT_ROLE = 'Learner';
const SOURCE_CODE = 'Client';
const STUDENT = 'Student';
const UNLOAD = 'Unload';
const UNLOAD_MESSAGE_VERSION = '1.10.0';
const WEBAPP = 'WebApp';

export default class AutobahnTimeOnTaskService extends BaseService {
  constructor() {
    super(constants.AUTOBAHN_TIMEONTASK);
    this.loadDate = '';
    this.loadUuid = '';
  }

  createPayload = (
    type,
    id,
    loadDate,
    loadUuid,
    data,
    userId,
    isBundled,
    isFromCourse,
    businessModelCode,
    unloadDate = null,
    playerModeCode,
    productId,
    pearsonSubscriptionId,
    pearsonSubscriptionTierCode,
    loadDtVal,
    userLocalDtVal,
    durationInSeconds,
    bookId,
    courseSectionTitle,
    pageTitle,
    productModelName,
    productPlatformCode,
    contentType,
    contentTitle,
    pageUserNavigatedFromUrn,
    pageUserNavigatedToUrn
  ) => {
    /**
     * data - Coursaware launches role is based on marin api response
     * STUDENT - Non-courseware launches default to student
     */
    const roleCode = (data && data.role && TimeOnTaskUtils.ucFirst(data.role)) || STUDENT;
    const requestPayload = {
      originatingSystemCode: env.PRODUCT.toUpperCase(),
      activities: [{
        messageTypeCode: type,
        messageVersion: type === constants.USER_LOADS_CONTENT ? LOAD_MESSAGE_VERSION : UNLOAD_MESSAGE_VERSION,
        namespace: NAMESPACE,
        payload: {
          accessedUsingAppId: WEBAPP,
          appActivityAreaCode: APP_ACTIVITY_AREA_CODE,
          appId: env.PRODUCT && env.PRODUCT.toLowerCase(),
          contentId: id,
          contentIdType: env.PRODUCT.toLowerCase(),
          datetimeSourceCode: SOURCE_CODE,
          deviceType: DEVICE_TYPE,
          environmentCode: env.ENVIRONMENT.toUpperCase(),
          loadDt: loadDtVal,
          messageId: CommonUtils.getUUID(),
          messageTransferType: MESSAGE_TRANSFER_TYPE,
          messageTypeCode: type,
          messageVersion: type === constants.USER_LOADS_CONTENT ? LOAD_MESSAGE_VERSION : UNLOAD_MESSAGE_VERSION,
          namespaceCode: TimeOnTaskUtils.ucFirst(NAMESPACE),
          operatingSystemCode: window.navigator.platform,
          originatingSystemCode: env.PRODUCT.toUpperCase(),
          personId: userId,
          personIdType: PERSON_ID_TYPE,
          personRoleCode: roleCode,
          transactionDt: unloadDate || loadDate,
          timeCategorization: APP_ACTIVITY_AREA_CODE,
          timeOnTaskUuid: loadUuid,
          userAgent: window.navigator.userAgent,
          userLocalLoadDt: userLocalDtVal,
          userTimeZoneOffsetNum: Math.abs(new Date().getTimezoneOffset() / 60),
          gaClientId: env.CLIENT_ID,
          loginSessionId: window.piSession && window.piSession.getContextId(),
          sessionId: window.piSession && window.piSession.getContextId(),
          pageUrn: (window.location.href).split('?')[0],
          contentLearningMode: constants.CONTENT_LEARNING_MODE,
          deviceBrandName: osName,
          pageVisitId: id,
          bookId,
          playerModeCode, // Static Will change once will get data, Ex: reading vs listening
          productId,
          pageTitle,
          productModelName,
          productPlatformCode,
          contentType,
          contentTitle
        }
      }]
    };

    if (isFromCourse) {
      requestPayload.activities[0].payload.courseId = data && data.id;
      requestPayload.activities[0].payload.courseSectionId = data && data.id;
      requestPayload.activities[0].payload.courseIdType = isBundled ? COURSE_TYPE_SMS : COURSE_TYPE_REGISTRAR;
      requestPayload.activities[0].payload.courseSectionIdType = isBundled ? COURSE_TYPE_SMS : COURSE_TYPE_REGISTRAR;
    }
    if (data && data.organizationId) {
      requestPayload.activities[0].payload.organizationId = data.organizationId;
      requestPayload.activities[0].payload.organizationIdType = ORGANIZATION_ID_TYPE;
    }
    if (courseSectionTitle) {
      requestPayload.activities[0].payload.courseSectionTitle = courseSectionTitle;
    }
    if (pageUserNavigatedFromUrn) {
      requestPayload.activities[0].payload.pageUserNavigatedFromUrn = pageUserNavigatedFromUrn;
    }
    if (pageUserNavigatedToUrn) {
      requestPayload.activities[0].payload.pageUserNavigatedToUrn = pageUserNavigatedToUrn;
    }
    if (pearsonSubscriptionId) {
      requestPayload.activities[0].payload.pearsonSubscriptionId = pearsonSubscriptionId;
    }
    if (pearsonSubscriptionTierCode) {
      requestPayload.activities[0].payload.pearsonSubscriptionTierCode = pearsonSubscriptionTierCode;
    }
    if (type !== constants.USER_LOADS_CONTENT) {
      requestPayload.activities[0].payload.unloadDt = unloadDate;
      requestPayload.activities[0].payload.unloadTimestampActivityCode = UNLOAD;
      requestPayload.activities[0].payload.unloadCausedByCode = roleCode;
      requestPayload.activities[0].payload.durationInSeconds = durationInSeconds;
      requestPayload.activities[0].payload.userLocalUnloadDt = userLocalDtVal;
    }
    if (businessModelCode) {
      requestPayload.activities[0].payload.businessModelCode = businessModelCode;
      const businessModelCodeType = [
        constants.DIRECT_TO_LEARNER,
        constants.DIRECT_TO_LEARNER_RENEWAL,
        constants.CG_RENEWALS,
        constants.DIRECT_TO_CONSUMER
      ];

      if (businessModelCodeType.includes(businessModelCode)) {
        requestPayload.activities[0].payload.personRoleCode = PRODUCT_ROLE;
        if (type !== constants.USER_LOADS_CONTENT) {
          requestPayload.activities[0].payload.unloadCausedByCode = PRODUCT_ROLE;
        }
      }
    }

    return requestPayload;
  };

  handleEvent(eventName, event) {
    let unloadDate = '';
    const {
      type,
      pageId,
      course,
      product,
      userId,
      isBundled,
      isFromCourse,
      businessModelCode,
      playerModeCode,
      productId,
      pearsonSubscriptionId,
      pearsonSubscriptionTierCode,
      loadUuid,
      loadDt,
      userLocalDt,
      durationInSeconds,
      bookId,
      courseSectionTitle,
      pageTitle,
      productModelName,
      productPlatformCode,
      contentType,
      contentTitle,
      pageUserNavigatedFromUrn,
      pageUserNavigatedToUrn
    } = event;

    if (type === constants.USER_LOADS_CONTENT) {
      this.loadUuid = loadUuid;
      this.loadDate = new Date().toISOString();
    } else {
      unloadDate = new Date().toISOString();
    }
    const data = isFromCourse ? course : product;
    const payLoad = this.createPayload(
      type,
      pageId,
      this.loadDate,
      this.loadUuid,
      data,
      userId,
      isBundled,
      isFromCourse,
      businessModelCode,
      unloadDate,
      playerModeCode,
      productId,
      pearsonSubscriptionId,
      pearsonSubscriptionTierCode,
      loadDt,
      userLocalDt,
      durationInSeconds,
      bookId,
      courseSectionTitle,
      pageTitle,
      productModelName,
      productPlatformCode,
      contentType,
      contentTitle,
      pageUserNavigatedFromUrn,
      pageUserNavigatedToUrn
    );

    return this.getClient('stpaper').post('/courseboot/pla/autobahn/messages/pageLoadUnload', payLoad);
  }
}
