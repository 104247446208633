/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { makeStyles } from '@material-ui/core';

/**
 * TemplateAStyles
 *
 * @file TemplateAStyles.jsx
 * @author Manimaran S
 */

const TemplateAStyles = (styles = {}) => makeStyles({
  snackbar: {
    zIndex: 1300
  },
  iconRoot: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  contentCardContanier: {
    display: 'flex'
  },
  rootCardContent: {
    padding: '0 !important'
  },
  contentArea: {
    width: 200,
    wordWrap: 'break-word',
    alignSelf: 'center'
  },
  positionCenter: {
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    bottom: 'auto',
    right: 'auto'
  },
  positionBottomRight: {
    top: 'auto',
    right: 0,
    left: 'auto',
    bottom: 0
  },
  positionTopRight: {
    top: 0
  },
  ...styles
});
export default TemplateAStyles;
