/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Srinivasan Bakthavatchalam
 */

import Framework from '@greenville/framework';
import { getSnapshot } from 'mobx-state-tree';
import CommonUtils from '../../../common/utils/CommonUtils';
import * as constants from '../../../common/constants';

export default class BannersPlugin {
  constructor(context) {
    this.authHomeContext = context;
  }


  /**
   * Check if the banner data is present, if yes then
   * execute the rules inside banner data. This rules
   * will decide which banner to be displayed in library card
   *
   */
  setBookBanner = () => {
    const { bookshelf } = this.authHomeContext.props;
    const bannerData = this.authHomeContext.authHomeStackOrderData?.library?.bannerData;
    if (bannerData) {
      const bookShelfData = getSnapshot(bookshelf);
      const booksChannelMapping = [];
      if (bookShelfData.books.length > 0) {
        const booksBannerMapping = bookShelfData.books.map((book) => {
          if (CommonUtils.isActiveBook(book)) {
            booksChannelMapping.push(book);
          }
          const renderBook = { ...book };
          // setting current book context in template plugin
          this.authHomeContext.templatesPlugin.setCurrentBookContext(renderBook);
          // setting current subscription context in template plugin
          this.authHomeContext.templatesPlugin.setSubscriptionContext();
          const subscriptionBookData = this.authHomeContext.templatesPlugin.getSlides(bannerData).pop();
          if (subscriptionBookData) {
            const {
              id, template, title, secondaryTitle, description, action
            } = subscriptionBookData;
            renderBook.bannerData = {
              id, template, title, secondaryTitle, description, action
            };
          }

          return { ...renderBook };
        });
        const updatedbookshelf = { ...this.bookShelfData, books: booksBannerMapping };
        Framework.getStoreRegistry().getStore('bookshelf').set(updatedbookshelf);
      }
    }
  }

  /**
    * Handles subscription banners new GA event
    *
    * @param {*} id
    * @param {*} locationInApp
    * @param {*} isClicked
    */
  handleSubscriptionBannerNewGAEvent = (id, locationInApp, isClicked = false) => {
    const {
      MESSAGE_CENTER_AND_SUBSCRIPTION_BANNER_EVENT_LABEL,
      AUTH_HOME_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      LIBRARY_EVENT
    } = constants;
    const customFields = {
      location_in_app: locationInApp,
      is_ghost_account: CommonUtils.isGhostAccount()
    };
    const eventLabel = MESSAGE_CENTER_AND_SUBSCRIPTION_BANNER_EVENT_LABEL[id];

    if (eventLabel && isClicked) {
      CommonUtils.dispatchGaEvent(
        AUTH_HOME_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION,
        eventLabel,
        customFields
      );
    }
  }

  /**
   * Sets header banner
   *
   */
  getHeaderBannerData = () => {
    const banners = this.authHomeContext.authHomeStackOrderData?.header?.banners;
    let headerBannerData = null;

    if (banners) {
      this.authHomeContext.templatesPlugin.setAuthHomeContext();
      const validBanners = this.authHomeContext.templatesPlugin.getSlides(banners);

      if (validBanners?.length > 0) {
        headerBannerData = validBanners[0];
        this.authHomeContext.changeHeroCTAIfCRBanner({ data: headerBannerData });

        return headerBannerData;
      }
    }

    return headerBannerData;
  }

  /**
   * Function to handle header banner CTA
   * @param {*} actionData
   */
  handleHeaderBannerCTAClick = (actionData) => {
    const {
      target, launchURL
    } = actionData;
    const {
      BANNERS,
      AUTH_HOME_CATEGORY,
      EVENT_AUTHHOMEEVENT
    } = constants;

    CommonUtils.dispatchGaEvent(
      AUTH_HOME_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      BANNERS.CODE_REDEEMED.click_event_action,
      BANNERS.CODE_REDEEMED.event_label,
      {
        is_ghost_account: CommonUtils.isGhostAccount()
      }
    );
    if (launchURL) {
      window.open(launchURL, target || '_self');
    }
  };
}
