/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to RecommendedTopic
 *
 * @file RecommendedTopic.js
 * @author Gladson Samuel S S
 */

import { types } from 'mobx-state-tree';

const RecommendedTopic = types.model('recommendedTopic', {
  chapterTitle: types.maybeNull(types.string),
  chapterSeoUrl: types.maybeNull(types.string),
  topicTitle: types.maybeNull(types.string),
  topicSeoUrl: types.maybeNull(types.string),
  thumbUrl: types.maybeNull(types.string),
  topicDuration: types.maybeNull(types.string),
  numOfAssets: types.maybeNull(types.number),
  tutorSeoUrl: types.maybeNull(types.string),
  progress: types.maybeNull(types.number),
  assetId: types.maybeNull(types.string),
  topicId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  url: types.maybeNull(types.string)
});

export default RecommendedTopic;
