/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../common/constants';

/**
* Author Poornima N
*/
const LaunchLogItem = types.model(
  'LaunchLogItem',
  {
    serverUpdatedDateTime: types.maybeNull(types.string),
    isImpersonated: types.maybeNull(types.boolean),
    deviceId: types.maybeNull(types.string),
    deliveryChannel: types.maybeNull(types.string)
  }
);
const LaunchLog = types.model(
  'LaunchLog',
  {
    itemsCode: types.array(LaunchLogItem)
  }
).actions(self => ({
  fetch() {
    Framework.getEventManager().publish(constants.LAUNCH_LOG_FETCHED, {});
  },

  set(launchLog) {
    applySnapshot(self, { itemsCode: launchLog });
    Framework.getEventManager().publish(constants.LAUNCH_LOG_FETCHED, {});
  },

  setCodeHistoryAsEmpty() {
    applySnapshot(self, { itemsCode: [] });
    Framework.getEventManager().publish(constants.LAUNCH_LOG_FETCHED, {});
  }
}));

export default LaunchLog;
