/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Stage environment overrides.
 *
 * @author Hari Gangadharan
 */

const stg = {
  // GTM config to be uncommented once the Mojo changes has been pushed to STG
  /* GTM_AUTH: 'BhCbCynvIifJE48PjY0B8Q',
  GTM_PREVIEW: 'env-5', */
  NEWRELIC_ENABLED: true,
  ELIGIBILITY_API_BASE_URL: 'https://api-c-pgi.pearson.com/eu/mojo-uat/v1/',
  CHANNELS_MAPPING_URL: 'https://api.studychannel-dev.pearsondev.tech/v1',
  CHANNELS_MAPPING_URL_TIMEOUT: 10000
};
export default stg;
