/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Couse module index to define initial state and header for API
 *
 * @file index.jsx
 * @author Hari Gangadharan
 */

import React from 'react';
import Framework from '@greenville/framework';

import CourseService from './services/CourseService';
import AssetService from './services/AssetService';
import AutobahnTimeOnTaskService from '../../common/services/AutobahnTimeOnTaskService';
import CourseListService from './services/CourseListService';
import Course from './model/Course';
import Asset from './model/Asset';
import CourseListData from './model/CourseListData';
import CourseContainer from './CourseContainer';
import GlossaryHotspot from './model/GlossaryHotspot';
import GlossaryHotspotService from './services/GlossaryHotspotService';
import PdfHotspot from './model/PdfHotspot';
import PdfHotspotService from './services/PdfHotspotService';

import env from '../../common/env';
import { X_AUTHORIZATION } from '../../common/constants';


Framework.getClientManager().add({
  name: 'marin',
  authHeader: 'authorization',
  timeout: env.MARIN_API_TIMEOUT,
  baseUrl: env.MARIN_API_BASE_URL,
  tokenPrefix: 'Bearer '
})
  .add({
    name: 'prism',
    authHeader: X_AUTHORIZATION,
    timeout: env.PRISM_API_TIMEOUT,
    baseUrl: env.PRISM_API_BASE_URL,
    tokenPrefix: ''
  })
  .add({
    name: 'stpaper',
    authHeader: X_AUTHORIZATION,
    timeout: env.STPAPER_API_TIMEOUT,
    baseUrl: env.STPAPER_API_BASE_URL,
    tokenPrefix: ''
  })
  .add({
    name: 'spectrum',
    authHeader: X_AUTHORIZATION,
    timeout: env.SPECTRUM_API_TIMEOUT,
    baseUrl: env.SPECTRUM_API_BASE_URL,
    tokenPrefix: ''
  })
  .add({
    name: 'spectrumV4',
    authHeader: X_AUTHORIZATION,
    timeout: env.SPECTRUM_API_TIMEOUT,
    baseUrl: env.SPECTRUM_API_V4_BASE_URL,
    tokenPrefix: ''
  })
  .add({
    name: 'etext',
    authHeader: X_AUTHORIZATION,
    timeout: env.ETEXT_API_TIMEOUT,
    baseUrl: env.ETEXT_API_BASE_URL,
    tokenPrefix: ''
  })
  .add({
    name: 'csg',
    authHeader: X_AUTHORIZATION,
    timeout: env.CSG_API_TIMEOUT,
    baseUrl: env.CSG_API_BASE_URL,
    tokenPrefix: ''
  })
  .add({
    name: 'pmc',
    timeout: env.PMC_API_TIMEOUT,
    baseUrl: env.PMC_API_BASE_URL
  })
  .add({
    name: 'marinV2',
    authHeader: 'authorization',
    timeout: env.MARIN_API_TIMEOUT,
    baseUrl: env.MARIN_API_BASE_URL_V2,
    tokenPrefix: 'Bearer '
  })
  .add({
    name: 'channelMap',
    timeout: env.PMC_API_TIMEOUT,
    baseUrl: env.CHANNELS_MAPPING_URL
  });

export default {
  path: [
    '/courses/:id',
    '/courses/:id/contents',
    '/courses/:id/pages/:pageId?',
    '/courses/:id/products/:productId',
    '/courses/:id/products/:productId/pages/:pageId?',
    '/courses/:id/products/:productId/bundles/:bundleId',
    '/courses/:id/products/:productId/bundles/:bundleId/pages/:pageId?'
  ],
  exact: true,
  services: [
    new CourseService(),
    new AssetService(),
    new AutobahnTimeOnTaskService(),
    new CourseListService(),
    new PdfHotspotService(),
    new GlossaryHotspotService()
  ],
  stores: [
    {
      name: 'course',
      spec: Course,
      saveLocally: false,
      initialState: {
        id: '',
        courseId: '',
        title: '',
        code: '',
        organizationId: '',
        source: '',
        key: '',
        role: '',
        platform: '',
        status: '',
        isStudent: false,
        startDate: 0,
        endDate: 0,
        products: [
          {
            authors: [{
              firstName: '',
              lastName: '',
              otherName: ''
            }
            ],
            audioBook: '',
            basePath: '',
            catalogUrl: '',
            format: '',
            id: '',
            indexId: '',
            isSumatra: false,
            mathmlOn: true,
            mobileReady: false,
            model: '',
            platform: '',
            status: '',
            thumbnailUrl: '',
            title: '',
            version: '',
            subscription: {
              allowAccess: false,
              businessModelCode: '',
              endDate: 0,
              startDate: 0,
              type: ''
            }
          }
        ]
      }
    },
    {
      name: 'asset',
      spec: Asset,
      saveLocally: false,
      initialState: {
        assetId: '',
        assetVersionId: '',
        id: '',
        title: '',
        code: '',
        playOrder: '0',
        productId: '',
        children: [{
          title: '',
          id: '',
          type: '',
          playOrder: '0',
          sectionType: ''
        }]
      }
    },
    {
      name: 'pdfHotspot',
      spec: PdfHotspot,
      saveLocally: false,
      initialState: {
        id: '',
        title: '',
        productId: '',
        updatedTime: '',
        pageNo: '',
        serverCreationTime: '',
        children: [{
          regionId: '',
          regionTypeId: '',
          pageNo: '',
          name: '',
          glossary: '',
          imageUri: '',
          linkUri: '',
          linkType: '',
          x: '',
          y: '',
          width: '',
          height: '',
          linkTypeId: '',
          transparent: false,
          isIcon: '',
          platformId: 0
        }]
      }
    },
    {
      name: 'courseItems',
      spec: CourseListData,
      saveLocally: false,
      initialState: {
        items: [{
          annotationExists: false,
          contextId: '',
          courseName: ''
        }]
      }
    },
    {
      name: 'glossaryHotspot',
      spec: GlossaryHotspot,
      saveLocally: false,
      initialState: {
        searchResults: []
      }
    }
  ],
  render: props => (
    <CourseContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
