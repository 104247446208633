/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Mohamed yasar arabath M
 */

import Framework from '@greenville/framework';
import { getSnapshot } from 'mobx-state-tree';
import xss from 'xss';
import CommonUtils from '../../../common/utils/CommonUtils';
import PathUtils from '../../../common/utils/PathUtils';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import ProductUtils from '../../../common/utils/ProductUtils';

export default class HeroBannerPlugin {
  static lastLocationDataFetched = false;

  static ldClient = null;

  static enableTitleArchival = false;

  constructor(context) {
    this.context = context;
    HeroBannerPlugin.ldClient = this.context.props.ldClient;
    this.userLastVisitedId = '';
    this.initalChapterId = '';
    this.init();
  }

  // eslint-disable-next-line class-methods-use-this
  init() {
    Framework.getEventManager().on(constants.LASTLOCATION_DATA_FETCHED, (() => {
      HeroBannerPlugin.lastLocationDataFetched = true;
    }));
  }

  /**
      * Function to set falgs
      * @param {array} courses
      */
   static getCourse = (courses) => {
     let courseDetails = {
       course_id: null,
       course_name: null,
       course_start_date: null,
       course_end_date: null,
       user_role: null
     };
     if (courses && Array.isArray(courses) && courses.length > 0) {
       const course = courses[0];
       courseDetails = { ...courseDetails, ...course };
     }

     return courseDetails;
   }

   /**
   * Sets the appropriate hero book
   *
   */
   // eslint-disable-next-line class-methods-use-this
   static setHeroBannerBook(fetchHeroMappedChannel) {
     const {
       BOOK
     } = constants.HERO_BANNER_TYPES;
     const bookShelf = Framework.getStoreRegistry().getStore('bookshelf');
     const language = Framework.getStoreRegistry().getStore('language');
     const heroBannerStores = Framework.getStoreRegistry().getStore('herobanner');
     const preHeroBanner = getSnapshot(heroBannerStores);
     const books = getSnapshot(bookShelf).books;
     const heroBanner = {};
     let heroBook = null;
     let lastAccessedBook = null;
     let titleSortOrderNewlyAddedBook = null;
     let newlyAddedActiveBooks = [];
     if (books.length > 0 && CommonUtils.hasActiveBooks(books)) {
       const IABook = CommonUtils.getIAbookIfAvailable(books);
       if (!IABook) {
         const lastAccess = this.getConditionalActiveBooks(books, true);
         lastAccessedBook = lastAccess.firstBook;
         // eslint-disable-next-line prefer-const
         const newAdded = this.getConditionalActiveBooks(books, false);
         titleSortOrderNewlyAddedBook = newAdded.firstBook;
         newlyAddedActiveBooks = newAdded.books;
       }
       if (IABook) { // IA book special case override all below rule
         heroBook = IABook;
       } else if (!lastAccessedBook && titleSortOrderNewlyAddedBook) { // No last accessed book and only new book
         heroBook = titleSortOrderNewlyAddedBook;
       } else if (lastAccessedBook && !titleSortOrderNewlyAddedBook) { // Have last accessed book and no new book
         heroBook = lastAccessedBook;
       } else if (lastAccessedBook && titleSortOrderNewlyAddedBook) { // Have last accessed book and new book
         titleSortOrderNewlyAddedBook = newlyAddedActiveBooks.find((book) => {
           let timeDiff = 0;
           const createdTime = book?.product_entitlements?.start_date;
           if (createdTime) {
             timeDiff = new Date(createdTime).getTime() - lastAccessedBook.last_accessed_time;
           }

           return timeDiff > 0;
         });
         heroBook = titleSortOrderNewlyAddedBook || lastAccessedBook;
       }
     }
     const isSolution1D = !!(heroBook && heroBook.channel_id);
     const isHeroVariant2 = isSolution1D && HeroBannerPlugin.isHeroBannerVariant2();
     if (heroBook && !isSolution1D) {
       const config = this.getConfig(BOOK, { ...heroBook });
       heroBanner.heroHeading = language.getMessage('hero.heroHeadingNoMappedChannel');
       heroBanner.bannerType = BOOK;
       heroBanner.channelId = heroBook.channel_id;
       heroBanner.config = config;
       heroBanner.title = heroBook.title;
       heroBanner.titleInfo = {
         author: heroBook.author,
         bookTitle: heroBook.title,
         courseName: this.getCourse(heroBook.courses).course_name,
         coverImageUrl: heroBook.cover_image_url,
         isbn: heroBook.isbn,
         productModelName: heroBook.product_model_name,
         book_id: heroBook.book_id,
         product_id: heroBook.product_id,
         course_id: heroBook.course_id,
         notificationsData: heroBook.bannerData || null,
         showRemoveEntitlement: heroBook.showRemoveEntitlement
       };

       if (config.showToc) {
         heroBanner.tableOfContents = {
           chapterTitle: heroBook.last_location_read || language.getMessage('hero.toc.defaultPlacehoder'),
           chapterId: heroBook.last_location_read_id || '',
           initialChapterId: heroBook.last_location_read_id || ''
         };
       }

       Framework.getStoreRegistry().getStore('herobanner').setHeroBanner(
         {
           heroHeading: preHeroBanner.heroHeading,
           mappedChannel: preHeroBanner.mappedChannel,
           ...heroBanner
         },
         fetchHeroMappedChannel
       );
      //  Framework.getStoreRegistry().getStore('herobanner').fetchPopularChannels();
     } else if (heroBook && isSolution1D) {
       let heroHeading = null;
       const channelRecommendationsByBookStore = Framework.getStoreRegistry().getStore('channelRecommendationsByBook');
       const channelTitle = channelRecommendationsByBookStore.getChannelTitle();
       const userStore = Framework.getStoreRegistry().getStore('user');
       const user = getSnapshot(userStore);
       const isBundleUser = CommonUtils.hasSubscription(user, [constants.ACTIVE], [constants.BUNDLE]);
       const hasChannelSubscription = CommonUtils.hasSubscription(
         user, [constants.ACTIVE], [constants.ENTITLEMENTLEVEL_STUDY]
       );
       const config = this.getConfig1D(BOOK, heroBook, isHeroVariant2);
       heroBanner.bannerType = BOOK;
       heroBanner.channelId = heroBook.channel_id;
       heroBanner.config = config;
       heroBanner.sectionHeading = HeroBannerPlugin.getHeroBookSectionHeading({
         lastActivity: heroBook.last_activity,
         language,
         isBundleUser,
         isHeroVariant2
       });
       heroBanner.heroBook = {};
       heroBanner.heroBook.heading = heroBook.last_activity
         ? language.getMessage('hero.continueReading') : language.getMessage('hero.startReading');
       heroBanner.heroBook.title = heroBook.title;
       heroBanner.heroBook.thumbnailImage = heroBook.cover_image_url;
       heroBanner.heroBook.tocLabel = language.getMessage('hero.tocLabel');
       heroBanner.titleInfo = {
         author: heroBook.author,
         bookTitle: heroBook.title,
         courseName: this.getCourse(heroBook.courses).course_name,
         coverImageUrl: heroBook.cover_image_url,
         isbn: heroBook.isbn,
         productModelName: heroBook.product_model_name,
         book_id: heroBook.book_id,
         product_id: heroBook.product_id,
         course_id: heroBook.course_id,
         notificationsData: heroBook.bannerData || null,
         showRemoveEntitlement: heroBook.showRemoveEntitlement
       };
       if (config.showGoToPage) {
         heroBanner.goToPage = {
           placeholder: language.getMessage('hero.goToPage.label'),
           label: language.getMessage('hero.goToPage.label'),
           value: heroBook.last_accessed_print_page_number,
           initialValue: heroBook.last_accessed_print_page_number,
           maxLength: constants.GO_TO_PAGE_MAX_LENGTH
         };
       }
       if (config.showToc) {
         heroBanner.tableOfContents = {
           chapterTitle: heroBook.last_location_read || language.getMessage('hero.toc.defaultPlacehoder'),
           chapterId: heroBook.last_location_read_id || '',
           initialChapterId: heroBook.last_location_read_id || ''
         };
       }
       if (config.showHeroBookCTA) {
         heroBanner.heroBook.callForAction = {
           text: language.getMessage('hero.openEtextBookV1'),
           ariaLabel: language.getMessage('hero.openEtextBookV1')
         };
       }
       if (channelTitle) {
         const heroHeadingToReplace = isHeroVariant2 ? 'hero.heroHeadingV1' : 'hero.heroHeading';
         const replacePlaceHolder = isHeroVariant2 ? '{replace_value}' : '{channel_title}';
         heroHeading = language.getMessage(heroHeadingToReplace).replace(replacePlaceHolder, channelTitle);
       }
       if (config.showHeroHeading && !isHeroVariant2) {
         heroBanner.heroHeading = isBundleUser
           ? language.getMessage('hero.heroHeadingForBundleUser')
           : (preHeroBanner.heroHeading || heroHeading);
       }
       if (config.showHeroHeading && isHeroVariant2) {
         heroBanner.heroHeading = HeroBannerPlugin.getHeroHeading({
           heroBook,
           language,
           previousHeading: preHeroBanner.heroHeading
         });
       }
       if (config.showSubscriptionStatusCard) {
         heroBanner.subscriptionStatus = HeroBannerPlugin.getSubscriptionStatusData({
           language,
           heroBook,
           hasChannelSubscription,
           isBundleUser
         });
       }

       Framework.getStoreRegistry().getStore('herobanner').setHeroBanner(
         {
           heroHeading: preHeroBanner.heroHeading,
           mappedChannel: preHeroBanner.mappedChannel,
           ...heroBanner
         },
         fetchHeroMappedChannel
       );
     } else {
       this.setHeroConditionalMessage(fetchHeroMappedChannel);
     }
   }

   /**
    * To fetch the LD value
    *
    * @param {*} flagName
    * @param {*} defaultValue
    * @returns
    */
   static getLDClient(flagName, defaultValue) {
     return HeroBannerPlugin.ldClient ? HeroBannerPlugin.ldClient.variation(flagName, defaultValue) : defaultValue;
   }

   static getConfig(type) {
     const {
       BOOK
     } = constants.HERO_BANNER_TYPES;
     let config = {
       showInfo: false,
       showToc: false,
       showTutor: false
     };
     // TODO: Refactor the code and value should be referred from hero mapped channel
     switch (type) {
       case BOOK:
         config = {
           ...config,
           showInfo: true,
           showToc: this.getLDClient('enableTocInHeroView', true)
         };
         break;
       default:
         break;
     }

     return config;
   }

   /**
    * Returns config for herobanner (Solution 1D)
    *
    * @param {*} type
    * @param {*} data
    * @returns
    */
   static getConfig1D(type, data, isHeroVariant2 = false) {
     const {
       BOOK,
       CHANNEL,
       MARKETING_TEMPLATE
     } = constants.HERO_BANNER_TYPES;
     const {
       HERO_BANNER_V1_VARIANTS
     } = constants;
     let config = {
       showInfo: false,
       showMarketingCard: false,
       showToc: false,
       showHeroHeading: true,
       showGoToPage: false,
       variant: HERO_BANNER_V1_VARIANTS.DEFAULT,
       showPromotionalCard: false,
       showSubscriptionStatusCard: false
     };

     switch (type) {
       case BOOK:
         config = {
           ...config,
           showInfo: true,
           showToc: this.getLDClient('enableTocInHeroView', true),
           showMarketingCard: !data.last_activity,
           showGoToPage: HeroBannerPlugin.showGoToPage({ heroBook: { ...data } }),
           variant: isHeroVariant2 ? HERO_BANNER_V1_VARIANTS.V2 : HERO_BANNER_V1_VARIANTS.DEFAULT,
           showPromotionalCard: isHeroVariant2,
           showSubscriptionStatusCard: isHeroVariant2
         };
         break;
       case CHANNEL:
         config = {
           ...config
         };
         break;
       case MARKETING_TEMPLATE:
         config = {
           ...config,
           showMarketingCard: true
         };
         break;
       default:
         break;
     }

     return config;
   }

  static getConditionalActiveBooks = (books, byLastAccessedTime) => {
    const filterBooks = books.filter(book => (
      (byLastAccessedTime ? book.last_accessed_time : !book.last_accessed_time)
      && CommonUtils.isActiveBook(book)
      && (this.enableTitleArchival ? !book.isArchived : true)));

    return { firstBook: filterBooks[0] || null, books: filterBooks };
  }

  /**
   * Construct data for letsGetGoing / CodeRedeemed in hero section
   *
   * @param {String} messageType
   * @returns
   */
  static constructHeroConditionalMessage(messageType) {
    const language = Framework.getStoreRegistry().getStore('language');
    const { HERO_BANNER_TYPES } = constants;
    const heroBanner = {};
    const isMarketingTemplate = messageType === HERO_BANNER_TYPES.MARKETING_TEMPLATE;
    const config = this.getConfig1D(messageType);
    const renderCTAText = language.getMessage('hero.study.help');

    if (isMarketingTemplate) {
      heroBanner.bannerType = HERO_BANNER_TYPES.MARKETING_TEMPLATE;
      heroBanner.config = config;
      heroBanner.heroHeading = language.getMessage('hero.heroBook.sectionHeading');
      heroBanner.sectionHeading = language.getMessage('hero.noTitleNoChannel.sectionHeading');
      heroBanner.sectionSubHeading = language.getMessage('hero.marketingcard.subheading');
      //  Framework.getStoreRegistry().getStore('herobanner').fetchPopularChannels();
    }
    if (config.showCallForAction) {
      heroBanner.callForAction = {
        text: renderCTAText,
        ariaLabel: renderCTAText
      };
    }

    return heroBanner;
  }

  static setHeroBannerChannel(fetchMappedChannel) {
    const {
      CHANNEL
    } = constants.HERO_BANNER_TYPES;
    const heroBanner = {};
    const { channel, location } = CommonUtils.getRecentlyUsedChannel() || {};
    const {
      title
    } = channel;
    const language = Framework.getStoreRegistry().getStore('language');
    const config = this.getConfig1D(CHANNEL);
    heroBanner.bannerType = CHANNEL;
    heroBanner.channelId = location?.id || null;
    heroBanner.config = config;
    if (title) {
      heroBanner.heroHeading = language.getMessage('hero.heroHeading').replace('{channel_title}', title);
    }
    heroBanner.sectionHeading = language.getMessage('hero.heroBook.channelsSectionHeading');
    // heroBanner.generalMappedChannel = channelRecommendationsByChannelStore.getGeneralMappedChannel();
    // Framework.getStoreRegistry().getStore('herobanner').fetchPopularChannels();

    Framework.getStoreRegistry().getStore('herobanner').setHeroBanner({
      mappedChannel: Framework.getStoreRegistry().getStore('herobanner').mappedChannel,
      ...heroBanner
    }, fetchMappedChannel);
  }

  /**
   * Set conditional message for hero section
   *
   */
  static setHeroConditionalMessage(fetchMappedChannel) {
    const { MARKETING_TEMPLATE } = constants.HERO_BANNER_TYPES;
    const bookShelf = Framework.getStoreRegistry().getStore('bookshelf');
    const hasActiveBooks = CommonUtils.hasActiveBooks(
      getSnapshot(bookShelf).books
    );
    const hasArchivedBooks = this.enableTitleArchival
      && CommonUtils.hasArchivedBooks(getSnapshot(bookShelf).books);
    const hasLastAccessedChannel = CommonUtils.hasLastAccessedChannel();
    let heroBanner = {};

    if (HeroBannerPlugin.lastLocationDataFetched && (!hasActiveBooks || hasArchivedBooks)) {
      if (!hasLastAccessedChannel) { // No active title and No last accessed channel
        heroBanner = this.constructHeroConditionalMessage(MARKETING_TEMPLATE);
      } else if (hasLastAccessedChannel) { // No active title and last accessed channel
        heroBanner = this.setHeroBannerChannel(fetchMappedChannel);
      }
    }

    if (heroBanner && !CommonUtils.isEmpty(heroBanner)) {
      Framework.getStoreRegistry().getStore('herobanner').setHeroBanner(heroBanner);
    }
  }

  /**
   * Makes asset call for the hero book required for TOC
   *
   * @param {Object} book
   */
  fetchAsset(book) {
    const { asset } = this.context.props;
    const id = CommonUtils.isIntegratedBMC(book.product_entitlements.business_model_code)
      ? book.book_id : book.product_id;

    if (book) {
      asset.fetch(id, book.course_id, null, book.book_type, false, true);
    }
  }

  /**
   * Sets table of content data for hero book
   *
   */
  setTableOfContentData() {
    let data = {};
    let book = null;
    const { asset, herobanner } = this.context.props;
    const { tableOfContents } = getSnapshot(herobanner);
    let chapterId = tableOfContents.chapterId || '';
    book = this.findHeroBook();
    if (book) {
      const isPdf = book.product_model === constants.ETEXT_PDF;
      const role = ((book.courses && book.courses.length > 0 && book.courses[0].user_role) || (book.product_entitlements?.user_role));
      const isAudioBook = book.showAudio;
      if (isPdf) {
        data = asset.getAssetTocPdf(null, role, false, isAudioBook);
      } else {
        data = getSnapshot(asset);
      }
      data = this.tocTraverseToMapUniqueId(data);
      chapterId = this.userLastVisitedId || chapterId;
      herobanner.setTableOfContents({ data, chapterId });
    }
  }

  /**
   * TO Traverse tree and update unque id with separator
   *
   * @param {*} tocData
   */
  tocTraverseToMapUniqueId(tocChildren) {
    const { herobanner } = this.context.props;
    const { tableOfContents } = getSnapshot(herobanner);
    const chapterId = tableOfContents.chapterId || '';
    const tocLevelChildList = {};
    tocLevelChildList.id = tocChildren.id;
    tocLevelChildList.title = tocChildren.title;
    tocLevelChildList.children = [];
    (tocChildren.children).forEach((tocData, parentIndex) => {
      if (typeof tocData.display === 'undefined' || tocData.display) {
        const childList = this.constructTree(tocData, (parentIndex + 1), chapterId);
        tocLevelChildList.children.push(childList);
      }
    });

    return tocLevelChildList;
  }

  /**
   * Method for constructing tree structured TOC
   *
   * @param {*} tocData
   * @returns
   */
  constructTree(tocData, parentIndex, chapterId) {
    const tocNode = {};
    if (tocData.id === chapterId) {
      this.userLastVisitedId = `${tocData.id}${constants.TOC_SEPERATOR_CHAPTER}${parentIndex}`;
    }
    tocNode.id = `${tocData.id}${constants.TOC_SEPERATOR_CHAPTER}${parentIndex}`;
    tocNode.title = tocData.title;
    tocNode.children = [];
    if (tocData.children) {
      tocData.children.forEach((tocDataChildren, tocDataChildrenIndex) => {
        if (typeof tocDataChildren.display === 'undefined' || tocDataChildren.display) {
          const childNode = this.constructTree(tocDataChildren, `${parentIndex}.${(tocDataChildrenIndex + 1)}`, chapterId);
          tocNode.children.push(childNode);
        }
      });
    }

    return tocNode;
  }

  /**
   * To get pageDetails from playlist
   *
   * @param {*} playlist
   * @param {*} pageId
   * @returns
   */
  getPageDetails = (playlist, pageId) => {
    let pageDetail = {};

    if (pageId) {
      const pageDetails = playlist && playlist.find(list => list.id === pageId);

      pageDetail = pageDetails || {};
    }

    return pageDetail;
  };

  /**
   * Sets isTOCFetchFailed as true when asset call fails
   *
   */
  setTableOfContentError() {
    const { herobanner } = this.context.props;
    herobanner.setTableOfContents({ isTOCFetchFailed: true });
  }

  /**
   * Excludes hero book and expired book crossed max days
   *
   * @param {Object} books
   * @param {bool} excludeHeroBook
   * @returns
   */
  filterLibraryL1Books(books, isFromLibraryShelf = false) {
    let excludeHeroBook = true;
    const { enableTitleArchival } = this.context.state;
    const filteredBooks = enableTitleArchival ? books.filter(book => !book.isArchived) : books;
    const actualBooks = CommonUtils.removeExpiredBookAfterXDays(filteredBooks, constants.EXPIRED_BOOK_MAX_DAYS);

    if (isFromLibraryShelf) {
      const activeBooks = CommonUtils.getActiveBooks(actualBooks);
      excludeHeroBook = (actualBooks.length === 1 && activeBooks.length === 1) || false;
    }

    if (excludeHeroBook) {
      const book = this.findHeroBook();
      // eslint-disable-next-line camelcase
      const { book_id, product_id } = book || {};

      // eslint-disable-next-line camelcase
      if ((book_id || product_id) && actualBooks.length > 0) {
        // eslint-disable-next-line camelcase
        const heroBookIndex = actualBooks.findIndex(bookData => bookData.book_id === book_id && bookData.product_id === product_id);
        // eslint-disable-next-line camelcase
        if (heroBookIndex !== -1) {
          actualBooks.splice(heroBookIndex, 1);
        }
      }
    }

    return actualBooks;
  }

  /**
   * Find the Hero book in library
   *
   * @returns
   */
  findHeroBook() {
    let bookData;
    const { BOOK } = constants.HERO_BANNER_TYPES;
    const { bookshelf, herobanner } = this.context.props;
    const { books } = getSnapshot(bookshelf);
    const { bannerType, titleInfo } = herobanner;
    if (bannerType === BOOK) {
      bookData = books.find(book => (
        book.book_id === titleInfo.book_id
        && book.product_id === titleInfo.product_id
        && book.course_id === titleInfo.course_id
      ));
    }

    return bookData;
  }

  /**
   * To get clean chapterId in case of duplicate case
   *
   */
  getCleanChapterId = () => {
    const { herobanner } = this.context.props;
    const { tableOfContents } = getSnapshot(herobanner);
    let renderChapterId = (tableOfContents && tableOfContents.chapterId) || '';
    if (renderChapterId && renderChapterId.includes(constants.TOC_SEPERATOR_CHAPTER)) {
      renderChapterId = renderChapterId.split(constants.TOC_SEPERATOR_CHAPTER)[0];
    }

    if (tableOfContents && (renderChapterId === tableOfContents.initialChapterId)) {
      renderChapterId = '';
    }

    return renderChapterId ? encodeURI(renderChapterId) : '';
  }

  /**
   * Handles launch url page redirections
   * @param {*} launchURL
   * @param {*} target
   */
  redirectToPage = (launchURL, target) => {
    if (launchURL.includes(constants.L2_SEPERATOR)) {
      const [page, container] = launchURL.split(constants.L2_SEPERATOR);
      if (page.toLowerCase() === constants.L2PAGE.toLowerCase() && container) {
        this.context.redirectToL2Page(container);
      }
    } else {
      window.open(launchURL, target || '_blank');
    }
  }

  handleChapterIdChange = (chapterId, chapterTitle, parentChapterTitle) => {
    const { herobanner } = this.context.props;

    herobanner.setTableOfContents({ chapterId, chapterTitle, parentChapterTitle });
    this.context.studyHelpPlugin.fetchHeroMappedChannel();
  }

  /**
   * Add UTM Parameter and amend Url hash at end of the Url incase
   *
  */
  static getUrlWithUTMParams = (url, utmParams) => {
    let locationUrl = url;
    if (url && !url.includes(utmParams)) {
      const hashReg = new RegExp('#.+=([^&|/|?]+)', 'i');
      const hashMatch = url.match(hashReg);
      let urlHash = '';
      if (hashMatch) {
        urlHash = hashMatch[0];
        locationUrl = locationUrl.replace(urlHash, '');
      }
      locationUrl = PathUtils.addQueryParameter(utmParams, locationUrl) + urlHash;
    }

    return locationUrl;
  }

  /**
   * Handles CTA click in Hero
   * @param {*} isClicked
   * @param {*} isFromInfoModal
   */
  handleCtaClick = (isClicked = true, isFromInfoModal = false) => {
    const {
      BOOK
    } = constants.HERO_BANNER_TYPES;
    const
      {
        LOCATION_IN_APP
      } = constants;
    const { herobanner } = this.context.props;
    const { bannerType } = getSnapshot(herobanner);

    switch (bannerType) {
      case BOOK: {
        const book = this.findHeroBook();
        if (book) {
          if (isClicked) {
            const chapterId = this.getCleanChapterId();
            const goToPageNo = this.getGoToPageNo();
            this.context.handleBookClick(
              book,
              constants.READ,
              book.course_id,
              null,
              chapterId,
              LOCATION_IN_APP.HERO,
              { goToPageNo }
            );
          }
          CommonUtils.handleInfoBookCTAEvents(book, isClicked, LOCATION_IN_APP.HERO, isFromInfoModal);
        }
      }
        break;
      default:
        break;
    }
  }

  /**
   * Handles CTA hover in Hero
   */
  handleCtaHover = () => {
    this.handleCtaClick(false);
  }

  /**
   * Handles Secondary CTA click in Hero
   *
   */
  handleSecondaryCtaClick = () => {
    // Naviagte to Library L2
    this.context.onSeeAll(false);
  }

  /**
   * Handles thumbnail click in Hero
   */
  handleHeroThumbnailClick = () => {
    this.handleCtaClick();
  }

  /**
   * Handles thumbnail hover in Hero
   */
  handleHeroThumbnailHover = () => {
    this.handleCtaClick(false);
  }

  /**
   * Handles handle Icons Click on Hero
   */
  handleIconsClick = (type) => {
    const {
      LIBRARY_EVENT, EVENT_AUTHHOMEEVENT, ACTIVITY_TYPE: { INFO }, LOCATION_IN_APP: { HERO }
    } = constants;
    const book = this.findHeroBook();
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: HERO
    };

    if (type === INFO) {
      CommonUtils.dispatchGaEvent(
        LIBRARY_EVENT.EVENT_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION,
        LIBRARY_EVENT.INFO_EVENT_LABEL, customFields
      );
    } else if (book && type !== INFO) {
      const chapterId = this.getCleanChapterId();
      this.context.handleBookClick(book, type, book.course_id, null, chapterId, HERO);
    }
  }

  /**
   * Handle TOC click
   */
  handleTocClick = () => {
    const { LIBRARY_EVENT, LOCATION_IN_APP, EVENT_AUTHHOMEEVENT } = constants;
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: LOCATION_IN_APP.HERO
    };
    const book = this.findHeroBook();
    if (book) {
      this.fetchAsset(book);
    }
    CommonUtils.dispatchGaEvent(
      LIBRARY_EVENT.EVENT_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION,
      LIBRARY_EVENT.TOC_EVENT_LABEL,
      customFields
    );
  }

  /**
   * Handles title info modal CTA click
   */
  onTitleInfoBookCTAClick = () => {
    this.handleCtaClick(true, true);
  }

  /**
   * Handles title info modal CTA click
   */
  onTitleInfoBookCTAHover = () => {
    this.handleCtaClick(false, true);
  }

  /**
   * Handles title info modal Remove from library CTA click
   */
  onTitleInfoRemoveClick = () => {
    const { LOCATION_IN_APP } = constants;
    const book = this.findHeroBook();

    this.context.handleRemoveTitlePopup(book, null, true, LOCATION_IN_APP.HERO);
  }

  /**
   * Handles remove title hover
   */
  onRemoveTitleHover = () => {
    const { LOCATION_IN_APP } = constants;

    this.context.handleRemoveGAEvent(false, true, LOCATION_IN_APP.HERO);
  }

  /**
   * Handles title info Notification primary or secondary CTA click
   *
   * @param {*} event
   * @param {*} isDismissed
   * @param {*} launchURL
   * @param {*} target
   * @param {*} bannerId
   */
  onTitleInfoNotificationCTAClick = (event, isDismissed, launchURL, target, bannerId) => {
    const book = this.findHeroBook();
    this.context.handleSubscriptionBannerClick(event, book, isDismissed, launchURL, target, bannerId, constants.LOCATION_IN_APP.HERO);
  }


  static isTocFetched = () => {
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const heroBanner = getSnapshot(heroBannerStore);

    return !!heroBanner?.TableOfContents?.data;
  }

  /**
   * Triggers 581 event click or hover open channel event for solution 1D
   *
   * @param {*} customFieldsData
   * @param {*} isClicked
   */
  handleClickOrHoverChannelEventsForSol1D = (customFieldsData, isClicked = false) => {
    const { user } = this.context.props;
    const {
      CLICK_CHANNEL_CARD_ELEMENT,
      OPEN_CHANNEL_EVENT_LABEL
    } = constants.CHANNEL_EVENT;
    const {
      // eslint-disable-next-line camelcase
      location_in_app
    } = this.getChannelsEventLocationInAppForSol1D();

    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app,
      channel_personalized: customFieldsData.channel_personalized,
      channel_id: customFieldsData.channelId,
      channels_p_bundle_subs: CommonUtils.hasSubscription(getSnapshot(user), [constants.ACTIVE], [constants.BUNDLE])
    };
    if (isClicked) {
      CommonUtils.dispatchGaEvent(
        constants.AUTH_HOME_CATEGORY,
        constants.EVENT_AUTHHOMEEVENT,
        CLICK_CHANNEL_CARD_ELEMENT,
        OPEN_CHANNEL_EVENT_LABEL,
        customFields
      );
    }
  }

  /**
   * Returns appropriate location_in_app for Solution 1D 581 open channel event
   *
   * @returns
   */
  getChannelsEventLocationInAppForSol1D = () => {
    const {
      BOOK,
      CHANNEL,
      MARKETING_TEMPLATE
    } = constants.HERO_BANNER_TYPES;
    const {
      HERO_ETEXT_MAPPED_CHANNEL,
      HERO_NORECENTCHANNEL_NOACTIVETEXTBOOK,
      HERO_RECENTCHANNEL
    } = constants.LOCATION_IN_APP;
    const { herobanner } = this.context.props;
    const heroBanner = getSnapshot(herobanner);
    let channelEventCustomFields = null;

    if (heroBanner.bannerType === BOOK) {
      channelEventCustomFields = {
        location_in_app: HERO_ETEXT_MAPPED_CHANNEL
      };
    } else if (heroBanner.bannerType === CHANNEL) {
      channelEventCustomFields = {
        location_in_app: HERO_RECENTCHANNEL
      };
    } else if (heroBanner.bannerType === MARKETING_TEMPLATE) {
      channelEventCustomFields = {
        location_in_app: HERO_NORECENTCHANNEL_NOACTIVETEXTBOOK
      };
    }

    return channelEventCustomFields || {};
  }

  /**
   * Handles general mapped channel CTA click
   * Applicable for Hero book and Hero channel
   *
   * @param {*} isClicked
   */
  onChannelInfoCTAClick = (isClicked = true) => {
    const { HERO_BANNER_TYPES } = constants;
    const { CHANNEL_BANNER } = constants.CHANNEL_PERSONALIZED_VALUES;
    const { herobanner, channelRecommendationsByBook, channelRecommendationsByChannel } = this.context.props;
    const heroBanner = getSnapshot(herobanner);
    const isHeroBook = heroBanner.bannerType === HERO_BANNER_TYPES.BOOK;
    const isHeroChannel = heroBanner.bannerType === HERO_BANNER_TYPES.CHANNEL;
    const customFields = {
      channel_personalized: CHANNEL_BANNER
    };
    let channelData = null;
    let redirectionUrl = null;
    const { enableChannelLearnRedirection } = this.context.state.flags;
    if (isHeroChannel) {
      const { channel, location } = CommonUtils.getRecentlyUsedChannel() || {};
      channelData = channel;
      this.handleClickOrHoverChannelEventsForSol1D(
        {
          ...customFields,
          channelId: location.id || null
        },
        isClicked
      );
    } else if (isHeroBook) {
      this.handleClickOrHoverChannelEventsForSol1D(
        {
          ...customFields,
          channelId: heroBanner.channelId || null
        },
        isClicked
      );
      channelData = getSnapshot(channelRecommendationsByBook)?.channelData || {};
    }

    if (isClicked && (channelData.url || channelData.learnUrl)) {
      const maxAssets = this.context.getContainerData('heroView', constants.BYCHANNEL_MAX_ASSET) || constants.MAX_CHANNEL_CARD;
      redirectionUrl = enableChannelLearnRedirection ? CommonUtils.getChannelLearnUrl({ ...channelRecommendationsByBook, ...channelRecommendationsByChannel }, heroBanner.mappedChannel, channelData, maxAssets) : channelData.url;
      if (redirectionUrl) {
        const channelUrl = HeroBannerPlugin.getUrlWithUTMParams(redirectionUrl, constants.CHANNEL_QUERY_PARAM);
        const {
          channelPersonalized
        } = CommonUtils.getChannelsViewEventDataForSol1D(heroBanner.mappedChannel);
        window.open(channelUrl);
        CommonUtils.dispatchGaEvent(
          constants.AUTH_HOME_CATEGORY,
          constants.EVENT_AUTHHOMEEVENT,
          constants.USER_ACTION_AB_TEST_CLICK,
          enableChannelLearnRedirection ? constants.USER_AB_TEST_LABEL.CHANNEL_BANNER_LEARN : constants.USER_AB_TEST_LABEL.CHANNEL_BANNER_MYCOURSE,
          {
            location_in_app: constants.LOCATION_IN_APP.HERO,
            is_ghost_account: CommonUtils.isGhostAccount(),
            channel_id: heroBanner.channelId,
            channel_personalized: CommonUtils.toString(channelPersonalized)
          }
        );
      }
    }
  }

  /**
   * Handles general mapped channel CTA hover
   * Applicable for Hero book and Hero channel
   *
   */
  onChannelInfoCTAHover = () => {
    this.onChannelInfoCTAClick(false);
  }

  /**
   * Handles Asset card click
   * Applicable for Hero book and Hero channel
   *
   * @param {*} type
   * @param {*} cardIndex
   */
  onAssetCardClick = (type, cardIndex) => {
    const { herobanner } = this.context.props;
    const { RECOMMENDED__ASSETS, POPULAR_ASSETS } = constants.CHANNEL_PERSONALIZED_VALUES;
    const heroBanner = getSnapshot(herobanner);
    const { mappedChannel } = heroBanner;
    const { MAPPED_CHANNEL_ASSET_CARD, LAST_ACCESSED_CHANNEL_ASSETS } = constants.MAPPED_CHANNEL_TEMPLATES;
    const customFields = {
      channel_personalized: (mappedChannel.template === MAPPED_CHANNEL_ASSET_CARD.TEXT
        || mappedChannel.template === LAST_ACCESSED_CHANNEL_ASSETS.TEXT)
        ? RECOMMENDED__ASSETS : POPULAR_ASSETS,
      channelId: heroBanner.channelId || null
    };
    const {
      channelRecommendationsByBook: { recommendedAssets, popularTopics },
      channelRecommendationsByChannel: { startWithTopics, jumpBackIn, recommendedNext },
      ldClient
    } = this.context.props;
    const {
      RECOMMENDED_ASSETS,
      JUMPBACK_IN_AND_RECOMMENDED_NEXT,
      POPULAR_TOPICS,
      START_WITH_TOPICS
    } = constants.CHANNEL_TYPES;
    const { LD_EVENT_NAME } = constants;
    let recommendedAssetsData = null;
    let popularTopicsData = null;
    let redirectionUrl;
    this.handleClickOrHoverChannelEventsForSol1D(customFields, true);

    if (ldClient && ldClient.track) {
      ldClient.track(LD_EVENT_NAME.HERO_CHANNEL_CARD_CLICK);
    }
    if (recommendedAssets) {
      recommendedAssetsData = getSnapshot(recommendedAssets);
    }
    if (popularTopics) {
      popularTopicsData = getSnapshot(popularTopics);
    }
    switch (type) {
      case RECOMMENDED_ASSETS: {
        if (recommendedAssetsData && recommendedAssetsData[cardIndex]) {
          const { url } = recommendedAssets[cardIndex];

          redirectionUrl = `${env.STUDY_CHANNEL_URL}${url}`;
        }
        break;
      }
      case POPULAR_TOPICS: {
        if (popularTopicsData && popularTopicsData[cardIndex]) {
          const { url } = popularTopicsData[cardIndex];

          redirectionUrl = `${env.STUDY_CHANNEL_URL}${url}`;
        }
        break;
      }
      case START_WITH_TOPICS: {
        if (startWithTopics && startWithTopics[cardIndex]) {
          const { url } = startWithTopics[cardIndex];

          redirectionUrl = `${env.STUDY_CHANNEL_URL}${url}`;
        }
        break;
      }
      case JUMPBACK_IN_AND_RECOMMENDED_NEXT: {
        if (jumpBackIn || recommendedNext) {
          const MAPPED_CHANNEL_MAX_CARDS = this.context.getContainerData('heroView', constants.BYCHANNEL_MAX_ASSET) || constants.MAX_CHANNEL_CARD;
          const hasRecommendedNext = recommendedNext && recommendedNext.length > 0;
          const jumpBackInAndUpNext = [...jumpBackIn, ...(hasRecommendedNext ? recommendedNext : [])].slice(
            0, MAPPED_CHANNEL_MAX_CARDS
          );

          if (jumpBackInAndUpNext[cardIndex]) {
            const { url } = jumpBackInAndUpNext[cardIndex];

            redirectionUrl = `${env.STUDY_CHANNEL_URL}${url}`;
          }
        }
        break;
      }
      default:
        break;
    }

    if (redirectionUrl) {
      const channelUrl = HeroBannerPlugin.getUrlWithUTMParams(redirectionUrl, constants.CHANNEL_QUERY_PARAM);
      window.open(channelUrl);
    }
  }

  /**
   * Handles Asset card hover
   * Applicable for Hero book and Hero channel
   *
   */
  onAssetCardHover = () => {
    const { herobanner } = this.context.props;
    const heroBanner = getSnapshot(herobanner);
    const { mappedChannel } = heroBanner;
    const { RECOMMENDED__ASSETS, POPULAR_ASSETS } = constants.CHANNEL_PERSONALIZED_VALUES;
    const { MAPPED_CHANNEL_ASSET_CARD, LAST_ACCESSED_CHANNEL_ASSETS } = constants.MAPPED_CHANNEL_TEMPLATES;
    const channel = CommonUtils.getRecentlyUsedChannel()?.location;
    const customFields = {
      channel_personalized: (mappedChannel.template === MAPPED_CHANNEL_ASSET_CARD.TEXT
        || mappedChannel.template === LAST_ACCESSED_CHANNEL_ASSETS.TEXT)
        ? RECOMMENDED__ASSETS : POPULAR_ASSETS,
      channelId: ((channel && channel.id) || null) || (heroBanner.channelId || null)
    };
    this.handleClickOrHoverChannelEventsForSol1D(customFields, false);
  }

  /**
   * Handles primary CTA click
   * Applicable for No active book and no channel sub
   *
   */
  onPrimaryCTAClick = () => {
    const { MARKETING_CARD } = constants.CHANNEL_PERSONALIZED_VALUES;
    const customFields = {
      channel_personalized: MARKETING_CARD,
      channelId: null
    };
    this.handleClickOrHoverChannelEventsForSol1D(customFields, true);

    this.context.scrollToChannelContainer();
  }

  /**
   * Handles primary CTA hover
   * Applicable for No active book and no channel sub
   *
   */
  onPrimaryCTAHover = () => {
    const { MARKETING_CARD } = constants.CHANNEL_PERSONALIZED_VALUES;
    const customFields = {
      channel_personalized: MARKETING_CARD,
      channelId: null
    };
    this.handleClickOrHoverChannelEventsForSol1D(customFields, false);
  }

  /**
   * Handle popular channel card click
   * Applicable for Channel only sub and no active book and no channel sub
   *
   * @param {*} index
   * @param {*} isClicked
   */
  onPopularChannelCardClick = (index, isClicked = true, isFromSoultion1C = false) => {
    const { herobanner } = this.context.props;
    const heroBanner = getSnapshot(herobanner);
    const { popularChannels } = heroBanner;
    const { channelCards } = popularChannels;
    const { POPULAR_CHANNEL } = constants.CHANNEL_PERSONALIZED_VALUES;

    if (channelCards && channelCards[index]) {
      const { channelId, url } = channelCards[index];
      const customFields = {
        channel_personalized: POPULAR_CHANNEL,
        channelId
      };
      if (isFromSoultion1C) {
        if (isClicked) {
          CommonUtils.dispatchGaEvent(
            constants.AUTH_HOME_CATEGORY,
            constants.EVENT_AUTHHOMEEVENT,
            constants.MESSAGE_CENTER_AND_SUBSCRIPTION_BANNER_EVENTS.CTA_CLICK,
            channelId,
            { is_ghost_account: CommonUtils.isGhostAccount() }
          );
        }
      } else {
        this.handleClickOrHoverChannelEventsForSol1D(customFields, isClicked);
      }

      if (isClicked && url) {
        const channelUrl = HeroBannerPlugin.getUrlWithUTMParams(url, constants.CHANNEL_QUERY_PARAM);
        window.open(channelUrl);
      }
    }
  }

  /**
   * Handle popular channel card click
   * Applicable for Channel only sub and no active book and no channel sub
   *
   * @param {*} index
   */
  onPopularChannelCardHover = (index, isFromSoultion1C = false) => {
    this.onPopularChannelCardClick(index, false, isFromSoultion1C);
  }

  /**
   * Sets enableTitleArchival to true once all conditions for archival is met
   *
   * @param {*} enableTitleArchival
   */
  static setEnableTitleArchival = (enableTitleArchival) => {
    HeroBannerPlugin.enableTitleArchival = enableTitleArchival;
  }

  /**
   * Returns value (true | false) from LD
   *
   * @returns
   */
  static isHeroBannerVariant2 = () => HeroBannerPlugin.getLDClient('enableHeroBannerVariant2', false);

  /**
   * Determines and returns hero section heading
   *
   * @param {Object} properties
   * @returns
   */
  static getHeroBookSectionHeading = (properties = {}) => {
    const {
      lastActivity,
      language,
      isBundleUser,
      isHeroVariant2
    } = properties;
    let heroBookSectionHeading = lastActivity ? language.getMessage('hero.heroBook.sectionHeading')
      : language.getMessage('hero.heroBook.sectionHeadingNewUser');

    if (isHeroVariant2) {
      heroBookSectionHeading = !isBundleUser
        ? language.getMessage('hero.studyandexamprep') : language.getMessage('hero.heroHeadingForBundleUser');
    }

    return heroBookSectionHeading;
  }

  /**
   * Constructs and returns subscription status data
   *
   * @param {Object} param0
   * @returns
   */
  static getSubscriptionStatusData = ({
    language,
    isBundleUser,
    hasChannelSubscription,
    heroBook
  }) => {
    const title = language.getMessage('hero.subscriptionStatusTitle');
    const subscriptions = [{
      text: language.getMessage('hero.eTextbook'),
      checked: true
    }];
    const BMC = heroBook?.product_entitlements?.business_model_code;
    const courseId = heroBook?.course_id;

    if (CommonUtils.isMyLabSubscription(courseId, BMC)) {
      subscriptions.push({
        text: language.getMessage('hero.mylabOrMastering'),
        checked: true
      });
    }

    /**
      * For all other users - Study & Exam Prep
      *
    */
    if (!isBundleUser) {
      subscriptions.push({
        text: language.getMessage('hero.studyandexamprep'),
        checked: hasChannelSubscription
      });
    }

    /**
     * If bundle user copy - Study & Exam Prep Pack
     *
    */
    if (isBundleUser) {
      subscriptions.push({
        text: language.getMessage('hero.heroHeadingForBundleUser'),
        checked: isBundleUser
      });
    }

    return {
      title,
      listContent: subscriptions
    };
  }

  /**
   * Returns true if the book is eligible for print page navigation
   *
   * @param {Object} param0
   * @returns
   */
  static showGoToPage = ({
    heroBook
  }) => {
    // eslint-disable-next-line camelcase
    const { printPageAvailable, book_type } = heroBook;
    const enableGoToPage = HeroBannerPlugin.getLDClient('enableGoToPage', false);
    // eslint-disable-next-line camelcase
    const isBronte = book_type === constants.CONTENT_TYPE_EPUB;
    // eslint-disable-next-line camelcase
    const isCITE = book_type === constants.CONTENT_TYPE_CITE;
    // eslint-disable-next-line camelcase
    const isBVBronte = ProductUtils.isBVBronteBook(book_type);

    let showGoToPage = false;

    if (enableGoToPage && printPageAvailable) {
      if (isBronte) {
        showGoToPage = HeroBannerPlugin.getLDClient('enablePrintPageNavigationBronte', false);
      } else if (HeroBannerPlugin.getLDClient('enablePrintPageNavigation', false)
        && !isCITE
        && !isBVBronte
      ) {
        showGoToPage = true;
      }
    }

    return showGoToPage;
  }

  /**
   * Handled promotional card CTA click
   *
   */
  onPromotionalCardCTAClick = () => {
    // Redirect to channels my course page
    const { CHANNEL_FEATURES_LEARN_MORE } = constants.CHANNEL_PERSONALIZED_VALUES;
    const { channelRecommendationsByBook, herobanner } = this.context.props;
    const { channelData } = getSnapshot(channelRecommendationsByBook);
    const { channelId } = getSnapshot(herobanner);
    const { url } = channelData || {};
    const customFields = {
      channel_personalized: CHANNEL_FEATURES_LEARN_MORE,
      channelId
    };
    this.handleClickOrHoverChannelEventsForSol1D(customFields, true);

    if (url) {
      window.open(url);
    }
  }

  /**
   * Handles Go to page number text field change
   *
   * @param {String} updatedGoToPageNo
   */
  onGoToPageNoChange = (updatedGoToPageNo) => {
    const { herobanner } = this.context.props;
    const { GO_TO_PAGE } = constants.LAST_INTERACTION_HERO;
    let sanitizedGoToPageNo = xss(updatedGoToPageNo);
    if (sanitizedGoToPageNo?.includes('-')) sanitizedGoToPageNo = sanitizedGoToPageNo?.replace(/^-+/g, '');

    herobanner.setGoToPage(sanitizedGoToPageNo);
  }

  /**
   * Handles go to page input box blur
   *
   */
  onGoToPageInputBlur = () => {
    const { herobanner } = this.context.props;
    const { goToPage } = getSnapshot(herobanner);
    const { value } = goToPage;
    let renderValue = value;

    if (!renderValue?.includes('-')) return;

    renderValue = renderValue.replace(/^-+|-+$/g, '');

    if (renderValue?.length > 0) {
      herobanner.setGoToPage(renderValue);
    }
  }

  /**
   * Returns the current go to page number of hero book
   *
   * @returns
   */
  getGoToPageNo = () => {
    const { herobanner } = this.context.props;
    const { goToPage } = getSnapshot(herobanner);
    const { value, initialValue } = goToPage || {};
    let renderValue = value;

    if (renderValue?.includes('-')) {
      renderValue = renderValue.replace(/^-+|-+$/g, '');
    }

    return encodeURI(initialValue === renderValue ? encodeURI('') : encodeURI(renderValue));
  }

  /**
   * Returns Hero heading for book with mapped channel
   *
   * @param {Object} param0
   * @returns
   */
  static getHeroHeading = ({
    heroBook,
    language,
    previousHeading
  }) => {
    const courseName = heroBook?.courseName;
    let heroHeading = previousHeading;

    if (courseName) {
      heroHeading = language.getMessage('hero.heroHeadingV1').replace('{replace_value}', courseName);
    }

    return heroHeading;
  }
}
