/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * PopularChannelsSliderContanier
 *
 * @file PopularChannelsSliderContanier.jsx
 * @author Mohamed yasar arabath M
 */

import React, { Suspense, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CommonUtils from '../utils/CommonUtils';

const PopularChannelsSlider = CommonUtils.lazyWithPreload(() => import('@authhome/library')
  .then(module => ({ default: module.PopularChannelsSlider })));

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '34px',
    display: 'flex',
    justifyContent: 'center'
  }
});

const PopularChannelsSliderContanier = (props) => {
  const classes = useStyles(props);

  return (
    <Suspense fallback={<Fragment />}>
      <div className={classes.root}>
        <PopularChannelsSlider {...props} />
      </div>
    </Suspense>
  );
};

export default PopularChannelsSliderContanier;
