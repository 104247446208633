/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Hotspot utilities
 *
 * @file HotspotUtils.js
 * @author Gowtaman Madanselvaraj
 */

import { PDF_CONSTANTS, PDF_COVER_PAGE } from '../constants';

export default class HotspotUtils {
  /**
  * Function to return region type based on the document type
  *
  * @param {string} regionUrl
  */
  static getHotspotType(regionUrl) {
    const regionLink = regionUrl.toLowerCase();
    const regionType = {};

    if (regionLink.endsWith('.doc') || regionLink.endsWith('.docx')) {
      regionType.region = 'DOCUMENT';
    }
    if (regionLink.endsWith('.xls') || regionLink.endsWith('.xlsx')) {
      regionType.region = 'DOCUMENT';
    }
    if (regionLink.endsWith('.ppt') || regionLink.endsWith('.pptx')) {
      regionType.region = 'DOCUMENT';
    }
    if (regionLink.endsWith('.pdf')) {
      regionType.region = 'DOCUMENT';
    } else if (regionLink.endsWith('.mp4') || regionLink.endsWith('.m4v')) {
      regionType.region = 'VIDEO';
    } else if (regionLink.endsWith('.mp3')) {
      regionType.region = 'AUDIO';
    } else if (regionLink.endsWith('.jpg') || regionLink.endsWith('.jpeg')
      || regionLink.endsWith('.png') || regionLink.endsWith('.gif')) {
      regionType.region = 'IMAGE';
    } else {
      regionType.region = 'URL';
    }

    return regionType;
  }

  /**
   * Function to get clicked region details
   *
   * @param {Object} regionClicked
   */
  static getRegionDetails(regionClicked) {
    const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/; // eslint-disable-line no-useless-escape,max-len
    const clickedRegionDetails = regionClicked;

    clickedRegionDetails.hotspotType = '';
    switch (clickedRegionDetails.linkTypeId) {
      case 1:
        clickedRegionDetails.hotspotType = 'IMAGE';
        break;
      case 2:
      case 13:
        clickedRegionDetails.hotspotType = 'VIDEO';
        break;
      case 4:
      case 12:
        clickedRegionDetails.hotspotType = 'AUDIO';
        break;
      case 5:
        clickedRegionDetails.hotspotType = 'PAGENUMBER';
        break;
      case 8:
        clickedRegionDetails.hotspotType = 'EMAIL';
        break;
      case 11:
        clickedRegionDetails.hotspotType = 'LTILINK';
        break;
      case 7:
        clickedRegionDetails.hotspotType = this.getTypeFromRegion(
          clickedRegionDetails.regionTypeId, clickedRegionDetails.linkUri
        );
        break;
      case 9:
        clickedRegionDetails.hotspotType = this.getTypeFromRegion(
          clickedRegionDetails.regionTypeId, clickedRegionDetails.linkUri
        );
        break;
      case 15:
        clickedRegionDetails.hotspotType = this.getTypeFromRegion(
          clickedRegionDetails.regionTypeId, clickedRegionDetails.linkUri
        );
        break;
      default:
        clickedRegionDetails.hotspotType = 'URL';
    }
    if (clickedRegionDetails.hotspotType !== 'PAGENUMBER' || clickedRegionDetails.hotspotType !== 'EMAIL'
      || clickedRegionDetails.hotspotType !== 'LTILINK') {
      const linkUri = clickedRegionDetails.linkUri;
      const isSPPASSET = linkUri.match(/^http[s]?:\/\/mediaplayer\.pearsoncmg\.com\/assets/);

      if (isSPPASSET) {
        clickedRegionDetails.hotspotType = 'SPPASSET';
      }
      if (clickedRegionDetails.hotspotType === 'URL') {
        if (youtubeRegex.test(clickedRegionDetails.linkUri)) {
          clickedRegionDetails.hotspotType = 'VIDEO';
        }
      }
      if (clickedRegionDetails.hotspotType !== 'SPPASSET') {
        if (linkUri.endsWith('.htm') || linkUri.endsWith('.html')
          || isSPPASSET) {
          clickedRegionDetails.hotspotType = 'URL';
        }
      }
    }

    return clickedRegionDetails;
  }

  /**
   * Function to create https link
   *
   * @param {string} uri
   */
  static createHttps(uri) {
    let url = uri;

    if (/^http:\/\//i.test(uri)) {
      const link = uri.substring(4);

      url = `https${link}`;
    }

    return url;
  }

  /**
   * Function to disable the navigation icons until page load.
   *
   */
  static disableNavigation = () => {
    const navigationElement = document.getElementsByClassName('pageNavigationContainer')[0];

    if (navigationElement) {
      navigationElement.classList.add('navigation-disabled');
    }
  };

  /**
   * Function to enable the navigation icons after page load.
   *
   */
  static enableNavigation = () => {
    const navigationElement = document.getElementsByClassName('pageNavigationContainer')[0];

    if (navigationElement) {
      navigationElement.classList.remove('navigation-disabled');
    }
  };

  /**
   * Function to validate hotspot datatype is valid
   *
   * @param {Object} hotspotData
   * @param {string} platformId
   * @param {string} userRole
   * @param {Array} linkTypes
   * @param {Boolean} isFromToc
   */
  static isValidHotspot = (hotspotData, platformId, userRole, linkTypes, isFromToc = false) => {
    const {
      FLV,
      IPADAPP,
      JAZZASSET,
      LTILINK,
      SWF
    } = PDF_CONSTANTS.LINK_TYPE;
    const {
      FLV_FORMAT,
      SWF_FORMAT
    } = PDF_CONSTANTS.FILE_FORMAT;
    const {
      DEFAULT_ROLE,
      DEFAULT_ROLE_ID,
      INSTRUCTOR_ID,
      STUDENT,
      STUDENT_ID
    } = PDF_CONSTANTS.USER_ROLE;
    let userHasAccess = true;

    if (!isFromToc && hotspotData.linkTypeId && LTILINK === hotspotData.linkTypeId) {
      return platformId ? hotspotData.platformId && hotspotData.platformId.toString() === platformId : false;
    }
    const userRoleType = userRole === STUDENT ? STUDENT_ID : INSTRUCTOR_ID;

    if (isFromToc) {
      userHasAccess = hotspotData.role
        && hotspotData.role === DEFAULT_ROLE ? true : hotspotData.role === userRole;
    } else {
      userHasAccess = hotspotData.roleTypeId
        && hotspotData.roleTypeId === DEFAULT_ROLE_ID ? true : userRoleType === hotspotData.roleTypeId;
    }

    const invalidLinkTypes = linkTypes || [FLV, SWF, IPADAPP, JAZZASSET];
    const invalidContentTypes = [FLV_FORMAT, SWF_FORMAT];
    const isValidData = !invalidLinkTypes.includes(hotspotData.linkTypeId) && userHasAccess
      && !invalidContentTypes.some(contentType => hotspotData.linkUri.toString().endsWith(contentType));

    return isValidData;
  }

  static removeElementsByClass = (className) => {
    const elements = document.getElementsByClassName(className);

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  /**
   * Function to get the type of a region
   *
   * @param {string} regionId
   * @param {string} linkUri
   */
  static getTypeFromRegion = (regionId, linkUri) => {
    let hotspotType;
    let regionData;

    switch (regionId) {
      case 1:
        hotspotType = 'AUDIO';
        break;
      case 6:
        hotspotType = 'IMAGE';
        break;
      case 8:
      case 11:
        regionData = this.getHotspotType(linkUri);
        hotspotType = regionData.region;
        break;
      case 9:
      case 13:
      case 14:
      case 15:
        hotspotType = 'DOCUMENT';
        break;
      case 12:
        hotspotType = 'VIDEO';
        break;
      default:
        hotspotType = 'URL';
    }

    return hotspotType;
  }

  /**
   * Function to return play order from playlist
   *
   * @param {Object} asset
   * @param {string} bundleId
   * @param {string} pageNo
   * @param {Boolean} serverSideEnabled
   * @param {Object} product
   */
  static getPlayOrder = (asset, bundleId, pageNo, serverSideEnabled, product) => {
    const isCover = pageNo === PDF_COVER_PAGE;
    const pagePlaylist = bundleId ? asset && asset.children : asset && asset.slates;
    let pageNumber = pageNo;
    const spdfPlaylist = asset && asset.slates && asset.getPagePlaylist(
      bundleId,
      product,
      serverSideEnabled
    );

    if (bundleId) {
      const bundledPage = JSON.parse(atob(bundleId)); // eslint-disable-line

      pageNumber = bundledPage.startPage;
    }
    let page = !isCover && pagePlaylist && pagePlaylist.find((item => item.pageno === pageNumber));

    if (serverSideEnabled) {
      page = !isCover && spdfPlaylist && spdfPlaylist.find((item => item.pagenumber === pageNumber));
    }
    let playOrder = {};

    if (pagePlaylist) {
      playOrder = {
        pageId: isCover ? PDF_COVER_PAGE : page && page.playOrder,
        deeplink: true
      };
    }

    return playOrder;
  }

  /**
   * Function to return hotspot based on role
   *
   * @param {Object} hotspotData
   * @param {string} userRole
   */
  static isValidRole = (hotspotData, userRole) => {
    let userHasAccess = true;
    userHasAccess = hotspotData.role
      && hotspotData.role === PDF_CONSTANTS.USER_ROLE.DEFAULT_ROLE ? true : hotspotData.role === userRole;

    return userHasAccess;
  }
}
