/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateG
 *
 * @file TemplateG.jsx
 * @author Sanjay Kumar jayswal
 */

import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Box,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TemplateGStyles from "./styles/templateGStyles";
import CommonUtils from "../../../common/utils/CommonUtils";
import { OPEN_EREADER_COMPONENT } from "../../../common/constants";

const TemplateG = ({ data, callbackForBraze }) => {
  const {
    styles,
    title,
    description,
    backgroundImage,
    backgroundColor,
    action,
    showDismiss,
    position,
  } = data;
  const {
    onViewCard,
    onDismiss,
    logContentCardClick,
    openEreaderComponentFromBraze,
  } = callbackForBraze;
  const [open, setOpen] = useState(false);
  const classes = TemplateGStyles(styles)();
  const dismiss = (event = null) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setOpen(false);
    onDismiss();
  };
  const snackbarPosition = {
    CENTER: "positionCenter",
    BOTTOMRIGHT: "positionBottomRight",
    TOPRIGHT: "positionTopRight",
    BOTTOMLEFT: "positionBottomLeft",
    TOPLEFT: "positionTopLeft",
  };

  const handleCtaClick = (actionData) => {
    const {
      target,
      launchURL,
      allowDismiss,
      logClickEvent,
      type,
      componentToShow,
    } = actionData;

    if (logClickEvent) {
      logContentCardClick();
    }
    if (
      type &&
      componentToShow &&
      type.toUpperCase() === OPEN_EREADER_COMPONENT
    ) {
      openEreaderComponentFromBraze(componentToShow);
    } else if (launchURL) {
      window.open(launchURL, target || "_self");
    }
    if (allowDismiss) {
      dismiss();
    }
  };

  useEffect(() => {
    if (data) {
      setOpen(true);
      onViewCard();
    }
  }, [data && data.show]);

  return (
    data && (
      <Snackbar
        className={`${classes.snackbar} ${
          position ? classes[snackbarPosition[position.toUpperCase()]] : ""
        } 
        ${classes.window}`}
        classes={{ anchorOriginTopRight: classes.anchorOriginTopRight }}
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box>
          {showDismiss && (
            <IconButton
              onClick={(event) => dismiss(event)}
              size="small"
              classes={{ root: classes.iconRoot }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          <CardMedia
            classes={{ root: classes.cardMedia }}
            style={{ backgroundColor }}
            image={backgroundImage}
          >
            <CardContent className={classes.rootCardContent}>
              <div className={classes.contentCardContanier}>
                <div className={classes.contentArea}>
                  {title && (
                    <Typography
                      className={classes.title}
                      gutterBottom
                      variant="h5"
                      component="h2"
                    >
                      {title}
                    </Typography>
                  )}
                  {description && (
                    <Typography
                      className={classes.description}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {description}
                    </Typography>
                  )}
                  {action &&
                    action.map((actionData, index) => (
                      <Button
                        classes={{ root: classes.buttonContainer }}
                        aria-label={`${actionData.text}`}
                        role="link"
                        key={index}
                        onClick={() => handleCtaClick(actionData)}
                      >
                        {actionData.text}
                      </Button>
                    ))}
                </div>
              </div>
            </CardContent>
          </CardMedia>
        </Box>
      </Snackbar>
    )
  );
};

TemplateG.defaultProps = {
  data: {
    styles: {},
    imageUrl: "",
    backgroundImage: "",
    action: [],
    campaignClickAction: {},
    showDismiss: false,
    position:""
  },
  callbackForBraze: {
    onViewCard: () => {},
    onDismiss: () => {},
    openEreaderComponentFromBraze: () => {},
  },
};

TemplateG.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    styles: PropTypes.object,
    position: PropTypes.string,
    backgroundImage: PropTypes.string,
    action: PropTypes.array,
    campaignClickAction: PropTypes.object,
    showDismiss: PropTypes.bool,
  }).isRequired,
  callbackForBraze: PropTypes.shape({
    onViewCard: PropTypes.func,
    onDismiss: PropTypes.func,
    openEreaderComponentFromBraze: PropTypes.func,
  }).isRequired,
};

export default memo(TemplateG);
