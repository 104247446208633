
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Custom TOC Mapper util.
 *
 * @file TocMapper.js
 * @author Arish Kumar K
 */


export default class TocMapper {
  static tocData(items, contextId) {
    const contents = [];
    let parentId = '';

    function drillToDeep(item, chapterId) {
      const node = item;
      const href = node.href || node.uri || '';
      const index = href && href.indexOf('/OPS/');
      const hrefUri = index >= 0 ? href.substr(index, href.length) : href;
      const list = {
        id: node.id,
        title: node.title || node.label,
        type: node.type || ''
      };

      /**
       * Add to list only if below attrs are available
       */
      if (hrefUri) {
        list.uri = hrefUri;
      }
      if (node.sectionType) {
        list.sectionType = node.sectionType; 
      }
      if (node.playOrder) {
        list.playOrder = node.playOrder; 
      }
      if (node.optional) {
        list.optional = node.optional;
      }
      if (node.hidden) {
        list.optional = (node.hidden).toString();
      }
      if (node.versionId) {
        list.versionId = node.versionId;
      }
      if (node.parentId) {
        list.parentId = node.parentId;
      }
      if (node.parentVersionId) {
        list.parentVersionId = node.parentVersionId;
      }
      if (node.usageType) {
        list.usageType = node.usageType;
      }
      const children = node.items || node.children || node.content;
      if (children) {
        list.children = [];
        children.forEach((child) => {
          // Code removed because we discarded part in response
          parentId = list.id; // assigned here for get revel cite child id by parent
          // eslint-disable-next-line no-param-reassign
          child.level = node.level + 1;
          list.children.push(drillToDeep(child, chapterId));
        });
      }

      return list;
    }
    if (items) {
      items.forEach((node) => {
        if (!node.part) {
          // eslint-disable-next-line no-param-reassign
          node.level = 1;
          const nodes = drillToDeep(node, node.id);
          contents.push(nodes);
        }
      });
    }
    const optionalList = [];
    let list = contents.filter((chapter) => {
      if (chapter.optional) {
        optionalList.push(chapter);
      }

      return !chapter.optional;
    });

    list = Array.prototype.concat.apply(list, optionalList);
    const tocData = {
      id: contextId,
      list
    };

    return { tocData };
  }

  static isAudioAvailableInChapter = (node) => {
    const audio = node.children && node.children.find(child => child.audio);

    // eslint-disable-next-line no-param-reassign
    node.isAudioAvailableInChapter = Boolean(audio);

    return node;
  }

  static setManifest = (data) => {
    this.manifest = data;
  };

  static getManifest = () => (this.manifest ? this.manifest : null);

  static getTocAndAudioContents(
    tocChildren,
    bookId,
    courseId,
    isPdfContent = false,
    languages = {},
    enableTOCPrintPage = false,
    isLDFlagsFetched = false
  ) {
    const audioContents = [];
    const contents = [];
    let audio = null;
    let assessmentId = '';
    // if book id is empty then course id will be used.
    const id = bookId || courseId;
    let parentId = '';
    let titleAudio = null;

    function drillToDeep(item, chapterId, chapterIndex) {
      const node = item;
      const href = node.href || node.uri || '';
      let hrefUri = href;

      if (['assessment', 'tdx', 'cite-assessment'].indexOf(node.type) !== -1) {
        assessmentId = node.uri;
      }
      if (!isPdfContent) {
        const index = href && href.indexOf('/OPS/');

        hrefUri = index >= 0 ? href.substr(index, href.length) : href;
      }
      // Custom Basket : Makes as empty when href comes as "undefined"
      if (hrefUri === 'undefined') {
        hrefUri = '';
      }
      if (node.audio && node.audio.length >= 1) {
        audio = [{
          audioIndex: '0',
          id: node.id,
          title: node.title,
          parentId,
          chapterId,
          src: node.audio,
          audioMetadata: node.audioTTSMetadata && node.audioTTSMetadata
        }];
      } else {
        audio = null;
      }
      if (audio && audio.length === 1) {
        titleAudio = audio[0];
      } else {
        titleAudio = null;
      }

      // Audio duplicate issue fix
      if (node.children && node.children.length) {
        const childList = [];
        node.children.forEach((child) => {
          const childHref = child.href || child.uri || '';
          if (childHref && childHref.includes('#')) {
            childList.push(childHref.split('#')[0]);
          }
        });
        if (childList.includes(href)) {
          titleAudio = null;
        }
      }
      // End

      const list = {
        assessmentId,
        audio: titleAudio,
        chapterId,
        children: [],
        coPage: node.coPage === 'true',
        display: node.display !== false,
        hidden: node.optional === 'true',
        href: hrefUri,
        id: node.id,
        label: node.level ? String(node.level) : '',
        parentItemId: parentId,
        playOrder: node.playOrder,
        title: node.title || node.label,
        type: node.type || '',
        urn: node.id || node.path,
        // PDF attributes
        linkTypeId: node.linkTypeId || '',
        linkUri: node.linkUri || '',
        linkX: node.linkX || '',
        linkY: node.linkY || '',
        name: node.name || '',
        regionTypeId: node.regionTypeId || '',
        sectionType: node.sectionType || '',
        contentChapterId: isPdfContent && node.chapterId ? node.chapterId : '',
        level: node.level || '',
        actualPageUri: node.actualPageUri || '',
        pageNo: enableTOCPrintPage && isLDFlagsFetched ? node.pageNumber || node.pageNo || node.pageno || null : null,
        pdfParentId: node.parentId
      };
      if (node.type && (node.type.toLowerCase() === 'chapter')) {
        list.chapterNumber = chapterIndex;
      }

      if (audio && audio.length > 1) {
        audio.forEach((audioTrack, index) => {
          list.children.push({
            assessmentId,
            audio: audioTrack,
            chapterId,
            children: [],
            coPage: false,
            display: false,
            hidden: false,
            href: '',
            id: `${node.id}&&${index}`, // Added && to differentiate from numerical page ids
            label: node.level ? String(node.level + 1) : '',
            parentItemId: node.id,
            playOrder: '',
            chapterNumber: '',
            title: `${languages.getText('audio')} ${index + 1}`,
            type: '',
            urn: `${node.id}&&${index}` // Added && to differentiate from numerical page ids
          });
        });
      }

      if (node.level === 1 && node.optional === 'true') {
        list.optional = node.optional;
      }

      if (audio && audio.length) {
        audio.forEach((audioObj) => {
          // Objects make to find next track for autoplay
          const audioInfo = {
            ...audioObj,
            isPlaying: false
          };

          audioContents.push(audioInfo);
        });
      }
      const children = node.items || node.children || node.content;

      if (children) {
        children.forEach((child) => {
          // Code removed because we discarded part in response
          parentId = list.id; // assigned here for get revel cite child id by parent
          const audioArr = [];
          if (child.audio && child.audio.length >= 1) {
            audioArr.push({
              audioIndex: '0',
              id: child.id,
              title: child.title,
              parentId,
              chapterId,
              src: child.audio,
              audioMetadata: child.audioTTSMetadata && child.audioTTSMetadata
            });
          }
          // if (node.level >= env.DEFAULT_TOC_DEPTH) {
          if (node.level >= 2) {
            list.audio = list.audio && list.audio.src ? list.audio : audioArr;
          }
          // eslint-disable-next-line no-param-reassign
          child = {
            ...child,
            level: node.level + 1
          };
          if (child.uri || child.children || child.items) {
            list.children.push(drillToDeep(child, chapterId));
          }
        });
      }
      parentId = '';
      audio = null;

      return list;
    }
    if (tocChildren) {
      tocChildren.forEach((node, tocIndex) => {
        if (!node.part) {
          const nodes = TocMapper.isAudioAvailableInChapter(drillToDeep(node, node.id, tocIndex + 1));
          contents.push(nodes);
        }
      });
    }
    const optionalList = [];
    let tocList = contents.filter((chapter) => {
      if (chapter.optional === 'true') {
        optionalList.push(chapter);
      }

      return !chapter.optional;
    });

    tocList = Array.prototype.concat.apply(tocList, optionalList);
    const tableOfContents = {
      id,
      list: tocList
    };

    return {
      audioContents,
      tableOfContents
    };
  }
}
