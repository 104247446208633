/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

/**
 * @author Sandeep and Arish Kumar K
 */
export default class AssetService extends BaseService {
  constructor() {
    super(constants.ASSET_REQUESTED, 'assetStatus');
    this.isFromCourse = false;
    this.isFromAuthHome = false;
  }

  handleEvent(eventName, event) {
    this.isFromAuthHome = event.isFromAuthHome;
    const getPath = event.courseId ? `&id=${encodeURI(event.courseId)}` : '';
    if (event.bundleId) {
      if (event.isIntergratedMLMLaunch && event.format !== constants.CONTENT_TYPE_PXE) { // intergartedMLM Launch and book format other than PXE
        const productDetails = CommonUtils.deCodeBase64(event.bundleId); // Fetching product details from bundle id
        if (productDetails && productDetails.productId) { // If product id is available then we directly pass it to assest toc
          return this.getClient('prism').get(`/contenttoc/v1/assets?productId=${productDetails.productId}${getPath}`);
        }
      }
      return this.getClient('prism').get(`/contenttoc/v1/assets/da?bundleId=${event.bundleId}`);
    }
    if (event.courseId) {
      this.isFromCourse = true;
    }
    return this.getClient('prism').get(`/contenttoc/v1/assets?productId=${event.productId}${getPath}`);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('asset').set(response.data, this.isFromCourse, this.isFromAuthHome);
  }

  /**
   * Method to handle Asset call failure
   *
   */
  handleError() { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.ASSET_FAILED);
  }
}
