/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { getSnapshot } from 'mobx-state-tree';
import { ACTIVE, CHANNEL_PARTNER, HERO_BANNER_TYPES } from '../../common/constants';
import CommonUtils from '../../common/utils/CommonUtils';
import env from '../../common/env';

/**
 * @author Manimaran S
 */
export default class BrazeTemplatePlugin {
  constructor(context) {
    this.templatesContext = context;
    this.operations = ['eq', 'not', 'gte', 'lte', 'days_gte', 'days_lte'];
    this.ruleKeys = {};
    this.setAuthHomeDefaultContext();
    this.setEReaderDefaultContext();
  }

  /**
   * Setting authhome default context
   */
  setAuthHomeDefaultContext = () => {
    this.ruleKeys = {
      studyBaseUrl: env.STUDY_CHANNEL_URL,
      isPPlusUser: false,
      hasActiveBookStoreSubscription: false,
      isAllActiveBookStoreHavePPlusEntitlements: false,
      isSolution1D: false,
      hasMappedChannel: false,
      channelId: null
    };
  }

  /**
   * Setting ereader default context
   */
  setEReaderDefaultContext = () => {
    this.ruleKeys.isAIEnabled = false;
  }

  /**
   * Set AuthHome context
   */
  setAuthHomeContext = () => {
    const { bookshelf, user, herobanner } = this.templatesContext.props;
    const books = getSnapshot(bookshelf);
    const heroBannerStore = getSnapshot(herobanner);
    const isSolution1D = !!(heroBannerStore.channelId || heroBannerStore.bannerType !== HERO_BANNER_TYPES.BOOK);
    this.setAuthHomeDefaultContext();
    this.ruleKeys.isPPlusUser = CommonUtils.isPPlusUser(user);
    this.ruleKeys.hasActiveBookStoreSubscription = CommonUtils.hasSubscriptionWithSource(
      user,
      CHANNEL_PARTNER,
      ACTIVE
    );
    this.ruleKeys.isAllActiveBookStoreHavePPlusEntitlements = CommonUtils.isAllActiveBookStoreHavePPlusEntitlements(
      getSnapshot(user),
      books.books
    );
    this.ruleKeys.isSolution1D = isSolution1D;
    this.ruleKeys.hasMappedChannel = !!heroBannerStore.channelId;
    this.ruleKeys.channelId = heroBannerStore.channelId;
  }

  /**
   * Set Ereader context
   */
  setEReaderContext = () => {
    this.setEReaderDefaultContext();
    const product = this.templatesContext.commonPlugin.getProduct();
    this.ruleKeys.isAIEnabled = this.templatesContext.commonPlugin.isAIEnabled();
    this.ruleKeys.hasMappedChannel = !!product.channel_id;
  }

  /**
   * Returns only eligible cards
   * @param {*} cards
   * @returns
   */
  getEligibleBrazeContentCard = (cards) => {
    const totalCards = [];
    if (Array.isArray(cards)) {
      cards.forEach((card) => {
        if (this.checkBrazeRules(card)) {
          const renderCard = this.matchContext(card);
          totalCards.push(renderCard);
        }
      });
    }

    return totalCards;
  }

  /**
   * To get the context value
   *
   * @param {*} name
   * @returns
   */

  getRuleActualValue = (name) => {
    let actualValue;

    if (name.indexOf('.') !== -1) {
      const nameList = name.split('.');
      actualValue = nameList.reduce((previousNode, currentNode) => {
        let traversal;
        if (typeof previousNode !== 'undefined' && previousNode.hasOwnProperty(currentNode)) {
          traversal = previousNode[currentNode];
        }

        return traversal;
      }, this.ruleKeys);
    } else {
      actualValue = this.ruleKeys[name];
    }

    return actualValue;
  }

  /**
   * To match the context
   *
   * @param {*} text
   * @returns
   */

  matchIdentifier = (text) => {
    const contextIdentifierMatches = text.match(/{context\.[^\\{]+}/g);
    let renderText = text;
    if (contextIdentifierMatches) {
      contextIdentifierMatches.forEach((contextIdentifier) => {
        const value = contextIdentifier.replace(/{|}|context\./g, '');
        renderText = renderText.replace(contextIdentifier, this.getRuleActualValue(value) || '');
      });
    }

    return renderText;
  }

  /**
   * Replace placeholder with actual value of context.
   *
   * @param {*} card
   * @returns
   */

  matchContext = (card) => {
    const keys = ['title', 'description', 'extras'];
    const renderCard = { ...card };
    keys.forEach((key) => {
      if (renderCard[key] && key === 'extras' && renderCard[key].action && renderCard[key].action.length > 0) {
        const actions = [];
        const extrasActions = JSON.parse(renderCard[key].action);
        extrasActions.forEach((action) => {
          if (action.launchURL) {
            const value = this.matchIdentifier(action.launchURL);
            actions.push({ ...action, launchURL: value });
          } else {
            actions.push({ ...action });
          }
        });
        renderCard[key].action = JSON.stringify(actions);
      } else if (renderCard[key] && (key === 'title' || key === 'description')) {
        const value = this.matchIdentifier(renderCard[key]);
        renderCard[key] = value;
      }
    });

    return { ...renderCard };
  }

  /**
   * Returns eligible criteria
   * @param {*} card
   * @returns
   */
  checkBrazeRules = (card) => {
    let eligible = true;
    if (card.rules && card.rules.length > 0) {
      eligible = card.rules.some(({ condition }) => this.isRuleBrazeMatch(condition));
    }

    return eligible;
  };

  /**
   * Function to check matching conditions
   * @param {*} condition
   * @returns
   */
  isRuleBrazeMatch = (condition) => {
    let satisfied = false;
    if (condition) {
      satisfied = condition.every(({ name, value, operation }) => {
        let conditionCheck = false;
        const actualValue = this.getRuleActualValue(name);
        if (typeof actualValue !== 'undefined') {
          conditionCheck = this.operationCheck(actualValue, value, operation, Array.isArray(actualValue));
        }

        return conditionCheck;
      });
    }

    return satisfied;
  }

  /**
   * Returns actual rule value
   * @param {*} name
   * @returns
   */
  getRuleActualValue = name => this.ruleKeys[name]

  /**
   * Function to perform operational check
   * @param {*} actualValue
   * @param {*} value
   * @param {*} operation
   * @param {*} isArray
   * @returns
   */
  operationCheck = (actualValue, value, operation, isArray = false) => {
    let conditionCheck = false;
    let noOfDays = 0;
    const [eq, not, gte, lte, daysGte, daysLte] = this.operations;
    switch (operation) {
      case eq:
        conditionCheck = isArray ? actualValue.includes(value) : actualValue === value;
        break;
      case not:
        conditionCheck = isArray ? !actualValue.includes(value) : actualValue !== value;
        break;
      case gte:
        conditionCheck = actualValue >= parseInt(value, 10);
        break;
      case lte:
        conditionCheck = actualValue <= parseInt(value, 10);
        break;
      case daysGte:
        noOfDays = CommonUtils.getNoOfDays(new Date(), actualValue);
        conditionCheck = (noOfDays >= 0 && noOfDays >= value);
        break;
      case daysLte:
        noOfDays = CommonUtils.getNoOfDays(new Date(), actualValue);
        conditionCheck = (noOfDays >= 0 && noOfDays <= value);
        break;
      default:
        break;
    }

    return conditionCheck;
  }

  /**
   * Fucntion to handle the priority of braze cards
   * @param {*} cards
   * @returns
   */
  orderByPriority = (cards) => {
    const notPrioritized = [];
    const prioritized = [];
    cards.forEach((card) => {
      if (card?.data?.priority) {
        prioritized.push(card);
      } else {
        notPrioritized.push(card);
      }
    });
    if (prioritized.length > 0) {
      prioritized.sort((a, b) => {
        const currentSlidePos = parseInt(a.data.priority, 10);
        const nextSlidePos = parseInt(b.data.priority, 10);
        return currentSlidePos - nextSlidePos;
      });
    }

    return [...prioritized, ...notPrioritized];
  }
}
