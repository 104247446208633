/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * HeaderBanner
 *
 * @file HeaderBanner.jsx
 * @author Gladson Samuel S
 */

import React, { memo, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  CardContent, Typography, CardMedia, Button, Box
} from '@material-ui/core';
import HeaderBannerStyles from './styles/HeaderBannerStyles';
import CommonUtils from '../../../utils/CommonUtils';

const HeaderBanner = ({ data, callbacks }) => {
  const {
    id,
    title,
    description,
    backgroundColor,
    backgroundImage,
    action,
    fontColor
  } = data;
  const {
    onCTAClick
  } = callbacks;
  const classes = HeaderBannerStyles(backgroundColor, fontColor)();
  const isBackgroundImageInList = Array.isArray(backgroundImage);

  /**
   * Fucntion to get resolution based image source
   * @returns
   */
  const getSrcBasedOnResolution = () => {
    const currentWidth = window.innerWidth || document.documentElement.clientWidth;
    const currentImgSrc = backgroundImage.findLast(background => background.breakPoint <= currentWidth);

    return (currentImgSrc && currentImgSrc.imageURL) || null;
  };

  /**
   * Function to get campaign background image
   * @returns
   */
  const getBackgroundImage = () => (isBackgroundImageInList ? getSrcBasedOnResolution() : backgroundImage);
  const [imgSrc, setImgSrc] = useState(getBackgroundImage());

  /**
   * Function to handle resize
   */
  function handleResize() {
    if (backgroundImage && isBackgroundImageInList) {
      setImgSrc(getSrcBasedOnResolution());
    }
  }

  useEffect(() => {
    window.addEventListener('resize', CommonUtils.debounce(handleResize));

    return () => window.removeEventListener('resize', CommonUtils.debounce(handleResize));
  }, []);


  return (
    data && (
      <Box
        className={classes.cardWrapper}
        id={id}
      >
        <CardMedia
          classes={{ root: classes.cardMedia }}
          image={imgSrc}
        >
          <CardContent className={classes.rootCardContent}>
            <div className={classes.contentArea}>
              <div className={classes.copyTextWrapper}>
                {title && (
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                  {ReactHtmlParser(title)}
                </Typography>
                )}
                {description && (
                <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
                  {ReactHtmlParser(description)}
                </Typography>
                )}
              </div>
              <div className={classes.actionBtnWrapper}>
                {action && action.map((actionData, index) => (
                  <Button
                    classes={{ root: classes.primaryCtaBtn }}
                    aria-label={`${actionData.text}`}
                    role="link"
                    key={index}
                    onClick={() => onCTAClick(actionData)}
                  >
                    {actionData.text}
                  </Button>
                ))}
              </div>
            </div>
          </CardContent>
        </CardMedia>
      </Box>
    )
  );
};

HeaderBanner.defaultProps = {
  data: {
    description: null,
    styles: {},
    backgroundImage: '',
    action: [],
    showDismiss: false,
    showDescription: false
  },
  callbackForBraze: {
    onCTAClick: () => {}
  }
};

HeaderBanner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    action: PropTypes.array,
    showDescription: PropTypes.bool
  }).isRequired,
  callbacks: PropTypes.shape({
    onCTAClick: PropTypes.func
  }).isRequired
};

export default memo(HeaderBanner);
