/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to pdf Hotspot
 *
 * @file PdfHotspot.js
 * @author Gowtaman Madanselvaraj
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';

import HotspotUtils from '../../../common/utils/HotspotUtils';

import * as constants from '../../../common/constants';

/**
 * A mobx model for hotspot children
 *
 */
const Children = types.model(
  'Children',
  {
    regionId: types.maybeNull(types.union(types.string, types.integer, types.number)),
    regionTypeId: types.maybeNull(types.union(types.string, types.integer, types.number)),
    linkUri: types.maybeNull(types.string),
    imageUri: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    playOrder: types.maybeNull(types.union(types.string, types.integer, types.number)),
    linkType: types.maybeNull(types.string, ''),
    pageno: types.maybeNull(types.union(types.string, types.integer, types.number)),
    x: types.maybeNull(types.union(types.string, types.integer, types.number)),
    y: types.maybeNull(types.union(types.string, types.integer, types.number)),
    width: types.maybeNull(types.union(types.string, types.integer, types.number)),
    height: types.maybeNull(types.union(types.string, types.integer, types.number)),
    linkTypeId: types.maybeNull(types.union(types.string, types.integer, types.number)),
    transparent: types.maybeNull(types.boolean),
    glossary: types.maybeNull(types.union(types.string, types.integer, types.number)),
    isIcon: types.maybeNull(types.union(types.string, types.integer, types.number)),
    platformId: types.maybeNull(types.integer),
    iconTypeId: types.maybeNull(types.number),
    roleTypeId: types.maybeNull(types.number)
  }
);

/**
 * A mobx model for hotspot
 *
 */
const Hotspot = types.model(
  'Hotspot',
  {
    children: types.array(Children),
    pageNo: types.maybeNull(types.string),
    productId: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for PDF hotspot
 *
 */
const PdfHotspot = types.model(
  'PdfHotspot',
  {
    hotspot: types.array(Hotspot)
  }
).views(self => ({
  getHotspotData(regionId, glossaryData) {
    let glossaryItem = null;
    const hotspotId = parseInt(regionId.split('_')[1], 10);
    const hotspots = [];

    self.hotspot.forEach((hotspotData) => {
      hotspotData.children.forEach((data) => {
        hotspots.push(data);
      });
    });
    const hotspotItem = hotspots.find(item => item.regionId === hotspotId);
    const hotspotType = hotspotItem && hotspotItem.glossary != null ? 'glossary' : 'hotspot';

    if (hotspotType === 'glossary') {
      glossaryItem = glossaryData.searchResults.find(item => item.source.itemId === hotspotItem.glossary);
    }
    const hotspotData = {
      hotspotType,
      regionData: hotspotItem,
      glossaryData: {
        glossaryAudio: glossaryItem && glossaryItem.source.completeAudio,
        glossaryDefinition: glossaryItem && glossaryItem.source.definition,
        glossaryTerm: glossaryItem && glossaryItem.source.term
      }
    };

    return hotspotData;
  },
  /**
   * Filter the hotspot data by checking valid types
   *
   * @param {Array} hotspotData
   * @param {string} platformId
   * @param {string} userRole
   */
  filterHotspots(hotspotData, platformId, userRole) {
    const filteredHotspotData = hotspotData.filter(item => HotspotUtils.isValidHotspot(item, platformId, userRole));

    return filteredHotspotData;
  },
  /**
   * construct hotspot data
   *
   * @param {Array} hotspotData
   * @param {string} platformId
   * @param {string} userRole
   */
  constructHotspot(hotspotData, platformId, userRole) {
    const pdfHotspotData = [];

    hotspotData.forEach((data) => {
      const hotspotObj = {};
      hotspotObj.children = [];
      hotspotObj.pageNo = data.pageNo;
      hotspotObj.children = self.filterHotspots(
        data.children,
        platformId,
        userRole
      );
      pdfHotspotData.push(hotspotObj);
    });

    return pdfHotspotData;
  }
})).actions(self => ({

  fetch(productId, pageId) {
    if (self.id === productId) {
      Framework.getEventManager().publish(constants.PDF_HOTSPOT_FETCHED, {});
    } else {
      Framework.getEventManager().publish(constants.PDF_HOTSPOT_REQUESTED, {
        productId,
        pageId
      });
    }
  },

  /**
   * Set the PDF hotspot data to the store
   *
   * @param {Object} pdfHotspot
   */
  setPdfHotspot(pdfHotspot) {
    applySnapshot(self, pdfHotspot);
    Framework.getEventManager().publish(constants.PDF_HOTSPOT_FETCHED, {});
  }
}));

export default PdfHotspot;
