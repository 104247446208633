/* eslint-disable class-methods-use-this */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Channel recommendations by book service
 *
 * @file ChannelRecommenationsByBookService.js
 * @author Manimaran S
 */

import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class ChannelRecommenationsByBookService extends BaseService {
  constructor() {
    super(constants.CHANNEL_RECOMMENDATIONS_BY_BOOK_REQUESTED, 'channelRecommemdationsByBookStatus');
  }

  /**
   * Trigger the study channel recommendations by book api call
   *
   * @returns
   */
  handleEvent(eventName, eventData = {}) {
    const {
      bookId,
      channelId,
      subChapterName
    } = eventData;

    const payload = {
      bookId,
      subModule: subChapterName,
      channelName: channelId
    };

    return this.getClient('marin').post('/studyChannel/recommendations', payload);
  }

  /**
   * Handle study channel recommendations by book api response and setting ChannelRecommendationsByBook store
   *
   * @param {*} response
   */
  handleResponse(response) {
    const recommendations = response.data;
    Framework.getStoreRegistry().getStore('channelRecommendationsByBook').set(recommendations);
  }

  /**
   * Error handling
   *
   * @param {*} error
   */
  handleError(error) {
    const channelRecommendationsByBook = Framework.getStoreRegistry().getStore('channelRecommendationsByBook');
    channelRecommendationsByBook.setError(error);
  }
}
