/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { makeStyles } from '@material-ui/core';

/**
 * TemplateEStyles
 *
 * @file TemplateEStyles.jsx
 * @author Manimaran S
 */

const TemplateEStyles = (styles = {}, backgroundColor, maxCardHeight) => makeStyles({
  cardWrapper: {
    position: 'relative'
  },
  cardMedia: {
    borderRadius: 12,
    display: 'flex',
    boxSizing: 'border-box',
    background: `${backgroundColor || '#272d39'}`
  },
  iconRoot: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#000',
    background: '#d9d9d9A6',

    '&:hover': {
      color: '#fff',
      background: '#000'
    },
    '& svg': {
      fontSize: 12
    }
  },
  rootCardContent: {
    width: '100%',
    alignContent: 'center',
    padding: '16px !important',

    '@media (min-width: 834px)': {
      padding: '24px !important'
    }
  },
  title: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    color: '#FEFEFE',
    margin: 0
  },
  description: {
    color: '#020917',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0.24,
    fontFamily: 'TT Commons',

    '@media (min-width: 1280px)': {
      fontSize: 18,
      lineHeight: '24px'
    }
  },
  contentArea: {
    wordWrap: 'break-word',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    justifyContent: 'center'
  },
  copyTextWrapper: {
    wordWrap: 'break-word',
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  },
  actionBtnWrapper: {
    paddingBottom: 5
  },
  buttonContainer: {
    display: 'flex',
    padding: '8px 24px',
    justifyContent: 'center',
    borderRadius: 40,
    color: '#FEFEFE',
    width: 'fit-content',
    border: '2px solid #D2D7E2',

    '& span.MuiButton-label': {
      color: 'inherit',
      fontSize: 18,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.4px'
    },
    '&:hover, &.Mui-focusVisible': {
      background: '#F7F9FD',
      color: '#020917'
    },
    '&.Mui-focusVisible': {
      background: '#F7F9FD',
      border: '2px dashed #161C29',
      boxShadow: '0px 0px 0px 2px #F7F9FD',
      color: '#020917',
      '&:after': {
        border: 'none'
      }
    }
  },
  primaryCtaBtn: {
    boxSizing: 'border-box',
    background: 'linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%)',
    borderRadius: 40,
    display: 'flex',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      background: 'linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%)'
    },
    '&.Mui-focusVisible': {
      background: 'linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%)',
      border: '2px dashed #DDE3EE',
      boxShadow: '0px 0px 0px 2px #B41863',
      '&::after': {
        border: 'none'
      }
    },
    '& span': {
      color: '#FEFEFE',
      fontSize: 18,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: 0.4,
      whiteSpace: 'nowrap'
    }
  },
  ...styles
});
export default TemplateEStyles;
