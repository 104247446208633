/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of subscription software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import React, { Fragment, Suspense } from 'react';
import {
  APPSFLYERS_AUTHHOME,
  APPSFLYERS_EREADER,
  AUTHHOME_APPLICATION,
  AUTH_HOME,
  EREADER,
  EREADER_CATEGORY,
  LOCATION_IN_APP,
  NATIVE_APP_BANNER_DATA,
  NATIVE_APP_BANNER_EVENTS,
  NATIVE_APP_PROMO_TYPES,
  PISESSION_ID,
  APPSFLYERS_QRCODE_AUTHHOME,
  APPSFLYERS_QRCODE_EREADER
} from '../constants';
import CommonUtils from '../utils/CommonUtils';
import env from '../env';

const NativeAppBanner = CommonUtils.lazyWithPreload(() => import('@authhome/native-app-banner')
  .then(module => ({ default: module.NativeAppBanner })));

/**
  * NativeAppBannerPlugin
  *
  * @author Gladson Samuel S
*/
export default class NativeAppBannerPlugin {
  constructor(context, location = AUTH_HOME) {
    this.commonContext = context;
    this.location = location;
  }

  /**
   * Initialize native app banners
   *
   */
  initializeNativeAppBanners = () => {
    const { enableNativeAppPromoBanners } = this.commonContext?.state?.flags || {};
    const isIntergratedMLMLaunch = CommonUtils.isIntergratedMLMLaunch();

    if (!isIntergratedMLMLaunch && enableNativeAppPromoBanners) {
      const showDrawer = this.showNativeAppDrawer();

      this.commonContext.setState({
        nativeAppBannerConfig: {
          showDrawer,
          showBanner: !showDrawer,
          showBannerClose: true
        }
      });
    }
  }

  /**
   * Determines and returns whether to show Native app banner or not
   *
   */
  // eslint-disable-next-line class-methods-use-this
  showNativeAppDrawer() {
    const isGhostAccount = CommonUtils.isGhostAccount();
    const currentSessionId = window.piSession.getContextId();
    const previousSessionId = CommonUtils.getSessionStorge(PISESSION_ID);

    // Drawer will not be shown for ghost account
    if (isGhostAccount) return false;

    // If session id from session storage is same as piSessionId we will not show the banner
    if ((previousSessionId && previousSessionId) === currentSessionId) return false;

    return true;
  }

  /**
   * Renders native app promotional banner or drawer
   *
   * @returns
   */
  renderMobileBanners = () => {
    const { language } = this.commonContext?.props || {};
    const { nativeAppBannerConfig } = this.commonContext?.state || {};
    const { enableNativeAppPromoBanners } = this.commonContext?.state?.flags || {};
    const isIntergratedMLMLaunch = CommonUtils.isIntergratedMLMLaunch();

    if (isIntergratedMLMLaunch || !enableNativeAppPromoBanners) return null;

    return (
      <Suspense fallback={<Fragment />}>
        <NativeAppBanner
          config={nativeAppBannerConfig}
          data={this.getNativeAppBannerData()}
          locale={language.currentLanguage}
          callbacks={{
            onContinue: this.handleNativeAppDrawerContinue,
            onOpen: from => this.handleNativeAppRedirect(from),
            onBannerClose: this.handleNativeAppBannerClose,
            onInit: this.handleNativeAppBannerInit
          }}
        />
      </Suspense>
    );
  }

  /**
   * Constructs and returns appsflyer query parameter
   *
   * @returns
   */
  constructQueryParam = () => {
    const APPSFLYERS_QUERY_PARMS = this.location === AUTH_HOME ? APPSFLYERS_AUTHHOME : APPSFLYERS_EREADER;
    const queryParams = {
      af_xp: APPSFLYERS_QUERY_PARMS.af_xp,
      pid: APPSFLYERS_QUERY_PARMS.pid,
      c: APPSFLYERS_QUERY_PARMS.c,
      deep_link_value: APPSFLYERS_QUERY_PARMS.deep_link_value,
      af_dp: APPSFLYERS_QUERY_PARMS.af_dp,
      af_reengagement_window: APPSFLYERS_QUERY_PARMS.af_reengagement_window,
      is_retargeting: APPSFLYERS_QUERY_PARMS.is_retargeting
    };
    const piRefreshToken = CommonUtils.getPiRefreshToken();

    if (this.location === EREADER) {
      const { pageId } = this.commonContext;
      const { course } = this.commonContext.props;
      const { bookID, id } = this.commonContext?.commonPlugin?.getProduct();

      queryParams.books = encodeURIComponent(bookID || '');
      queryParams.pages = encodeURIComponent(pageId || '');
      queryParams.products = encodeURIComponent(id || '');

      if (course) {
        queryParams.courses = encodeURIComponent(course.id || '');
      }
    }

    if (piRefreshToken) {
      queryParams.auth_key = encodeURIComponent(piRefreshToken || '');
      queryParams.auth_env = encodeURIComponent(env.ENVIRONMENT || '');
    }

    return queryParams;
  }

  /**
   * Handles native app open button redirection
   *
   * @param {*} from
   */
  handleNativeAppRedirect = (from = NATIVE_APP_PROMO_TYPES.DRAWER) => {
    const appsflyersBaseUrl = env.APPSFLYER_BASE_URL;
    this.handleNativeAppBannerClickEvents(NATIVE_APP_BANNER_EVENTS.EVENT_LABEL[from]);

    const constructedQueryParams = new URLSearchParams(this.constructQueryParam());

    if (constructedQueryParams?.size > 0) {
      window.open(`${appsflyersBaseUrl}?${constructedQueryParams.toString()}`);
    }
  }


  /**
   * On click of Native app drawer continue button stores current piSession id
   *
   */
  // eslint-disable-next-line class-methods-use-this
  storeUsersContinueChoice() {
    const currentSessionId = window.piSession.getContextId();
    const previousSessionId = CommonUtils.getSessionStorge(PISESSION_ID);

    if (previousSessionId) {
      CommonUtils.clearSessionStorge(PISESSION_ID);
    }

    if (currentSessionId) {
      CommonUtils.setSessionStorge(PISESSION_ID, currentSessionId);
    }
  }

  /**
   * Handles click of native app drawer continue button
   *
   */
  handleNativeAppDrawerContinue = () => {
    this.handleNativeAppBannerClickEvents(
      NATIVE_APP_BANNER_EVENTS.EVENT_LABEL.BOTTOM_DRAWER_CONTINUE
    );
    // Store sessionid in session storage and evaluate whether to show drawer or not
    this.storeUsersContinueChoice();

    this.commonContext.setState(prevState => ({
      ...prevState,
      nativeAppBannerConfig: {
        ...prevState.nativeAppBannerConfig,
        showDrawer: false,
        showBanner: true
      }
    }));
  }

  /**
   * Handles click of native app drawer close button
   *
   */
  handleNativeAppBannerClose = () => {
    this.handleNativeAppBannerClickEvents(
      NATIVE_APP_BANNER_EVENTS.EVENT_LABEL.BANNER_CLOSE
    );

    this.commonContext.setState(prevState => ({
      ...prevState,
      nativeAppBannerConfig: {
        ...prevState.nativeAppBannerConfig,
        showBanner: false
      }
    }));
  }

  /**
   * Handles Native app banner init to track view events
   *
   */
  handleNativeAppBannerInit = () => {
    setTimeout(() => {
      CommonUtils.appDomElementIntersectionTracker({
        locationInApp: this.location === AUTH_HOME ? LOCATION_IN_APP.AUTHHOME : EREADER_CATEGORY
      });
    }, 100);
  }

  /**
   * Triggers click event for all native app banner actionable elements
   *
   * @param {*} eventLabel
   */
  handleNativeAppBannerClickEvents(eventLabel) {
    const { EVENT_NAME, EVENT_ACTION } = NATIVE_APP_BANNER_EVENTS;

    CommonUtils.dispatchGaEvent(
      AUTHHOME_APPLICATION,
      EVENT_NAME,
      EVENT_ACTION,
      eventLabel,
      {
        location_in_app: this.location === AUTH_HOME ? LOCATION_IN_APP.AUTHHOME : EREADER_CATEGORY,
        is_ghost_account: CommonUtils.isGhostAccount()
      }
    );
  }

  /**
   * Constructs and returns Native app banner data
   *
   * @returns
   */
  getNativeAppBannerData() {
    const {
      CTA_VARIANTS,
      IDS,
      PEARSON_ICON
    } = NATIVE_APP_BANNER_DATA;
    const { language } = this.commonContext.props;

    return {
      drawer: {
        title: language.getMessage('nativeAppBanner.drawer.title'),
        list: [{
          icon: PEARSON_ICON,
          text: language.getMessage('nativeAppBanner.drawer.pearsonApp'),
          cta: {
            id: IDS.open,
            text: language.getMessage('open'),
            variant: CTA_VARIANTS.PRIMARY
          }
        }, {
          renderBrowserIcon: true,
          cta: {
            id: IDS.continue,
            text: language.getMessage('continue'),
            variant: CTA_VARIANTS.SECONDARY
          }
        }]
      },
      banner: {
        icon: PEARSON_ICON,
        title: language.getMessage('nativeAppBanner.banner.title'),
        description: language.getMessage('nativeAppBanner.banner.description'),
        cta: {
          text: language.getMessage('open'),
          variant: CTA_VARIANTS.PRIMARY
        }
      }
    };
  }

    /**
   * Constructs and returns appsflyer query parameter
   *
   * @returns
   */
    constructQRCodeQueryParam = (jwtToken, uId) => {
      const APPSFLYERS_QRCODE_QUERY_PARMS = this.location === AUTH_HOME ? APPSFLYERS_QRCODE_AUTHHOME : APPSFLYERS_QRCODE_EREADER;
      const queryParams = {
        pid: APPSFLYERS_QRCODE_QUERY_PARMS.pid,
        c: APPSFLYERS_QRCODE_QUERY_PARMS.c,
        deep_link_value: APPSFLYERS_QRCODE_QUERY_PARMS.deep_link_value,
        af_reengagement_window: APPSFLYERS_QRCODE_QUERY_PARMS.af_reengagement_window,
        is_retargeting: APPSFLYERS_QRCODE_QUERY_PARMS.is_retargeting
      };

      if (this.location === EREADER) {
        const { pageId } = this.commonContext;
        const { course } = this.commonContext.props;
        const { bookID, id } = this.commonContext?.commonPlugin?.getProduct();

        queryParams.books = encodeURIComponent(bookID || '');
        queryParams.pages = encodeURIComponent(pageId || '');
        queryParams.products = encodeURIComponent(id || '');

        if (course) {
          queryParams.courses = encodeURIComponent(course.id || '');
        }
      }
      queryParams.uuid = encodeURIComponent(uId || '');
      // queryParams.jwt_token = encodeURIComponent(jwtToken || '');
      queryParams.auth_env = encodeURIComponent(env.ENVIRONMENT || '');
      queryParams.is_ghost_account = encodeURIComponent(CommonUtils.isGhostAccount() ? 'y' : 'n');

      return queryParams;
    }
}
