/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import Product from './model/Product';
import ProductContainer from './ProductContainer';
import ProductService from './services/ProductService';
import ProductToken from './model/ProductToken';
import ProductTokenService from './services/ProductTokenService';
import LaunchLog from '../../common/model/LaunchLog';
import LaunchLogService from '../../common/services/LaunchLogService';

export default {
  path: [
    '/products/:id',
    '/products/:id/contents',
    '/products/:id/pages/:pageId',
    '/products/:id/bundles/:bundleId',
    '/products/:id/bundles/:bundleId/pages/:pageId?'
  ],
  exact: true,
  services: [
    new ProductService(),
    new ProductTokenService(),
    new LaunchLogService()
  ],
  stores: [
    {
      name: 'product',
      spec: Product,
      saveLocally: false,
      initialState: {
        authors: [{
          firstName: '',
          lastName: '',
          otherName: ''
        }
        ],
        audioBook: '',
        basePath: '',
        backLinkUrl: '',
        bookID: '',
        catalogUrl: '',
        coverThumbnailUrl: '',
        format: '',
        id: '',
        indexId: '',
        isSumatra: false,
        mathmlOn: true,
        mobileReady: false,
        model: '',
        platform: '',
        status: '',
        thumbnailUrl: '',
        title: '',
        version: '',
        subscription: {
          allowAccess: false,
          businessModelCode: '',
          endDate: 0,
          startDate: 0,
          type: ''
        },
        serversideProcessed: false,
        serversideEnabled: false,
        serverSideUuid: '',
        twoPageLayoutLeft: ''
      }
    },
    {
      name: 'productToken',
      spec: ProductToken,
      saveLocally: true,
      initialState: {
        id: '',
        name: '',
        value: '',
        expiryTime: 0,
        verified: false
      },
      init: (store) => {
        store.setVerified(false);
      }
    },
    {
        name: 'launchLog',
        spec: LaunchLog,
        saveLocally: true,
        initialState: {
          serverUpdatedDateTime: "",
          isImpersonated: false,
          deviceId: ""
        }
    }
  ],
  render: props => (
    <ProductContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
