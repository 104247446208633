/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

/**
 * A service to fetch the product details.
 * @author sandeep
 */
export default class ProductService extends BaseService {
  constructor() {
    super(constants.PRODUCT_SYNC_REQUESTED, 'productStatus');
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    if (event.bundleId) {
      headerParams = {
        headers: {
          'bundle-id': event.bundleId
        }
      };
    }
    return this.getClient('marin').get(`/products/${event.id}`, headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('product').set(response.data);
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    console.log(error, 'error');
    const customFields = { event_value: error.response.data.message };
    CommonUtils.dispatchGaEvent(constants.LOAD_ERROR, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_LABEL, customFields);
  }
}
