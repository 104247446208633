/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateBStyles
 *
 * @file TemplateBStyles.jsx
 * @author Manimaran S
 */
import { makeStyles } from '@material-ui/core';

const TemplateBStyles = (styles = {}) => makeStyles({
  card: {
    background: '#161c29',
    borderRadius: 16
  },
  iconRoot: {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 32,
    height: 32,
    minWidth: 'unset',
    alignSelf: 'center',
    padding: 8,
    borderRadius: 40,
    zIndex: 2,
    border: '1px solid #393F4A',
    color: '#FEFEFE',
    background: 'transparent',
    '& svg': {
      fontSize: 16
    },
    '& .MuiButton-label': {
      minWidth: 'unset',
      display: 'inline-flex'
    },
    '&:hover, &.Mui-focusVisible': {
      border: '2px solid #F7F9FD',
      background: '#F7F9FD',
      color: '#020917'
    },
    '&.Mui-focusVisible': {
      color: '#020917',
      border: '2px dashed #161C29',
      boxShadow: '0px 0px 0px 2px #F7F9FD',
      '&:after': {
        border: 'none'
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    padding: '8px 24px',
    justifyContent: 'center',
    borderRadius: 40,
    border: '2px solid #D2D7E2',
    '& span.MuiButton-label': {
      color: '#FEFEFE',
      fontSize: 18,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.4px'
    },
    '&:hover, &.Mui-focusVisible': {
      border: '2px solid #F7F9FD',
      background: '#F7F9FD',
      '& span.MuiButton-label': {
        color: '#020917',
        fontSize: 18,
        fontFamily: 'TT Commons',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.4px'
      }
    },
    '&.Mui-focusVisible': {
      color: '#020917',
      border: '2px dashed #161C29',
      boxShadow: '0px 0px 0px 2px #F7F9FD',
      '&:after': {
        border: 'none'
      }
    }
  },
  contentCardContanier: {
    display: 'flex',
    flexDirection: 'column'
  },
  bannerImage: {
    width: '100%',
    height: '260px',
    objectFit: 'cover'
  },
  rootCardContent: {
    position: 'relative',
    padding: '0 !important',
    background: 'inherit'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    wordWrap: 'break-word',
    alignSelf: 'center',
    padding: 25,
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'center'
  },
  title: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '32px',
    color: '#fefefe',
    margin: '16px 0'
  },
  description: {
    color: '#989BA3',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 16,
    letterSpacing: 0.24
  },
  ...styles
});
export default TemplateBStyles;
