/* eslint-disable jsx-a11y/anchor-is-valid */
/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2021 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * L2Modal component
 *
 * @file modal.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core';
import { Icon } from '@greenville/mui-theme';
import L2ModalStyles from './styles/L2ModalStyles';

const L2Modal = ({
  classes,
  childComponent,
  callbacks,
  focusInfoBtnOnTitleInfoClose,
  infoheader
}) => {
  const { l2ModalClose } = callbacks;

  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const titleInfoModalRef = useRef(null);
  const closeBtnCTARef = useRef(null);

  return (
    <Dialog
      classes={{ paper: classes.dialogpaper }}
      open
      id="title-info-dialog"
      fullScreen={fullScreen}
      onClose={l2ModalClose}
      role="dialog"
      aria-modal="true"
      PaperProps={{
        tabIndex: 0,
        ref: titleInfoModalRef
      }}
      disableRestoreFocus
      TransitionProps={{
        onEntered: () => {
          if (titleInfoModalRef && titleInfoModalRef.current) {
            titleInfoModalRef.current.focus();
          }
        },
        onExited: () => {
          focusInfoBtnOnTitleInfoClose();
        }
      }}
    >

      <DialogContent
        id="title-info-dialog-description"
        classes={{ root: classes.dialogContentContainer }}
      >
        <Box className={classes.scrollBarContainer}>
          {childComponent}
        </Box>
      </DialogContent>
      <div
        className={classes.dialogTitleInfoContanier}
      >
        <Typography variant="h3" component="h2" className={classes.dialogheader}>
          {infoheader}
        </Typography>
        <DialogActions classes={{ root: classes.dialogaction }}>
          <IconButton
            id="remove-button"
            aria-label="Close"
            onClick={l2ModalClose}
            classes={{ root: classes.closeIcon }}
            ref={closeBtnCTARef}
          >
            <Icon id="remove-icon" name="Remove" color="action" fontSize="large" />
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

L2Modal.defaultProps = {
  focusInfoBtnOnTitleInfoClose: () => { },
  callbacks: {
    l2ModalClose: () => { }
  }
};

L2Modal.defaultProps = {
  focusInfoBtnOnTitleInfoClose: () => { },
  infoheader: '',
  callbacks: {
    l2ModalClose: () => { }
  }
};

L2Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  focusInfoBtnOnTitleInfoClose: PropTypes.func,
  infoheader: PropTypes.string,
  callbacks: PropTypes.shape({
    l2ModalClose: PropTypes.func
  }),
  childComponent: PropTypes.node.isRequired
};

export default withStyles(L2ModalStyles)(L2Modal);
