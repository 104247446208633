/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * React component for Device Management Popup
 *
 * @file AccessGranted.jsx
 * @author Infant Diana Sebastian
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
    useTheme,
    withStyles,
    IconButton,
    Button
} from '@material-ui/core';
import Framework from '@greenville/framework';
import { Icon } from '@greenville/mui-theme';
import CommonUtils from '../utils/CommonUtils';
import * as constants from '../constants';
import env from '../env';

const styles = () => ({
    dialogPaper: {
        background: '#F7F9FD',
        paddingLeft: 20,
        boxShadow: '0px 2px 8px rgb(7 15 31 / 10%), 0px 4px 24px rgb(7 15 31 / 10%)',
        borderRadius: 8
    },
    dialogtitle: {
        fontFamily: 'TT Commons',
        fontSize: 28,
        lineHeight: '110%',
        color:' #05112A',
        fontWeight: 'bold',
        width: '50%',
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: '-22px'
    },
    dialogcontent: {
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        color: '#333333',
        paddingTop: 8,
        paddingBottom: 8
    },
    dialogaction: {
        justifyContent: 'flex-start',
        marginTop: 0,
        paddingLeft: 0,
        marginBottom: 64,
        paddingTop: 0
    },
    passwordChangeButton: {
        border: 'none',
        background: 'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
        '&:hover': {
          background: 'linear-gradient(135deg, #D6492A 0%, #BA015A 63.44%, #940159 100%)'
        }
    },
    passwordChange: {
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '150%',
        color: '#FEFEFE'
    },
    remove: {
        marginTop: 7,
        marginRight: 7
    }
});
const AccessGranted = ({ classes, open, onClose }) => {
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    const language = Framework.getStoreRegistry().getStore('language');
    
    const validateURL=(url)=> {
        const parsed = new URL(url)
        return ['https:', 'http:'].includes(parsed.protocol)
      }
    const handleChangePassword = () => {
        CommonUtils.deviceManagementOTPEvents(constants.LINK_SUCCESS_MODAL, constants.MFA_SUCCESS_MODAL, constants.CLICK_LINK, constants.CHANGE_PASSWORD);
        if(validateURL(env.BOOKSHELF_CONFIG.changePassword))
        {
            window.open(env.BOOKSHELF_CONFIG.changePassword, '_self');
        }
        
    };

    return (
        <Dialog
            open={open}
            id="access-granted-dialog"
            fullScreen={fullScreen}
            onClose={onClose}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="access-granted-dialog-title"
            aria-describedby="access-granted-dialog-description"
            role="dialog"
            aria-modal="true"
            className="dialog-zindex"
        >
            <DialogActions classes={{ root: classes.remove }}>
                <IconButton
                    id="remove-button"
                    onClick={onClose}
                    aria-label={language.getMessage('common.CLOSE')}
                >
                    <Icon id="remove-icon" name="Remove" fontSize="small" />
                </IconButton>
            </DialogActions>
            <DialogTitle
                disableTypography
                id="access-granted-dialog-title"
                classes={{ root: classes.dialogtitle }}
            >
                <FormattedMessage {...language.getText('device.ACCESS_HEADER')} />
            </DialogTitle>
            <DialogContent id="access-granted-dialog-description" classes={{ root: classes.dialogcontent }}>
                <FormattedMessage
                    {...language.getText('device.ACCESS_DESC')}
                />
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogaction }}>
                <Button
                    className={classes.passwordChangeButton}
                    id="change-password"
                    color="default"
                    onClick={handleChangePassword}
                    size="medium"
                    variant="outlined"
                >
                    <Typography className={classes.passwordChange}>
                        <FormattedMessage
                            {...language.getText('device.ACCESS_CHANGE_PASSWORD')}
                        />
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AccessGranted.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(AccessGranted);
