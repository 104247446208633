/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Index file for all environment setting files.
 *
 * @author Hari Gangadharan
 */
import base from './base';
import dev from './dev';
import qaInt from './qaInt';
import stg from './stg';
import perf from './perf';
import prod from './prod';
import saet from './flavors/saet';
import mojoStg from './mojoStg';
import mojoProd from './mojoProd';

let env;
let productFlavor;

/**
 * To get the current local environment setting based on env input
 *
 * @param {*} APP_ENV
 * @returns
 */
function getLocalENV(APP_ENV) {
  let localEnv;
  switch (APP_ENV) {
    case 'dev':
      localEnv = dev;
      break;
    case 'qa':
      localEnv = mojoStg;
      break;
    case 'qaInt':
      localEnv = qaInt;
      break;
    case 'stg':
      localEnv = mojoStg;
      break;
    case 'ppe':
      localEnv = mojoProd;
      break;
    case 'prod':
      localEnv = mojoProd;
      break;
    default:
      localEnv = mojoStg;
      break;
  }

  return localEnv;
}

switch (window.location.hostname) {
  case 'localhost':
  case 'local-dev.pearson.com':
    env = getLocalENV(process?.env?.REACT_APP_ENV) || stg;
    productFlavor = saet;
    break;
  case 'ise-dev.dev-prsn.com':
  case 'ise-dev.pearson.com':
  case 'etext-ise-dev.pearson.com':
  case 'plus-dev.pearson.com':
    env = dev;
    productFlavor = saet;
    break;
  case 'etext-ise-qa-int.pearson.com':
    env = qaInt;
    productFlavor = saet;
    break;
  case 'plus-stg.pearson.com':
  case 'plus-qa.pearson.com':
    env = mojoStg;
    productFlavor = saet;
    break;
  case 'plus-ppe.pearson.com':
    env = perf;
    productFlavor = saet;
    break;
  // Production
  case 'etext-ise-perf.pearson.com':
  case 'etext-ise.pearson.com':
    env = prod;
    productFlavor = saet;
    break;
  case 'plus.pearson.com':
    env = mojoProd;
    productFlavor = saet;
    break;
  default:
    env = stg;
    productFlavor = saet;
}

/**
 * Override the base values with the environment specific values.
 */
export default { ...base, ...productFlavor, ...env };
