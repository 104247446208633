/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Chinese(Hongkong) language
 *
 * @file zh-hk.js
 * @author Manimaran.S
 */

const zhhk = {
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% 將應用程式下載到其他裝置上並自動登入，或使用您唯一的二維碼安全登入您已安裝的應用程式。',
  'moremenu.downloadAppNotDownload': '%Click here% 下載應用程式並使用您唯一的二維碼安全登入',
  'channel.bundleTitle': '存取其他課程的學習和考試準備包',
  'channel.noBundletitle': '使用學習和考試準備為您的其他課程提供幫助',
  'onboard.welcome': '歡迎來到Pearson+',
  'onboard.welcomeCourse': '您來對地方了，在這裡可以存取 <span>{COURSE_NAME}</span>等。',
  'onboard.welcomeCourseList': '您購買的產品包括：',
  'onboard.next': '下一頁',
  'onboard.studyPlan': '已建立自訂學習計劃！',
  'onboard.addCourseAnother': '您是否想新增其他課程？',
  'onboard.purchase': '我需要購買培生電子教材',
  'onboard.addCourseWithoutEtext': '新增沒有電子教材的課程',
  'onboard.addCourseWithoutEtextBundle': '使用學習和考試準備新增課程',
  'onboard.doYouWantOtherCourse': '您是否想添加其他课程？',
  'onboard.mayBeLater': '稍後',
  'onboard.close': 'Close',
  'course.addTitle': '新增課程',
  'bookshelf.TITLE': '我的書架',
  'common.LOGOUT': '登出',
  'common.GO_HOME': '前往首頁',
  'common.PURCHASE': '購買',
  'courses.WELCOME': '{userName} 您好，歡迎來到您的 {courseName} 課程！',
  'courses.action.SHOW_ASSIGNMENTS': '顯示作業',
  'courses.toc.PUBLISH_SUCCESS': '成功！',
  'courses.toc.PUBLISH_MESSAGE': '學生將在下次開啟內容時看到您的變更。',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': '訂閱未找到',
  'errors.SUBSCRIPTION_GENERIC_DESC': '您的帳戶沒有存取該章目的許可權。如果您認為您錯誤接收了這條訊息，請聯絡培生技術支援團隊',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': '訂閱未找到',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': '您的訂閱不包括存取 eText，然而您可以選擇使用信用卡立即訂閱。',
  'foxit.ABOUT': '關於',
  'foxit.COPY_RIGHT': 'PDF 閱讀器由 Foxit 提供支援。Copyright (C) 2003-2019 by Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': '下載移動應用程式',
  'menu.CONTACT_SUPPORT': '聯絡客服團隊',
  'menu.ABOUT': '關於',
  'menu.FOXIT': 'Foxit 提供支援',
  'menu.TERMS_OF_USE': '使用條款',
  'menu.PRIVACY_POLICY': '隱私政策。',
  'menu.SIGN_OUT': '登出',
  'menu.PRINT_PAGE': '列印頁面',
  'menu.GET_LOOSE_LEAF': '獲得活頁',
  'menu.AVAILABLE': '同樣可用',
  'menu.PRINT_VERSION': '想獲得紙本版本嗎？',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. 保留所有權利。',
  'menu.tooltip.SCHEDULE': '計劃安排',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': '{userName} 您好',
  'device.SWAPPED_ALREADY': '您本月已經切換過了',
  'device.SWITCH_DEVICE': '切換到目前裝置？',
  'device.SWITCH_ONCE': '由於您每月只能進行一次切換，因此您無法撤銷此操作。',
  'device.SEAT_TAKEN': '該名額已被佔用',
  'device.DISCONNECT_DEVICE': '您只能在3台裝置上登入。要從此處繼續，請斷開另一台裝置。',
  'device.ALERT_TEXT': '請記住，您每月只能進行一次裝置切換。',
  'device.YOUR_MOJO': '您正在前往Mojo',
  'device.DISCONNECT': '斷開',
  'device.CONTINUE': '繼續',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': '回到首頁',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': '',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': '驗證碼錯誤 - 請檢查驗證碼輸入是否正確，再試一次。',
  'device.MANY_ATTEMPTS': '錯誤次數過多，請重新傳送驗證碼',
  'device.EXPIRED': '驗證碼已過期，請重新傳送驗證碼',
  'device.ACCESS_HEADER': '大功告成！',
  'device.ACCESS_DESC': '您的工作階段已通過驗證。想要在登入狀態下變更密碼？',
  'device.ACCESS_CHANGE_PASSWORD': '變更密碼',
  'device.ACCESS_RESET_PASSWORD': '重設密碼',
  'device.THREATONE_TITLE': '是否一切正常？',
  'device.THREATONE_DESC': '我们注意到您的帐户存在可疑活动。',
  'device.THREATONE_DESC_TWO': '建议您最好修改密码。',
  'device.MAY_BE_LATER': '稍后',
  'device.OKAY': '確定',
  'device.ACCOUNT_LOCK_DESC': '为了安全起见，我们将使您先注销。我们已向您的帐户关联电子邮箱发送了密码重置链接。',
  'device.ACCOUNT_LOCK_TITLE': '我们注意到您的帐户存在可疑活动。',
  'device.ACCOUNT_LOCK_TIMER': '您將被登出 {time}',
  'menu.myAccount': '我的帐户',
  'menu.helpCenter': '帮助中心',
  'menu.preferencesCenter': '偏好设置中心',
  'menu.HELP_SUPPORT': '幫助和支援',
  'menu.FAQS': '常見問題',
  'menu.CHAT_WITH_SUPPORT': '與客服交談',
  'menu.QUICK_WALKTHROUGH': '快速攻略',
  'menu.LEGAL': '法務部',
  'menu.PRIVACY_NOTICE': '隱私通知',
  'menu.TITLE_INFO': '標題資訊',
  'menu.PEARSON_ACCESSIBILITY': '培生可及性',
  'menu.DEVICE_WARNING_MESSAGE': '註冊 {deviceLimit} 台裝置。每月可切換1台裝置。',
  'menu.ACCESSIBILITY': '可及性',
  'menu.ACCESSIBILITY_SUPPORT': '可及性支援',
  'menu.DEVICES': '最近裝置',
  'menu.THIS_DEVICE': '此裝置',
  'menu.MOJO_APP_INFO': '前往您的手机或平板上的应用程序商店以离线使用Pearson+。',
  'menu.DEVICE_LABEL': '设备',
  'navigation.PAGE': '頁碼',
  'launchcode.CREATEBY': '建立於：',
  'launchcode.sentvia': '傳送方式:',
  'launchcode.CODEINLAST': '過去 7 天內的代碼：',
  'launchcode.TIMESENT': '傳送時間 :',
  'launchcode.ACCOUNTEMAIL': '帳戶電子郵箱：',
  'launchcode.LAUNCHDIALOGTITLE': 'OTP 代碼日誌',
  'menu.OTP_CODES': 'OTP 代碼',
  'menu.LAUNCH_CODE': '啟動代碼日誌',
  'menu.SEND_CODE': '透過電子郵件向使用者傳送新代碼',
  'menu.SEND_CODE_PHONE': '透過簡訊向使用者傳送新代碼',
  'menu.COMMUNITY_GUIDELINES': '社群指南',
  'textmessage.TITLE': '輸入電話號碼',
  'textmessage.PHONE_NUMBER': '電話號碼',
  'textmessage.SEND_CODE': '傳送代碼',
  'textmessage.INVALID_NUMBER': '無效的電話號碼。',
  'textmessage.SUCCESS_MESSAGE': '代碼已傳送',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': '搜尋您的清單',
  'remove.title': '誤刪了書目？',
  'remove.subTitle': '您可隨時恢復誤刪書目——重新搜尋後再次新增即可。',
  'remove.buttonText': '刪除',
  'footer.downloadMobileApp': '下載移動應用程式',
  'footer.termsOfUse': '使用條款',
  'footer.privacy': '隱私',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': '請勿出售我的個人資訊',
  'footer.accessibility': '可及性',
  'footer.patentNotice': '專利通知',
  'footer.copyrights': '培生保留所有權利。',
  'channel.videos': '視訊',
  'channel.questions': '問題',
  'channel.descriptionA': '利用「頻道」補充閱讀內容。',
  'channel.descriptionB': '利用「頻道」解決疑難問題。',
  'channel.openchannel': '打開頻道',
  'hero.continueReading': '從上次離開的位置',
  'hero.openBook': '打開書',
  'hero.channel.open': '打開頻道',
  'hero.addATitle': '新增書籍',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': '查看無效書名',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: '尋找您的電子教材'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': '打開電子教材',
  'hero.openEtextBookV1': '打開電子教材',
  'hero.heroHeading': '因為您正在學習 <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep Pack',
  'hero.study.help': '獲得學習說明',
  'hero.study.jumpBackIn': '返回',
  'hero.study.numOfUsers': '目前有<b>{num_of_users}</b>名學生正在使用頻道功能。',
  'hero.heroBook.sectionHeading': '在課程學習中無往不利',
  'hero.heroBook.channelsSectionHeading': '開始新的學習階段',
  'hero.heroBook.sectionHeadingNewUser': '學習幫助在此處',
  'hero.heroBook.sectionSubHeading': '透過專為您的課程量身打造的習題和視訊講解，條分縷析艱深主題。',
  'hero.heroChannel.cardsHeading': '從中斷處繼續',
  'hero.marketingcard.subheading': '觀看專為您的課程量身打造的原理分析視訊講解，完成額外習題，學習更高效率。',
  'hero.marketingcard1.copy.title': '利用視訊學習',
  'hero.marketingcard1.copy.description': '觀看分析講解複雜話題的簡短視訊。',
  'hero.marketingcard2.copy.title': '習題',
  'hero.marketingcard2.copy.description': '利用海量的類比習題和講解視訊解決方案，為考試做好準備',
  'hero.marketingcard3.copy.title': '向經驗豐富的人士獲得幫助',
  'hero.marketingcard3.copy.description': '發佈問題，並從課程專家處獲得解答。',
  'hero.popularTopics.heading': '本頻道的其他熱門話題',
  'hero.popularChannels.heading': '熱門頻道',
  'hero.popularChannels.headingv1': '參加另一門課程？',
  'hero.popularChannels.description': '從這些熱門頻道中獲得學習說明',
  'hero.popularChannels.descriptionv1': '選擇不同頻道，獲得不同課程的學習幫助。',
  'hero.popularChannels.descriptionv2': '瀏覽熱門學習幫助主題。',
  'hero.startReading': '開始閱讀',
  'hero.practice': '練習',
  'hero.noTitleNoChannel.sectionHeading': '在前所未有的短時間內為考試做好準備。',
  'hero.herobook.mappedChannelHeading': '本頻道推薦的學習幫助',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': '分析講解複雜話題的簡短視訊',
  'hero.promotionalcard.list2': '海量的模擬習題',
  'hero.promotionalcard.list3': '從課程專家處獲得解答',
  'hero.studyandexamprep': '學習和考試準備',
  'hero.heroBook.sectionSubHeadingV1': '透過專為您的課程量身打造的習題和視訊講解，條分縷析艱深主題。',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': '瞭解更多內容',
  'hero.subscriptionStatusTitle': 'Pearson+訂閱狀態',
  'hero.eTextbook': '電子教材',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': '轉到頁面',
  'subscription.eTextbook': '電子教材',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': 'Study & Exam Prep Pack',
  'subscription.studyNoBundle': '學習和考試準備',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': '繼續學習',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': '尋找您的電子教材',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': '從控制台中刪除課程？',
  'hero.removecourse.description': '您隨時可以透過按一下「Add course（新增課程）」重新將這個課程新增到控制台中',
  'hero.removecourse.primaryCtaText': '刪除',
  'hero.removecourse.secondaryCtaText': '取消',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': '搜尋電子教材',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': '免費學習一種新語言',
  'hero.mondly.learnLang.title2': '免費學習一種新語言!',
  'hero.mondly.subTitle': '培生電子教材使用者可免費獲得三個月的Mondly by Pearson。',
  'hero.mondly.description': '大多数语言学习应用程序只允许您通过英语学习其他语种。但最佳方式是通过您的母语学习其他语种，因此，在Mondly中，您可以通过我们 41 种语言中的任何一种学习其他语种。',
  'hero.mondly.startLearningCTA': '開始學習',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': '培生的更多內容',
  'hero.extractingExamDetails': '正在提取考試詳細資訊',
  'hero.finishingTheProcess': '即將完成流程',
  'hero.hereWhatWeGotFromYourSyllabus': '這是我們從您的教學大綱中獲得的內容',
  'hero.school': '學校：',
  'hero.class': '班級：',
  'hero.textbook': '課本：',
  'hero.examSchedule': '考試時間表：',
  'hero.confirm': '確認',
  'onboard.aboutCourse': '告訴我們您的課程',
  'onboard.studyMaterials': '選擇您的課程，我們將建立一個包含自訂學習材料的控制台。',
  'hero.personalizedStudyPlan': '我們將利用人工智慧為您提供個性化的學習計劃。',
  'hero.welcomeToP': '歡迎來到P+！',
  'hero.learnYourWayAround': '現在花點時間來瞭解一下這款應用程式，或者下次在帳戶設定中瞭解。',
  'hero.weveCreatedADashboard': '我們在這裡為您的課程建立了一個控制台。',
  'hero.youCanAddYourPearsonCourse': '您還可以新增自己的非培生課程並免費試用學習材料。',
  'libraryL2.back': '返回',
  'hero.getExamreadyWithVideoLessons': '透過根據您的課程自訂的視訊課程和習題集，為考試做好準備。',
  'hero.youreTexbook': '您的電子教材及其所有工具都在這裡。',
  'hero.VideosAndPractice': '學習和考試準備中始終提供更多視訊和練習題。',
  'hero.pleaseSelectTheChapters': '請選擇本次考試涵蓋的章節：',
  'hero.tableOfContents': '目錄',
  'hero.search': '搜尋',
  'hero.notes': '筆記及高亮內容',
  'hero.doYouWantToAddACourse': '您想新增課程嗎？',
  'hero.whatsAvailableForYou': '您可以瀏覽的內容',
  'hero.whatsAvailableForYouInPearson': '您可以在Pearson+中瀏覽的內容',
  'hero.startStudying': '開始學習',
  'hero.unlockYourStudyExamPrep': '馬上解鎖其他課程的學習和考試準備包！',
  'header.menu.learnLanguage': '學習一種語言',
  'hero.subscriptionStatusTitle2': '您的Pearson+資源',
  'hero.getExamreadyForYou': '使用學習和考試準備為您的其他課程做好考試準備。',
  'hero.weVideoLessons': '我們有根據您的需求自訂的視訊課程和習題集。',
  'hero.tryForFree': '免費試用',
  'hero.addCourseUsingStudyExamPrep': '使用學習和考試準備新增課程',
  'hero.for': '對於',
  'hero.studyExamPrepPackForAllCourse': '適用於您所有課程的學習和考試準備包',
  'hero.myETextbooks': '我的電子教材',
  'header.continueInTheApp': '在應用程式中繼續',
  'hero.goToMyLabMastering': '轉到MyLab/Mastering',
  'hero.learnTxt': '學習',
  'hero.aITutorTxt': '人工智慧導師',
  'hero.exploreTxt': '探索',
  'hero.studyExamPrepAllYourCourses': '適用於您所有課程的學習和考試準備',
  'hero.examSchedules': '考試時間表',
  'hero.examSchedules2': '考試時間表',
  'hero.uploadYourSyllabus': '上傳您的教學大綱',
  'hero.addAnExam': '新增考試',
  'hero.UploadYourSyllabus.studyExamPrepPack': '上傳您的教學大綱，以充分利用您的學習和考試準備包',
  'hero.UploadYourSyllabus.studyExamPrep': '上傳您的教學大綱，以充分利用您的「學習和考試準備',
  'hero.getRecommendationsOnWhat': '獲得有關學習內容和時間的建議',
  'hero.noSyllabusSharingExamSchedule': '沒有教學大綱？也可以分享您的考試時間表',
  'hero.selectDropSyllabusFile': '選擇或刪除PDF或DOCX格式的教學大綱檔案',
  'hero.uploadTxt': '上傳',
  'hero.examName': '考試名稱',
  'hero.completeTxt': '完成',
  'hero.exactTopicsChapter': '稍後您將能夠指定每章的具體主題',
  'hero.uploadingDocument': '正在上傳文件',
  'hero.activatingAIComponents': '正在啟動人工智慧組件',
  'hero.extractingClassSchoolData': '正在提取班級和學校資料',
  'hero.extractingTextbookChapters': '正在提取課本章節',
  'hero.insideStudyExamPrepPack': '內部學習和考試準備包',
  'hero.userSubsGuidence': '您可以不受限制地存取根據您的考試時間表量身訂作的視訊課程、學習計劃和習題集。另外，還可以提出問題並獲得領域內專家的回饋。',
  'hero.examPrepTxt': '考試準備',
  'hero.extractingDataFromSyllabus': '正在从教学大纲中提取数据……',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'hero.insideStudyExamPrep': '內部學習和考試準備',
  'hero.testVideoLessons': '測試根目錄據您的考試時間表量身訂作的視訊課程、學習計劃和習題集。',
  'header.qrCode.subHeaderTxt': '使用此二維碼快速安全地登入移動裝置上的Pearson+應用程式。',
  'header.qrCode.dontHaveApp': '沒有該應用程式？',
  'header.qrCode.footerTxt': '掃描此二維碼會將您重新導向到App Store或Google Play。下載該應用程式後，再次掃描登入。',
  'header.qrCode.errorMsg': '二維碼無法載入。關閉並重試。',
  'hero.yourETextbook': '您的電子教材',
  'onboard.addCourseWithEtextBundle': '使用學習和考試準備包新增課程 ',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep Pack',
  'subscriptionstatus.nonbundlecta': '存取學習和考試準備',
  'channel.genericBundle': '使用您的學習和考試準備包為您的其他課程提供幫助',
  'channel.relatedBundle': '還可以使用您的學習和考試準備包為您的其他課程提供幫助',
  'onboard.courseAdded': '已新增課程！',
  'hero.flashcards': '記憶卡',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.'
};

export default zhhk;
