/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { makeStyles } from '@material-ui/core';

/**
 * templateFStyles
 *
 * @file templateFStyles.jsx
 * @author Manimaran S
 */

const defaultStyles = {
  cardWrapper: {
    position: 'relative',
    borderRadius: 16,
    overflow: 'hidden',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundImage: '-webkit-image-set(url("https://cdn.braze.eu/appboy/communication/assets/image_assets/images/6613a5eb12c596006ba2705a/original.jpg?1712563691") 1x, url("https://cdn.braze.eu/appboy/communication/assets/image_assets/images/6613a5fdaca3ba2bee51d087/original.jpg?1712563709") 2x,url("https://cdn.braze.eu/appboy/communication/assets/image_assets/images/6613a5f714414a2609561f22/original.jpg?1712563703") 3x)',
    backgroundColor: '#13203b',
    backgroundSize: 'cover',
    height: 'auto',

    '@media (min-width: 768px)': {
      height: 402
    }
  },
  iconRoot: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#000',
    background: '#d9d9d9A6',

    '&:hover': {
      color: '#fff',
      background: '#000'
    },
    '& svg': {
      fontSize: 12
    }
  },
  rootCardContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px !important',
    alignItems: 'center',
    flexDirection: 'column-reverse',

    '@media (min-width: 768px)': {
      flexDirection: 'row'
    }
  },
  title: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '40px',
    color: '#FEFEFE',
    letterSpacing: '0.4px',
    margin: 0
  },
  subTitle: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '28px',
    color: '#FEFEFE',
    margin: 0,
    letterSpacing: 0.4
  },
  description: {
    color: '#FEFEFE',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0.24,
    fontFamily: 'TT Commons',
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  contentArea: {
    wordWrap: 'break-word',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    justifyContent: 'center',
    width: 'auto',
    flexShrink: 0,
    padding: 24,

    '@media (min-width: 768px)': {
      padding: '24px 0px 24px 48px !important',
      width: 371
    },
    '@media (min-width: 834px)': {
      width: '50%'
    },
    '@media (min-width: 1024px)': {
      gap: 32
    }
  },
  copyTextWrapper: {
    wordWrap: 'break-word',
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  actionBtnWrapper: {
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap',

    '@media (min-width: 768px)': {
      flexWrap: 'nowrap'
    },
    '@media (min-width: 1280px)': {
      gap: 24
    }
  },
  imageWrapper: {
    height: 302,
    maxWidth: 479,
    display: 'flex',
    alignItems: 'center',

    '@media (min-width: 1024px)': {
      height: '100%'
    }
  },
  contentImage: {
    width: '100%',
    height: 'inherit'
  },
  buttonContainer: {
    display: 'flex',
    padding: '8px 24px',
    justifyContent: 'center',
    borderRadius: 40,
    color: '#FEFEFE',
    width: 'fit-content',
    border: '2px solid #D2D7E2',

    '& span.MuiButton-label': {
      color: 'inherit',
      fontSize: 18,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.4px'
    },
    '&:hover, &.Mui-focusVisible': {
      background: '#F7F9FD',
      color: '#020917'
    },
    '&.Mui-focusVisible': {
      background: '#F7F9FD',
      border: '2px dashed #161C29',
      boxShadow: '0px 0px 0px 2px #F7F9FD',
      color: '#020917',
      '&:after': {
        border: 'none'
      }
    }
  },
  primaryCtaBtn: {
    boxSizing: 'border-box',
    background: 'linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%)',
    borderRadius: 40,
    display: 'flex',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      background: 'linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%)'
    },
    '&.Mui-focusVisible': {
      background: 'linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%)',
      border: '2px dashed #DDE3EE',
      boxShadow: '0px 0px 0px 2px #B41863',
      '&::after': {
        border: 'none'
      }
    },
    '& span': {
      color: '#FEFEFE',
      fontSize: 18,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: 0.4,
      whiteSpace: 'nowrap',
      minWidth: 'unset'
    }
  },
  secondaryBtn: {
    width: 'fit-content',
    padding: '8px 14px',
    borderRadius: '40px',
    border: '2px solid #D2D7E2',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    color: '#FEFEFE',

    '&:hover': {
      background: '#F7F9FD',
      color: '#020917'
    },
    '&:focus-visible': {
      background: '#F7F9FD',
      color: '#020917',
      border: '2px dashed #161C29',
      boxShadow: '0px 0px 0px 2px #F7F9FD',
      '&::after': {
        border: 'none'
      }
    },
    '& span': {
      fontSize: 18,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: 0.4,
      whiteSpace: 'nowrap',
      minWidth: 'unset'
    },
    '@media (min-width: 1280px)': {
      padding: '8px 24px'
    }
  },
  secondaryFilledCtaBtn: {
    width: 'fit-content',
    padding: '8px 14px',
    borderRadius: '40px',
    border: '2px solid #D2D7E2',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    background: '#D2D7E2',
    color: '#020917',

    '&:hover': {
      background: '#F7F9FD',
      color: '#020917'
    },
    '&:focus-visible': {
      background: '#F7F9FD',
      color: '#020917',
      border: '2px dashed #161C29',
      boxShadow: '0px 0px 0px 2px #F7F9FD',
      '&::after': {
        border: 'none'
      }
    },
    '& span': {
      fontSize: 18,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: 0.4,
      whiteSpace: 'nowrap',
      minWidth: 'unset'
    },
    '@media (min-width: 1280px)': {
      padding: '8px 24px'
    }
  },
  divider: {
    minWidth: 'unset',
    color: '#FEFEFE',

    '& span': {
      fontSize: 20,
      fontFamily: 'TT Commons',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.4,
      whiteSpace: 'nowrap',
      minWidth: 'unset'
    }
  }
};

/**
 * Function to override the default styles with braze styles
 * @param {*} brazeStyles
 * @returns
 */
const templateFStyles = (brazeStyles = {}) => {
  Object.keys(defaultStyles).forEach((key) => {
    if (brazeStyles[key]) {
      defaultStyles[key] = { ...defaultStyles[key], ...brazeStyles[key] };
    }
  });

  return makeStyles(defaultStyles);
};

export default templateFStyles;
