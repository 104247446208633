/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import * as braze from '@braze/web-sdk';
import Framework from '@greenville/framework';
import {
  BRAZE_CONTENT_CARD_FETCHED,
  BRAZE_IN_APP_MESSAGE_CARD_FETCHED,
  BRAZE_IDENTIFY_USER,
  BRAZE_TRIGGER_EVENT
} from '../constants';
import env from '../env';

/**
 * Braze utilities
 *
 * @file BrazeUtils.js
 * @author Manimaran S
 */

export default class BrazeUtils {
  static isIntialized = false;

  static contentCards = [];

  static inAppMessageCards = null;

  /**
   * Function to handle Braze events
   *
   * @param {*} event
   * @param {*} userId
   * @param {*} callBack
   * @param {*} enableInAppMessage
   */
  static handleEvent(event, userId = null, callBack, customEvent = {}, enableInAppMessage = false) {
    if (this.isIntialized) {
      switch (event) {
        case BRAZE_IDENTIFY_USER:
          braze.changeUser(userId);
          if (enableInAppMessage) {
            braze.subscribeToInAppMessage((inAppMessage) => {
              if (inAppMessage) {
                BrazeUtils.inAppMessageCards = inAppMessage;
                Framework.getEventManager().publish(BRAZE_IN_APP_MESSAGE_CARD_FETCHED);
              }
            });
          }
          braze.subscribeToContentCardsUpdates((updates) => {
            if (updates && updates.cards && updates.cards.length > 0) {
              const { cards } = updates;
              BrazeUtils.contentCards = cards;
              Framework.getEventManager().publish(BRAZE_CONTENT_CARD_FETCHED);
            }
            callBack(updates);
          });
          braze.openSession();
          braze.requestContentCardsRefresh();
          break;
        case BRAZE_TRIGGER_EVENT: {
          const { name, properties = {} } = customEvent;
          if (name) {
            braze.logCustomEvent(name, properties);
          }
          break;
        }
        default:
      }
    } else {
      this.isIntialized = braze.initialize(env.BRAZE_API_KEY, {
        baseUrl: env.BRAZE_BASE_URL,
        allowUserSuppliedJavascript: true,
        noCookies: true
      });
      this.handleEvent(event, userId, callBack, customEvent, enableInAppMessage);
    }
  }

  /**
   * Function to initialize  callback listener from braze
   *
   */
  static initializeBrazeCallBackListener(callBack) {
    window.handleBrazeButtonClick = (action, actionValues = '') => {
      if (!action) {
        return;
      }

      callBack(action, actionValues);
    };
  }

  /**
   * Returns true if it is a valid JSON
   *
   * @param {*} str
   * @returns
   */
  static isJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }
}
