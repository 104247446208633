/* eslint-disable */
/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/*
 * A React component for Back Icon
 *
 * @author Ritabrata Basudhar
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const BackIcon = ({
  type
}) => (
  <SvgIcon style={{ fontSize: '12px', height: '100%', marginRight: '6px' }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    {type !== 'mojo' ?
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.23191 5.5H11C11.5523 5.5 12 5.94772 12 6.5C12 7.05228 11.5523 7.5 11 7.5H3.23191L6.63891 10.9159C7.00036 11.2783 7.00036 11.8658 6.63891 12.2282C6.27746 12.5906 5.69142 12.5906 5.32997 12.2282L0.27109 7.15617C-0.0903635 6.79378 -0.0903635 6.20622 0.27109 5.84383L5.32997 0.771795C5.69142 0.409402 6.27746 0.409402 6.63891 0.771795C7.00036 1.13419 7.00036 1.72175 6.63891 2.08414L3.23191 5.5Z" fill="black" /> :
      <path fillRule="evenodd" clipRule="evenodd" d="M3.23191 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H3.23191L6.63891 10.4159C7.00036 10.7783 7.00036 11.3658 6.63891 11.7282C6.27746 12.0906 5.69142 12.0906 5.32997 11.7282L0.27109 6.65617C-0.0903635 6.29378 -0.0903635 5.70622 0.27109 5.34383L5.32997 0.271795C5.69142 -0.0905985 6.27746 -0.0905985 6.63891 0.271795C7.00036 0.634189 7.00036 1.22175 6.63891 1.58414L3.23191 5Z" fill="#F7F9FD" />
    }
  </SvgIcon>
);

BackIcon.propTypes = {
  type: PropTypes.string.isRequired
};

export default BackIcon;

