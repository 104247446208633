/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 **/
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * A React component for  Collapse icon.
 *
 * 
 */

const CollapseIcon = () => (
  <SvgIcon width="8" height="5" viewBox="0 0 8 5" fill="none" fontSize="inherit">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.250401 1.49561C-0.083467 1.15347 -0.083467 0.598746 0.250401 0.256606C0.584269 -0.0855352 1.12558 -0.0855352 1.45944 0.256606L4 2.88489L6.54056 0.256606C6.87442 -0.0855352 7.41573 -0.0855352 7.7496 0.256606C8.08347 0.598746 8.08347 1.15347 7.7496 1.49561L4.60452 4.74339C4.27065 5.08554 3.72935 5.08554 3.39548 4.74339L0.250401 1.49561Z" fill="url(#paint0_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="0" y1="0" x2="4.49438" y2="7.19101" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01CBB7" />
        <stop offset="0.604167" stopColor="#007899" />
        <stop offset="1" stopColor="#005F79" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default CollapseIcon;
