/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Service file for glossary hotspot
 *
 * @file GlossaryHotspotService.js
 * @author Vignesh Selvam
 */

import Framework, { BaseService } from '@greenville/framework';

import { CSG_APP_ID_PDF, GLOSSARY_HOTSPOT_REQUESTED } from '../../../common/constants';

export default class GlossaryHotspotService extends BaseService {
  constructor() {
    super(GLOSSARY_HOTSPOT_REQUESTED, 'GlossaryHotspotService');
  }

  handleEvent(eventName, event) {
    const headerParams = {
      headers: {
        'application-id': CSG_APP_ID_PDF
      }
    };

    return this.getClient('csg').post('/search', event, headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('glossaryHotspot').setGlossaryHotspot(response.data);
  }
}
