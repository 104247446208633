/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to product configuration
 *
 * @file Configuration.js
 * @author Saravanan Kandasamy
 */

import { types } from 'mobx-state-tree';

/**
 * A mobx model for tool bar features
 *
 */
const ToolBarFeatures = types.model(
  'ToolBarFeatures',
  {
    hasBookMarkPageButton: types.maybeNull(types.boolean)
  }
);

/**
 * A mobx model for general features
 *
 */
const GeneralFeatures = types.model(
  'GeneralFeatures',
  {
    hasLeftAccordion: types.maybeNull(types.boolean)
  }
);

/**
 * A mobx model for book features
 *
 */
const BookFeatures = types.model(
  'BookFeatures',
  {
    hotSpotColor: types.maybeNull(types.string, false),
    isUnderLineHotspot: types.maybeNull(types.boolean, false),
    regionHotSpotAlpha: types.maybeNull(types.string, false),
    underLineHotSpotColor: types.maybeNull(types.string, false),
    underLineHotSpotThickness: types.maybeNull(types.string, false),
    underLineHotSpotHoverColor: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for languages
 *
 */
const BookLanguages = types.model(
  'BookLanguages',
  {
    languageDirection: types.maybeNull(types.string),
    languageID: types.maybeNull(types.integer),
    languageNameKey: types.maybeNull(types.string, 'en_us')
  }
);

/**
 * A mobx model for header features
 *
 */
const HeaderFeatures = types.model(
  'HeaderFeatures',
  {
    hasPrintLink: types.maybeNull(types.boolean)
  }
);

/**
 * A mobx model for configuration
 *
 */
const Configuration = types.model(
  'Configuration',
  {
    enableUserPrinting: types.optional(types.boolean, false),
    studyTools: types.optional(types.boolean, false),
    printOfferUrl: types.maybeNull(types.string),
    includeMathMLLib: types.optional(types.boolean, false),
    toolBarFeatures: types.maybeNull(types.compose(ToolBarFeatures)),
    generalFeatures: types.maybeNull(types.compose(GeneralFeatures)),
    ipadFeatures: types.maybeNull(types.compose(BookFeatures)),
    availableBookLanguages: types.maybeNull(types.array(BookLanguages)),
    headerFeatures: types.maybeNull(types.compose(HeaderFeatures))
  }
);
export default Configuration;
