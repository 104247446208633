/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to Channel Mapping For books
 *
 * @file ChannelMaping.js
 * @author MANIMARAN S
 */

import { types } from 'mobx-state-tree';

/**
 * A mobx model for Channel tutor
 */
const ChannelTutor = types.model(
  'ChannelTutor',
  {
    image: types.maybeNull(types.string),
    name: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for channel actions
 */
const ChannelActions = types.model(
  'ChannelActions',
  {
    number: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    type: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for channel details
 */
export const ChannelDetails = types.model(
  'ChannelDetails',
  {
    actions: types.maybeNull(types.array(ChannelActions)),
    courseId: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    thumbnail: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    tutor: types.maybeNull(ChannelTutor),
    url: types.maybeNull(types.string),
    group: types.maybeNull(types.string),
    numberOfUsers: types.maybeNull(types.number),
    learnUrl: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for channel location details
 */
const ChannelLocationDetails = types.model(
  'ChannelLocationDetails',
  {
    activity: types.maybeNull(types.string),
    displayTitle: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    updatedTime: types.maybeNull(types.Date),
    url: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for channel location
 */
export const ChannelLocation = types.model(
  'channelLocation',
  {
    channel: types.maybeNull(ChannelDetails),
    location: types.maybeNull(ChannelLocationDetails)
  }
);
