/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Perf environment overrides.
 *
 * @author Vignesh Selvam
 */

const SSI_CLIENT_ID = 'ejQ30eVDKpw6inZeCXwIaBAcLikqQT2a';
const LOCATION_HOST = typeof window !== 'undefined' ? `https://${window.location.host}` : null;
const perf = {
  ENVIRONMENT: 'prod',
  PARTNER_ENV: 'prod',
  GTM_AUTH: 'fBAn5khLdTsxZKxT5nnz0A',
  GTM_PREVIEW: 'env-6',
  CSG_API_BASE_URL: 'https://content-service.prd-prsn.com/csg/api/v3',
  PAPER_API_BASE_URL: 'https://paperapi.openclass.com/nextext-api/api/platforms/bookshelf',
  PRISM_API_BASE_URL: 'https://prism.pearsoned.com/api',
  SPECTRUM_API_BASE_URL: 'https://spectrum.pearsoned.com/api',
  SPECTRUM_API_V4_BASE_URL: 'https://spectrum.pearsoned.com/api/v4',
  STPAPER_API_BASE_URL: 'https://stpaperapi.prd-prsn.com/etext/v2',
  MARIN_API_BASE_URL: 'https://plus.pearson.com/marin/api/1.0',
  MARIN_API_BASE_URL_V2: 'https://plus.pearson.com/marin/api/2.0',
  ETEXT_API_BASE_URL: 'https://etext.pearson.com/api/nextext-api/v1/api',
  IDC_API_BASE_URL: 'https://etext-instructor.pearson.com/idc-api/',
  PSEUDO_LOCALE_CODE: null,
  CLIENT_ID: '6oVj1comRlGtGamiOamwDArIbVgGcKrA',
  SSI_CLIENT_ID,
  ZEPPELIN_URL: 'https://product-transaction.openclass.com/products',
  CONSOLE_URL: 'https://console.pearson.com',
  TENANT_ID_ETEXT: 'beeea450-02f6-4f88-842e-cec9364453b7',
  TENANT_KEY_ETEXT: 'fbc0226b-3587-4760-9503-17ded7e2e3d8',
  TENANT_KEY_ETEXT_PDF: 'bb0a1d97-78af-44e4-bfe4-810c535a45c0',
  SPECTRUM_X_TENANTKEY: '21d77376-46b2-4e26-8781-2d1a59468f8f',
  NEWRELIC_CONFIG_NAME: '/newrelic/newrelic.js',
  NEWRELIC_ENABLED: false,
  NEWRELIC_CONFIG: {
    accountId: 1322480,
    agentId: 274529857,
    applicationId: 91909111,
    licenseKey: 'd527718953',
    trustKey: 691807
  },
  SMARTTHINKING_CONFIG: {
    baseUrl: 'https://services.smarthinking.com/api/payment-mojo',
    secret: 'UK0DQCH1dUiZjwRH1r5GLpo7VPao2h9L'
  },
  ALGOLIA_SEARCH_CONFIG: {
    appId: 'XFOI9EBBHR',
    apiKey: 'f52b6c3d41be7aad7b87f76a0259213d',
    productIndex: 'live-mojo-product-index',
    categoryIndex: 'live-mojo-category-index',
    accountUrl: 'https://www.pearson.com/store/en-us/pearsonplus/account',
    changePassword: 'https://www.pearson.com/store/en-us/pearsonplus/account/change-password',
    resetPassword: `https://login.pearson.com/v1/piapi-int/iesui/forgot-unpw?client_id=${SSI_CLIENT_ID}&login_success_url=${LOCATION_HOST}/home`,
    helpCenter: 'https://www.pearson.com/en-us/pearsonplus/support.html',
    searchQuery: 'https://www.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=webapp&utm_campaign=search&utm_content=searchbar&aq=',
    preferencesCenter: 'https://www.pearson.com/store/en-us/pearsonplus/account/preferences'
  },
  ADD_A_TITLE_URL: {
    HERO: 'https://www.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=webapp&utm_campaign=add_title_hero',
    LIBRARY: 'https://www.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=webapp&utm_campaign=add_title_library&utm_content=addatitle&aq='
  },
  LAUNCH_DARKLY_BASE_URL: 'https://app.launchdarkly.com/api/v2',
  LD_ENVIRONMENT: 'production',
  LD_API_ACCESS_KEY: 'api-6a1d0842-a151-484e-8fd4-9fc2fb1d253d',
  LD_CLIENT_SIDE_ID: '5eda7256a9d7760a721a39b5',
  LD_ANONYMOUS_USER_ID: 'ise_anonymous_prod_user',
  LD_ANONYMOUS_HASH: 'e04941583fd8b89f9566cba6d4e3717611218d323c06986981048d560de8fa3f',
  BOOKSHELF_CONFIG: {
    checkout: 'https://www.pearson.com/en-us/pearsonplus/pricing.html?utm_source=authhome&utm_medium=display&utm_campaign=XXLEGP0721PLUS&utm_content=',
    addAnother: 'https://www.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=display&utm_campaign=XXLEGP0721PLUS&utm_content=',
    pmcHeader: 'https://www.pearson.com/en-us/pearsonplus.nav-content.standalone.html',
    pmcSearch: 'https://www.pearson.com',
    purchase: 'https://www.pearson.com/en-us/pearsonplus/search.html',
    logo: 'https://www.pearson.com/en-us/pearsonplus/pricing.html?utm_source=authhome&utm_medium=webapp&utm_campaign=logo&utm_content=logo',
    updatePayment: 'https://www.pearson.com/store/en-us/pearsonplus/account/payment-methods',
    faq: 'https://www.pearson.com/en-us/pearsonplus/support/etext-app.html',
    changePassword: 'https://www.pearson.com/store/en-us/pearsonplus/account/change-password',
    updateProfile: 'https://www.pearson.com/store/en-us/pearsonplus/my-account/update-profile',
    chatbot: {
      url: 'https://pearson.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseUrl: 'https://pearson.my.salesforce.com',
      supportUrl: 'https://support.pearson.com/getsupport',
      id: '00Db0000000e0e2',
      name: 'NAUS_HETS_DTC_CS_Bot',
      baseLiveAgentContentURL: 'https://c.la3-c1-fra.salesforceliveagent.com/content',
      deploymentId: '572b0000000ClKC',
      buttonId: '5735p000000sXvm',
      baseLiveAgentURL: 'https://d.la3-c1-fra.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5p000000sXuZEAU_17a41f20db5',
      isOfflineSupportEnabled: false
    },
    more: {
      MOJO_TERMS: 'https://www.pearson.com/en-us/pearsonplus/legal/terms-of-use.html',
      MOJO_PRIVACY: 'https://www.pearson.com/en-us/pearsonplus/legal/user-privacy-policy.html',
      MOJO_COOKIES: 'https://www.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_INFO: 'https://www.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_ACCESSIBILITY: 'https://www.pearson.com/en-us/pearsonplus/legal/accessibility.html',
      MOJO_NOTICE: 'https://www.pearson.com/en-us/pearsonplus/legal/patent-notice.html',
      MOJO_PEARSON_ACCESSIBILITY: 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
      MOJO_SUPPORT: 'https://www.pearson.com/en-us/pearsonplus/support.html',
      MOJO_APP: 'https://www.pearson.com/en-us/pearsonplus/mobile-app.html',
      COMMUNITY_GUIDELINES: 'https://www.pearson.com/en-us/pearsonplus/community-guidelines.html'
    }
  },
  PRODUCT_TOKEN: '80153',
  PLUS_URL: 'https://plus.pearson.com',
  ETEXT_URL: 'https://etext-ise.pearson.com',
  IES_BASE_URL: 'https://login.pearson.com/v1/piapi',
  ELIGIBILITY_API_BASE_URL: 'https://api-pgi.pearson.com/gps-subs/v1/',
  HELP_CENTER_NON_MOJO: 'https://support.pearson.com/getsupport/s/',
  CHANNELS_MAPPING_URL: 'https://api.studychannel.pearsonprd.tech/v1',
  CHANNELS_MAPPING_URL_TIMEOUT: 10000,
  STUDY_CHANNEL_URL: 'https://www.pearson.com/channels',
  BRAZE_API_KEY: '980e3d2f-a5f3-4724-921e-b21b54aad6fa',
  BRAZE_BASE_URL: 'sdk.fra-02.braze.eu',
  BERLIN: {
    STREAM_BASE_URL: 'https://plus.pearson.com/ber-api/api/1.0',
    STREAM_BASE_URL_V2: 'https://plus.pearson.com/ber-api/api/2.0',
    TENANT_ID: '29bb65c6-12f8-4cca-9734-fb91899d1c4c',
    TENANT_KEY: '0b64f2fe-1e38-4922-b87a-537b2a36be26'
  }
};

export default perf;
