/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * React component for Generic Error Display
 *
 * @file ErrorCard.jsx
 * @author Infant Diana Sebastian
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Typography, Box, withStyles, Link
} from '@material-ui/core';
import Framework from '@greenville/framework';
import { EventProvider } from '@aquila/core';
import ErrorMessageIcon from '../icons/ErrorMessageIcon';
import { ERROR_SUPPORT, ERROR_SUPPORT_CLICK } from '../constants';

const styles = () => ({
  wrapper: {
    marginTop: 120
  },
  boxWrapper: {
    width: '34.7%',
    paddingLeft: '9%',
    paddingRight: '9%',
    height: 416,
    boxShadow: '0px 7px 8px rgba(0, 0, 0, 0.05), 0px 5px 22px rgba(0, 0, 0, 0.03)',
    borderRadius: 8,
    textAlign: 'center',
    margin: 'auto'
  },
  errorTitle: {
    marginBottom: 16,
    fontWeight: 600
  },
  errorImage: {
    paddingTop: 50,
    width: '100%',
    '@media (max-width: 808px)': {
      height: 'auto',
      paddingTop: 20
    }
  },
  errorDesc: {
    marginBottom: 16
  },
  errorCode: {
    fontWeight: 600
  }
});

const ErrorCard = ({
  classes, code, courseId, productId
}) => {
  const language = Framework.getStoreRegistry().getStore('language');

  const handleSupport = () => {
    const userId = Framework.getStoreRegistry().getStore('user').id;

    EventProvider.getEventManager().dispatch({
      event: ERROR_SUPPORT,
      category: ERROR_SUPPORT,
      action: ERROR_SUPPORT_CLICK,
      data: {
        courseId,
        productId,
        userId
      }
    });
  };

  return (
    <div className={classes.wrapper}>
      <Box className={classes.boxWrapper}>
        <ErrorMessageIcon className={classes.errorImage} />
        <Typography variant="h2" className={classes.errorTitle}>
          <FormattedMessage {...language.getText('errors.GENERIC_TITLE')} />
        </Typography>
        <Typography variant="h5" className={classes.errorDesc}>
          <FormattedMessage {...language.getText('errors.GENERIC_DESC')} />
          <Link
            href={language.getMessage('link.SUPPORT')}
            color="inherit"
            target="_blank"
            rel="noopener"
            underline="always"
            onClick={handleSupport}
          >
            <Typography variant="h5" component="span">
              <FormattedMessage {...language.getText('errors.GENERIC_LINK')} />
            </Typography>
          </Link>
        </Typography>
        <Typography variant="subtitle1" className={classes.errorCode}>
          <FormattedMessage
            {...language.getText('errors.GENERIC_CODE')}
            values={
              {
                code
              }
            }
          />
        </Typography>
      </Box>
    </div>
  );
};

ErrorCard.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  courseId: PropTypes.string,
  productId: PropTypes.string
};

ErrorCard.defaultProps = {
  courseId: '',
  productId: ''
};

export default withStyles(styles)(ErrorCard);
