/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Chatbot script
 *
 * @author Mohamed yasar arabath M
 */

import PathUtils from './PathUtils';
import { GET_HELP } from '../constants';

/* eslint-disable */
function updateChatBotSettings(settings) {
  const targetElement = document.querySelector('.one-reader-maincontainer');
  settings.targetElement = targetElement || document.body;
}

const initESW = (gslbBaseURL, config) => {
  window.embedded_svc.settings.displayHelpButton = true; // Or false
  window.embedded_svc.settings.language = ''; // For example, enter 'en' or 'en-US'
  updateChatBotSettings(window.embedded_svc.settings);

  // Dynamically changes the button ID based on what the visitor enters in the pre-chat form. // Returns a valid button ID. //}
  window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  window.embedded_svc.settings.entryFeature = 'LiveAgent';
  window.embedded_svc.settings.disabledMinimizedText = GET_HELP;
  window.embedded_svc.settings.defaultMinimizedText = GET_HELP;
  window.embedded_svc.settings.loadingText = GET_HELP;
  window.embedded_svc.init(
    config.baseUrl,
    config.supportUrl,
    gslbBaseURL,
    config.id,
    config.name,

    {
      baseLiveAgentContentURL: config.baseLiveAgentContentURL,
      deploymentId: config.deploymentId,
      buttonId: config.buttonId,
      baseLiveAgentURL: config.baseLiveAgentURL,
      eswLiveAgentDevName: config.eswLiveAgentDevName,
      isOfflineSupportEnabled: config.isOfflineSupportEnabled
    }
  );

  if(window.embedded_svc) {
    window.embedded_svc.addEventHandler('afterInit', ()=> {
      if(PathUtils.getQueryParameterbyName('openChat') === 'true') {
        window.embedded_svc.onHelpButtonClick()
      }
      const checkChatBotExist = setInterval(() => {
        const chatBotElement = document.getElementsByClassName('helpButtonEnabled');
        if (chatBotElement && chatBotElement.length > 0) {
          chatBotElement[0].setAttribute('aria-label', 'Expand chat');
          clearInterval(checkChatBotExist);
        }
      }, 100);
    });
  }
};

export default initESW;
