/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * A service to fetch the course details.
 * @author Hari Gangadharan
 */
export default class CourseService extends BaseService {
  constructor() {
    super(constants.COURSE_SYNC_REQUESTED, 'courseStatus');
  }

  handleEvent(eventName, event) {
    const getPath = event.productId ? `/products/${event.productId}` : '';
    let headerParams = null;
    if (event.bundleId) {
      headerParams = {
        headers: {
          'bundle-id': event.bundleId
        }
      };
    }
    return this.getClient('marin').get(`/courses/${event.courseId}${getPath}`, headerParams);
  }

  handleResponse(response, event) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('course').set(response.data, event.bundleId);
  }
}
