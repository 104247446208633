/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * ChannelCourseService add / delete
 *
 * @file ChannelCourseService.js
 * @author Mohamed yasar arabath M
 */


import Framework, { BaseService } from '@greenville/framework';
import {
  COURSE_COLLECTION_SERVICE_FAILED,
  COURSE_COLLECTION_SERVICE_FETCHED,
  COURSE_COLLECTION_SERVICE_REQUESTED,
  HTTP_REQUEST_METHODS
} from '../../../common/constants';

export default class CourseCollectionService extends BaseService {
  constructor() {
    super(COURSE_COLLECTION_SERVICE_REQUESTED, 'coursecollectionstatus');
  }

  /**
   * Handles book collection service event
   *
   * @param {*} eventName
   * @param {*} eventData
   * @returns
   */
  handleEvent(eventName, eventData) {
    const { method, payload = [] } = eventData;
    let response = null;

    switch (method) {
      case HTTP_REQUEST_METHODS.GET:
        response = this.getClient('marin').get('/users/collections');
        break;
      case HTTP_REQUEST_METHODS.POST:
        response = this.getClient('marin').post('/user/collections', payload);
        break;
      case HTTP_REQUEST_METHODS.DELETE:
        response = this.getClient('marin').delete('/user/collections', { data: payload });
        break;
      default:
        break;
    }

    return response;
  }

  /**
   * Handles course collection service response
   *
   * @param {*} response
   */
  // eslint-disable-next-line class-methods-use-this
  handleResponse(response, event) {
    Framework.getEventManager().publish(COURSE_COLLECTION_SERVICE_FETCHED, event, (response && response.data) || []);
  }

  /**
   * Handles book collection service error
   *
   * @param {*} response
   */
  // eslint-disable-next-line class-methods-use-this
  handleError(error) {
    Framework.getEventManager().publish(COURSE_COLLECTION_SERVICE_FAILED, error);
    // eslint-disable-next-line no-console
    console.log(error, 'course collection service error');
  }
}
