/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * Device Account Warning component
 *
 * @file AccountWarning.jsx
 * @author Infant Diana Sebastian
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  withStyles
} from '@material-ui/core';
import { Icon } from '@greenville/mui-theme';
import Framework from '@greenville/framework';

import * as constants from '../constants';
import CommonUtils from '../utils/CommonUtils';
import env from '../env';

const styles = () => ({
  dialogPaper: {
    minWidth: 600,
    height: 418,
    '@media screen and (max-width: 700px)': {
      width: '90%',
      minWidth: 250,
      height: 525
    }
  },
  warningdialogaction: {
    margin: 0
  },
  warninginfo: {
    margin: 8
  },
  warningdialogtitle: {
    textAlign: 'center',
    width: '100%',
    padding: 0,
    paddingBottom: 8
  },
  warningdialogheader: {
    fontFamily: 'TT Commons',
    fontSize: 28,
    lineHeight: '110%',
    padding: '0.5px',
    fontWeight: 'bold'
  },
  warningdialogcontent: {
    padding: 0,
    textAlign: 'center',
    '@media screen and (max-width: 700px)': {
      padding: '0 20px'
    }
  },
  warningdialogcontentheader: {
    fontFamily: 'Hind',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '150%',
    color: '#333333'
  },
  warningdialogactions: {
    justifyContent: 'center',
    padding: 0,
    '@media screen and (max-width: 700px)': {
      display: 'grid'
    }
  },
  lateraction: {
    background: '#FEFEFE',
    border: '1px solid #E8EEFA',
    borderRadius: 40,
    padding: '7px 10.5px',
    '@media screen and (max-width: 700px)': {
      marginBottom: 16
    }
  },
  lateractiontext: {
    fontFamily: 'Hind',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    width: '87px',
    lineHeight: '150%',
    color: '#333333'
  },
  changepwdaction: {
    marginLeft: '16px !important',
    background: 'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
    borderRadius: 40,
    border: 'none',
    padding: '8px 20px',
    '&:hover': {
      background: 'linear-gradient(135deg, #D6492A 0%, #BA015A 63.44%, #940159 100%)'
    }
  }
});

const AccountWarning = ({
  open, onClose, classes
}) => {
  const language = Framework.getStoreRegistry().getStore('language');
  const handlepwdChange = () => {
    CommonUtils.dispatchGaEvent(constants.CONCURRENT_SESSIONS_MODAL_EVENT,
      constants.CONCURRENT_SESSIONS_EVENT_NAME, constants.RESPOND_TO_WARNING, constants.PASSWORD_EVENT_LABEL);
    window.open(env.BOOKSHELF_CONFIG.changePassword, '_self');
  };
  const handleOnClose = (event, dismiss = false) => {
    const eventLabel = dismiss ? constants.DISMISS_EVENT_LABEL : constants.LATER_EVENT_LABEL;
    CommonUtils.dispatchGaEvent(constants.CONCURRENT_SESSIONS_MODAL_EVENT,
      constants.CONCURRENT_SESSIONS_EVENT_NAME, constants.RESPOND_TO_WARNING, eventLabel);
    onClose(event);
  };
  return (
    <Dialog
      open={open}
      id="account-warning-dialog"
      onClose={(event) => { handleOnClose(event, true); }}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="account-warning-dialog-title"
      aria-describedby="account-warning-dialog-description"
      role="dialog"
      aria-modal="true"
      className="dialog-zindex"
    >
      <DialogActions classes={{ root: classes.warningdialogaction }}>
        <IconButton
          id="remove-button"
          aria-label="Close"
          onClick={(event) => { handleOnClose(event, true); }}
        >
          <Icon id="remove-icon" name="Remove" color="action" fontSize="large" />
        </IconButton>
      </DialogActions>
      <div
        className={classes.warninginfo}
      >
        <DialogTitle
          disableTypography
          id="account-warning-dialog-title"
          classes={{ root: classes.warningdialogtitle }}
        >
          <Typography variant="h3" component="h2" className={classes.warningdialogheader}>
            <FormattedMessage {...language.getText('device.THREATONE_TITLE')} />
          </Typography>
        </DialogTitle>
      </div>
      <DialogContent id="account-warning-dialog-description" classes={{ root: classes.warningdialogcontent }}>
        <Typography variant="subtitle2" component="p" className={classes.warningdialogcontentheader}>
          <FormattedMessage {...language.getText('device.THREATONE_DESC')} />
        </Typography>
        <Typography variant="subtitle2" component="p" className={classes.warningdialogcontentheader}>
          <FormattedMessage {...language.getText('device.THREATONE_DESC_TWO')} />
        </Typography>
        <DialogActions classes={{ root: classes.warningdialogactions }}>
          <Button
            color="default"
            variant="outlined"
            id="close"
            className={classes.lateraction}
            onClick={handleOnClose}
          >
            <Typography variant="body2" className={classes.lateractiontext}>
              <FormattedMessage {...language.getText('device.MAY_BE_LATER')} />
            </Typography>
          </Button>
          <Button
            color="default"
            variant="outlined"
            id="change-password"
            className={classes.changepwdaction}
            onClick={handlepwdChange}
          >
            <Typography variant="body2" className={classes.lateractiontext} style={{ color: '#F7F9FD', width: '129px' }}>
              <FormattedMessage {...language.getText('device.ACCESS_CHANGE_PASSWORD')} />
            </Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

AccountWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(AccountWarning);
