/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * BrazeContainer
 *
 * @file BrazeContainer.jsx
 * @author Manimaran S
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TemplateA from './templates/TemplateA';
import TemplateB from './templates/TemplateB';
import TemplateD from './templates/TemplateD';
import TemplateE from './templates/TemplateE';
import TEMPLATEF from './templates/TemplateF';
import TEMPLATEG from './templates/TemplateG';
import {
  TEMPLATE_A,
  TEMPLATE_B,
  TEMPLATE_D,
  TEMPLATE_E,
  TEMPLATE_F,
  TEMPLATE_G,
} from '../../common/constants';
import ErrorBoundary from '../../common/components/ErrorBoundary';

const BrazeContainer = (props) => {
  const { data: { template }} = props;
  const templates = new Map([
    [TEMPLATE_A, TemplateA],
    [TEMPLATE_B, TemplateB],
    [TEMPLATE_D, TemplateD],
    [TEMPLATE_E, TemplateE],
    [TEMPLATE_F, TEMPLATEF],
    [TEMPLATE_G, TEMPLATEG],
  ]);
  let ComponentToRender = null;

  if (templates.has(template)) {
    ComponentToRender = templates.get(template);
  }

  return ComponentToRender ? <ErrorBoundary><ComponentToRender {...props} /></ErrorBoundary> : null;
};

BrazeContainer.defaultProps = {
};

BrazeContainer.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.string.isRequired
  }).isRequired
};

export default memo(BrazeContainer);
