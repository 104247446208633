/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2021 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * QRCodeModal component
 *
 * @file modal.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import { Icon } from '@greenville/mui-theme';
import QRCode from 'react-qr-code';
import QRCodeStyles from './styles/QRCodeStyles';

const QRCodeModal = ({
  classes,
  data,
  callbacks
}) => {
  const {
    open,
    header,
    subHeader,
    ignoreAppQrCode,
    footer,
    qrCode,
    isQRCodeMsg,
    close
  } = data;
  const { handleQrCodeClick } = callbacks;

  const qrCodeModalRef = useRef(null);

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      id="title-info-dialog"
      onClose={handleQrCodeClick}
      aria-label={header}
      role="dialog"
      aria-modal="true"
      PaperProps={{
        tabIndex: 0,
        ref: qrCodeModalRef
      }}
      disableRestoreFocus
      TransitionProps={{
        onEntered: () => {
          if (qrCodeModalRef && qrCodeModalRef.current) {
            qrCodeModalRef.current.focus();
          }
        }
      }}
    >
      <DialogContent
        id="title-info-dialog-description"
        classes={{ root: classes.dialogContentContainer }}
      >
        <Box className={classes.infodescriptionWrapper}>
          <Typography className={classes.dialogQRSubheader}>
            {subHeader}
          </Typography>
          <div className={qrCode ? classes.qrImageContainer : classes.qrErrorContainer}>
            { !isQRCodeMsg && !qrCode && <div className={classes.loaderContainer}><CircularProgress /></div> }
            {isQRCodeMsg && <div className={classes.dialogErrorMsg}>{isQRCodeMsg}</div>}
            {isQRCodeMsg && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              id="close-button"
              aria-label="Close"
              className={classes.closeContainer}
              onClick={() => handleQrCodeClick()}
            >
              <div className={classes.closeButton}>{close}</div>
            </div>
            )}
            {qrCode && <QRCode value={qrCode} size={200} />}
          </div>
          <div className={classes.qrMsgContainer}>
            <Typography className={classes.dialogQueMsg}>
              {ignoreAppQrCode}
            </Typography>
            <Typography className={classes.dialogFooterMsg}>
              {footer}
            </Typography>
          </div>
          <Typography className={classes.dialogFooterAction}>
            <a aria-label="Download on Appstore" href="https://apps.apple.com/us/app/pearson-us-etexts/id1571362338" target="_blank" rel="noopener noreferrer">
              <img alt="Appstore" src="https://plus.pearson.com/auth-home/media/images/message-center/download_appstore.png" />
            </a>
            <a aria-label="Get it in Playstore" href="https://play.google.com/store/apps/details?id=com.pearson.plus" target="_blank" rel="noopener noreferrer">
              <img alt="Playstore" src="https://plus.pearson.com/auth-home/media/images/message-center/download_playstore.png" />
            </a>
          </Typography>
        </Box>
      </DialogContent>
      <div
        className={classes.dialogTitleInfoContanier}
      >
        <Typography variant="h3" component="h2" className={classes.dialogheader}>
          {header}
        </Typography>
        <DialogActions classes={{ root: classes.dialogAction }}>
          <IconButton
            id="remove-button"
            aria-label="Close"
            onClick={() => handleQrCodeClick()}
            classes={{ root: classes.closeIcon }}
          >
            <Icon id="remove-icon" name="Remove" color="action" fontSize="large" />
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

QRCodeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    open: PropTypes.bool,
    header: PropTypes.string,
    subHeader: PropTypes.string,
    ignoreAppQrCode: PropTypes.string,
    qrCode: PropTypes.string,
    footer: PropTypes.string,
    isQRCodeMsg: PropTypes.string,
    close: PropTypes.string
  }).isRequired,
  callbacks: PropTypes.shape({
    handleQrCodeClick: PropTypes.func
  }).isRequired
};

export default withStyles(QRCodeStyles)(QRCodeModal);
