/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * A React component for error message icon
 *
 * @file ErrorMessageIcon.jsx
 * @author Infant Diana Sebastian
 */
/* eslint-disable max-len */

import React from 'react';

const ErrorMessageIcon = props => (
  <svg width="363" height="184" viewBox="0 0 363 184" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_dd)">
      <path d="M190 156.205C223.137 156.205 250 129.566 250 96.7048C250 63.8439 223.137 37.2048 190 37.2048C156.863 37.2048 130 63.8439 130 96.7048C130 129.566 156.863 156.205 190 156.205Z" fill="url(#paint0_linear)" />
    </g>
    <path d="M190 135.205C211.539 135.205 229 117.744 229 96.2048C229 74.6657 211.539 57.2048 190 57.2048C168.461 57.2048 151 74.6657 151 96.2048C151 117.744 168.461 135.205 190 135.205Z" fill="url(#paint1_linear)" />
    <path d="M189.5 114.205C199.718 114.205 208 106.146 208 96.2048C208 86.2637 199.718 78.2048 189.5 78.2048C179.283 78.2048 171 86.2637 171 96.2048C171 106.146 179.283 114.205 189.5 114.205Z" fill="url(#paint2_linear)" />
    <g filter="url(#filter1_dd)">
      <path d="M28.6551 88.2147L23.7493 83.2164C20.3771 79.7806 23.0596 74.0001 27.8605 74.357L34.3741 80.8986C34.5235 81.0508 34.7291 81.1349 34.9444 81.1316C35.1597 81.1284 35.3665 81.0382 35.518 80.8815C35.6707 80.7307 35.7588 80.5266 35.7627 80.3141L35.7552 80.188C35.7319 80.0222 35.6569 79.868 35.5393 79.7481L30.3372 74.5415L37.0195 75.0383L42.3497 80.4682C42.4954 80.624 42.6989 80.7129 42.9141 80.7147C43.1293 80.7166 43.3382 80.6314 43.4931 80.4781C43.6481 80.3322 43.7392 80.131 43.7457 79.9202L43.7395 79.7951C43.718 79.6305 43.6443 79.477 43.5276 79.3581L39.3992 75.2153L49.1646 76.8657C50.8005 77.1422 52.1927 78.2121 52.8809 79.7217L55.6153 85.7195L28.6551 88.2147Z" fill="#86D6E6" />
      <path d="M28.6692 88.3968L24.5959 94.094C21.796 98.0102 25.3407 103.306 30.0285 102.21L35.4514 94.7397C35.5754 94.5662 35.7655 94.4513 35.9788 94.4212C36.192 94.3911 36.4103 94.4482 36.5842 94.5796C36.7584 94.7049 36.8769 94.8929 36.9138 95.1023L36.9258 95.228C36.9285 95.3954 36.8783 95.5594 36.7806 95.696L32.4469 101.645L38.9718 100.12L43.3976 93.9307C43.5173 93.7542 43.7046 93.6349 43.917 93.5998C44.1293 93.5646 44.3489 93.6165 44.5257 93.744C44.7013 93.8641 44.8224 94.0488 44.8615 94.2561L44.8747 94.3807C44.879 94.5466 44.8299 94.7096 44.733 94.8451L41.2955 99.5769L50.6878 96.4352C52.2612 95.9089 53.4711 94.6365 53.9174 93.0386L55.6907 86.6899L28.6692 88.3968Z" fill="#86D6E6" />
      <path d="M26.9817 89.7404C26.437 89.7735 25.9663 89.3639 25.924 88.8198L25.8931 88.4229C25.8507 87.8789 26.2523 87.4013 26.7956 87.3498L94.2557 80.9467C94.8121 80.8939 95.3038 81.3074 95.3472 81.8646L95.5563 84.5513C95.5997 85.1086 95.1779 85.5932 94.62 85.6271L26.9817 89.7404Z" fill="#003860" />
      <path d="M94.9541 84.4324L94.7706 82.075L100.739 82.5983C100.964 82.6181 100.989 82.9374 100.77 82.9917L94.9541 84.4324Z" fill="#003860" />
    </g>
    <g filter="url(#filter2_dd)">
      <path d="M63.2178 30.1411L62.2144 23.2098C61.5247 18.4453 67.1049 15.3677 70.7666 18.493L72.155 27.6194C72.1854 27.8305 72.3016 28.0198 72.4772 28.1444C72.6528 28.2691 72.873 28.3186 73.0878 28.2817C73.3001 28.2503 73.4917 28.1377 73.6206 27.9686L73.689 27.8624C73.7682 27.7149 73.7989 27.5462 73.7748 27.38L72.6556 20.1055L77.7523 24.4555L78.843 31.9859C78.8684 32.1976 78.98 32.3896 79.1525 32.5183C79.325 32.647 79.5439 32.7017 79.7595 32.6696C79.9707 32.6435 80.1631 32.535 80.2929 32.3688L80.3619 32.2642C80.4418 32.1187 80.4731 31.9514 80.4492 31.7865L79.5674 26.0047L86.4695 33.1074C87.6257 34.2972 88.1164 35.9831 87.7794 37.6076L86.4404 44.0619L63.2178 30.1411Z" fill="#86D6E6" />
      <path d="M63.1217 30.2965L56.4688 32.4849C51.8958 33.9893 51.6253 40.3562 56.0543 42.2429L64.844 39.4214C65.0466 39.3547 65.2678 39.3745 65.4576 39.4762C65.6474 39.5779 65.7898 39.753 65.8524 39.9617C65.9189 40.1658 65.9034 40.3875 65.8094 40.5782L65.7447 40.6867C65.648 40.8233 65.5106 40.9259 65.351 40.9784L58.3393 43.216L64.504 45.8421L71.7321 43.465C71.933 43.3935 72.1545 43.4079 72.3466 43.5051C72.5387 43.6022 72.6851 43.7738 72.7524 43.9811C72.8231 44.1819 72.8117 44.4024 72.7206 44.5927L72.6577 44.701C72.5631 44.8374 72.4272 44.9399 72.2689 44.9919L66.6994 46.7773L76.1327 49.7939C77.7129 50.2993 79.4409 49.9879 80.7453 48.9626L85.9279 44.8894L63.1217 30.2965Z" fill="#86D6E6" />
      <path d="M60.9661 30.3828C60.5072 30.0876 60.3696 29.479 60.657 29.0151L60.8666 28.6766C61.154 28.2128 61.7602 28.0649 62.2289 28.3443L120.431 63.0483C120.911 63.3345 121.063 63.9587 120.769 64.4338L119.35 66.7247C119.056 67.1998 118.429 67.3415 117.959 67.0392L60.9661 30.3828Z" fill="#003860" />
      <path d="M118.935 66.2732L120.18 64.2631L124.685 68.2127C124.855 68.3616 124.686 68.6339 124.477 68.5481L118.935 66.2732Z" fill="#003860" />
    </g>
    <g filter="url(#filter3_dd)">
      <path d="M331.673 129.539L334.102 136.108C335.771 140.623 330.957 144.798 326.723 142.507L323.46 133.871C323.386 133.671 323.233 133.51 323.035 133.425C322.837 133.34 322.612 133.337 322.409 133.418C322.208 133.493 322.044 133.643 321.954 133.836L321.909 133.954C321.862 134.115 321.867 134.286 321.926 134.444L324.539 141.324L318.647 138.134L316.008 130.998C315.938 130.796 315.789 130.631 315.594 130.541C315.398 130.452 315.173 130.444 314.968 130.52C314.767 130.59 314.602 130.736 314.51 130.926L314.464 131.042C314.416 131.201 314.421 131.372 314.478 131.528L316.548 136.998L308.315 131.493C306.936 130.571 306.104 129.025 306.094 127.366L306.056 120.774L331.673 129.539Z" fill="#86D6E6" />
      <path d="M331.735 129.367L337.784 125.838C341.943 123.412 340.878 117.128 336.152 116.208L328.145 120.803C327.961 120.91 327.741 120.937 327.534 120.877C327.327 120.818 327.151 120.676 327.046 120.485C326.939 120.299 326.908 120.079 326.96 119.873L327 119.753C327.067 119.6 327.179 119.471 327.325 119.386L333.714 115.734L327.137 114.453L320.565 118.287C320.383 118.399 320.164 118.431 319.956 118.376C319.747 118.321 319.568 118.184 319.459 117.995C319.348 117.813 319.313 117.595 319.363 117.39L319.402 117.271C319.466 117.118 319.577 116.989 319.721 116.905L324.795 113.996L314.94 113.016C313.289 112.852 311.664 113.517 310.602 114.792L306.385 119.858L331.735 129.367Z" fill="#86D6E6" />
      <path d="M333.825 128.832C334.336 129.025 334.597 129.592 334.413 130.105L334.279 130.48C334.094 130.994 333.532 131.265 333.016 131.089L268.85 109.304C268.321 109.124 268.042 108.545 268.23 108.019L269.14 105.483C269.328 104.956 269.912 104.687 270.434 104.885L333.825 128.832Z" fill="#003860" />
      <path d="M269.64 105.838L268.842 108.063L263.611 105.142C263.414 105.031 263.522 104.73 263.745 104.77L269.64 105.838Z" fill="#003860" />
    </g>
    <defs>
      <filter id="filter0_dd" x="108" y="20.2048" width="164" height="163" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
      <filter id="filter1_dd" x="0.241455" y="57.3413" width="122.68" height="72.0153" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
      <filter id="filter2_dd" x="30.8809" y="0.227539" width="115.874" height="95.337" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
      <filter id="filter3_dd" x="241.508" y="87.7666" width="120.868" height="82.3885" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
      <linearGradient id="paint0_linear" x1="147.592" y1="138.076" x2="231.725" y2="54.6295" gradientUnits="userSpaceOnUse">
        <stop offset="0.14" stopColor="#F58649" />
        <stop offset="1" stopColor="#F6BC4D" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="138.5" y1="129" x2="229" y2="63.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DEF2F7" />
        <stop offset="0.38" stopColor="#E1F3F8" />
        <stop offset="0.68" stopColor="#EBF7FA" />
        <stop offset="0.97" stopColor="#FCFEFE" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="176.431" y1="108.257" x2="201.907" y2="83.4694" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F58649" />
        <stop offset="1" stopColor="#F6BC4D" />
      </linearGradient>
    </defs>
  </svg>
);

export default ErrorMessageIcon;
