/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { BaseService, Utils } from '@greenville/framework';
import * as constants from '../../../common/constants';
import env from '../../../common/env';

/**
 * @author Sarathkumar Venkatachalam
 * @author Hari Gangadharan (refactored)
 */
export default class PageSequenceMappingService extends BaseService {
  constructor() {
    super(constants.PAGE_SEQUENCE_ASSET_REQUESTED);
    this.pageId = '';
  }

  handleEvent(eventName, event) {
    const { id, productId } = event;
    const getPath = id ? `&id=${id}` : '';
    return this.getClient('prism').get(`/contenttoc/v1/assets?productId=${productId}${getPath}`);
  }

  redirectToDigitalPage(id, productId, pageId, queryParams) { // eslint-disable-line class-methods-use-this
    if (id) {
      window.open(`${env.CONTENT_HOST}/courses/${id}/products/${productId}/pages/${pageId}${queryParams}`, '_self');
    } else {
      window.open(`${env.CONTENT_HOST}/products/${productId}/pages/${pageId}${queryParams}`, '_self');
    }
  }

  handleResponse(response, event) { // eslint-disable-line class-methods-use-this
    const {
      id, productId, pageNumber, queryParams
    } = event;
    const { children } = response.data;
    let pageId = children && children.length && children[0].id;
    if (pageNumber) {
      Utils.treeWalk(children, null, 'children', -1, (node) => {
        if (node.playOrder === pageNumber) {
          pageId = node.id;
          return false; // false stops the iteration
        }
        return true; // we need to continue to walk the tree since we did not find a match
      });
    }
    this.redirectToDigitalPage(id, productId, pageId, queryParams);
  }
}
