/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Service and event manager for pdf hotspot fetch
 *
 * @file PdfHotspotService.js
 * @author Gowtaman Madanselvaraj
 */

import Framework, { BaseService } from '@greenville/framework';

import * as constants from '../../../common/constants';

export default class PdfHotspotService extends BaseService {
  constructor() {
    super(constants.PDF_HOTSPOT_REQUESTED, 'PdfHotspotStatus');
  }

  handleEvent(eventName, event) {
    return this.getClient('prism')
      .get(`/contenttoc/v1/assets/hotspot?productId=${event.productId}&pageNo=${event.pageId}`);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    const hotspotData = {
      hotspot: response.data
    };

    Framework.getStoreRegistry().getStore('pdfHotspot').setPdfHotspot(hotspotData);
  }
}
