/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to glossary hotspot
 *
 * @file GlossaryHotspot.js
 * @author Vignesh Selvam
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';

import {
  FR_FR,
  FR_CA,
  GLOSSARY_HOTSPOT_REQUESTED,
  JA,
  JA_JP,
  KO,
  KO_KR
} from '../../../common/constants';

/**
 * A mobx model for glossary source
 *
 */
const Source = types.model(
  'Source',
  {
    completeAudio: types.maybeNull(types.string),
    definition: types.string,
    itemId: types.string,
    term: types.string
  }
);

/**
 * A mobx model for glossary data
 *
 */
const GlossaryData = types.model(
  'GlossaryData',
  {
    id: types.string,
    uuid: types.string,
    source: types.compose(Source)
  }
);

/**
 * A mobx model for glossary hotspot
 *
 */
const GlossaryHotspot = types.model(
  'GlossaryHotspot',
  {
    searchResults: types.array(GlossaryData)
  }
).actions(self => ({

  fetch(pageId, productId, language) {
    const frenchLocale = [FR_FR, FR_CA];
    let glossaryLanguage = '';

    if (language.indexOf('-') > -1) {
      if (frenchLocale.includes(language)) {
        glossaryLanguage = FR_FR.replace('-', '_');
      } else {
        glossaryLanguage = language.replace('-', '_');
      }
    } else {
      switch (language) {
        case JA:
          glossaryLanguage = JA_JP;
          break;
        case KO:
          glossaryLanguage = KO_KR;
          break;
        default:
          glossaryLanguage = language.concat('_', language);
          break;
      }
    }
    const request = {
      queryString: '*',
      filter: [
        `lang:${glossaryLanguage}`,
        `indexId:${productId}`,
        `pageNo:${pageId}`,
        'type:glossary'
      ],
      fieldsToReturn: [
        'term',
        'definition',
        'itemId',
        'pageNo',
        'pageOrder',
        'completeAudio'
      ]
    };

    Framework.getEventManager().publish(GLOSSARY_HOTSPOT_REQUESTED, request);
  },

  /**
   * Set the glossary hotspot data to the store
   *
   * @param {Object} glossaryHotspot
   */
  setGlossaryHotspot(glossaryHotspot) {
    applySnapshot(self, glossaryHotspot);
  }
}));

export default GlossaryHotspot;
