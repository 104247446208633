/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * @author Vijaykani Elangovan
 */
export default class RemoveEntitlement extends BaseService {
  constructor() {
    super(constants.REMOVE_ENTITLEMENT, 'removeEntitlement');
  }

  handleEvent(eventName, event) {
    const removeEntitlementEndPoint = 'mojo/entitlements';
    const headerParams = { headers: { 'X-TenantId': 'ETEXT' } };

    return this.getClient('marin').delete(removeEntitlementEndPoint, { data: event.data }, headerParams);
  }

  handleResponse(response, event) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('bookshelf').removeEntitlement(event.bookId, event.isBanner);
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('bookshelf').setError(error);
  }
}
