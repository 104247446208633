/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateA
 *
 * @file TemplateA.jsx
 * @author Manimaran S
 */

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Card, CardContent, Snackbar, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TOPRIGHT } from '../../../common/constants';
import TemplateAStyles from './styles/templateAStyles';

const TemplateA = ({ data, callbackForBraze }) => {
  const {
    styles, title, description, imageUrl, position
  } = data;
  const {
    onViewCard, onDismiss
  } = callbackForBraze;
  const classes = TemplateAStyles(styles)();
  const [open, setOpen] = useState(false);
  const snackbarPosition = {
    CENTER: 'positionCenter',
    BOTTOMRIGHT: 'positionBottomRight',
    TOPRIGHT: 'positionTopRight'
  };

  const dismiss = () => {
    setOpen(false);
    onDismiss();
  };

  useEffect(() => {
    if (data) {
      setOpen(true);
      onViewCard();
    }
  }, [data && data.show]);

  return (
    data && (
    <Snackbar
      className={
        `${classes.snackbar} ${position ? classes[snackbarPosition[position.toUpperCase()]] : ''} 
        ${classes.window}`
      }
      classes={{ anchorOriginTopRight: classes.anchorOriginTopRight }}
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Card classes={{ root: classes.card }}>
        <CardContent className={classes.rootCardContent}>
          <IconButton onClick={dismiss} size="small" classes={{ root: classes.iconRoot }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <div className={classes.contentCardContanier}>
            {imageUrl && (
              <div>
                <img style={{ width: '80px' }} alt="" src={imageUrl} />
              </div>
            )}
            <div className={classes.contentArea}>
              {title && (
              <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              )}
              {description && (
              <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
              )
              }
            </div>
          </div>
        </CardContent>
      </Card>
    </Snackbar>
    )
  );
};

TemplateA.defaultProps = {
  data: {
    styles: {},
    imageUrl: '',
    position: TOPRIGHT
  },
  callbackForBraze: {
    onViewCard: () => {},
    onDismiss: () => {}
  }
};

TemplateA.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    styles: PropTypes.string,
    imageUrl: PropTypes.string,
    position: PropTypes.string
  }).isRequired,
  callbackForBraze: PropTypes.shape({
    onViewCard: PropTypes.func,
    onDismiss: PropTypes.func
  }).isRequired
};

export default memo(TemplateA);
