/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Channel TOC Service
 *
 * @file ChannelTOCService.js
 * @author Gladson Samuel S
 */

import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class ChannelTOCService extends BaseService {
  constructor() {
    super(constants.CHANNEL_TOC_REQUESTED, 'channelTocStatus');
  }

  /**
   * Trigger the study channel recommendations by channel api call
   *
   * @returns
   */
  handleEvent(eventName, eventData) {
    const { courseId } = eventData;

    return this.getClient('studyChannel').get(`/courses/${courseId}/userTocIndex`);
  }

  /**
   * Handle study channel recommendations by channel api response and setting ChannelRecommendationsByChannel store
   *
   * @param {*} response
   */
  // eslint-disable-next-line class-methods-use-this
  handleResponse(response) {
    const tableOfContents = response.data;
    Framework.getEventManager().publish(constants.CHANNEL_TOC_FETCHED, tableOfContents);
    Framework.getEventManager().publish(constants.FETCH_HERO_MAPPED_CHANNEL);
  }

  /**
   * Error handling
   *
   * @param {*} error
   */
  // eslint-disable-next-line class-methods-use-this
  handleError(error) {
    console.log(error, 'error in Channel TOC fetch');
    const herobanner = Framework.getStoreRegistry().getStore('herobanner');
    herobanner.setAssets(herobanner.getAssetsInitialState(), false);
    Framework.getEventManager().publish(constants.CHANNEL_TOC_FAILED);
  }
}
