/*
* PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
* Copyright © 2019 Pearson Education, Inc.
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Pearson Education, Inc. The intellectual and technical concepts contained
* herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
* patent applications, and are protected by trade secret or copyright law.
* Dissemination of this information, reproduction of this material, and copying or distribution of this software
* is strictly forbidden unless prior written permission is obtained
* from Pearson Education, Inc.
*/
import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Framework, { shapes } from '@greenville/framework';
import * as constants from '../../common/constants';
import PathUtils from '../../common/utils/PathUtils';
import env from '../../common/env';
/**
* React Container component for course.
*
* @author Sarathkumar Venkatachalam
* @author Hari Gangadharan (refactored)
*/
class PageSequenceContainer extends React.Component {
  static propTypes = {
    match: shapes.match.isRequired,
    history: shapes.history.isRequired
  };

  componentDidMount() {
    const { match, history } = this.props;
    const { id, productId, pageNumber } = match.params;
    const printPage = PathUtils.getQueryParameterbyName('printPage');
    const queryParams = history.location.search;
    if (printPage) {
      if (id) {
        window.open(`${env.CONTENT_HOST}/courses/${id}/products/${productId}${queryParams}`, '_self');
      } else {
        window.open(`${env.CONTENT_HOST}/products/${productId}${queryParams}`, '_self');
      }
    } else {
      Framework.getEventManager().publish(constants.PAGE_SEQUENCE_ASSET_REQUESTED, {
        id,
        productId,
        pageNumber,
        queryParams
      });
    }
  }

  render() {
    return (
      <Fragment>
        <CircularProgress className="circleStyle" />
      </Fragment>
    );
  }
}

export default PageSequenceContainer;
