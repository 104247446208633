import Framework, { BaseService } from '@greenville/framework';

import { USER_PREFERENCES_REQUESTED, USER_PREFERENCES_SUCESS, USER_PREFERENCES_FAIL } from '../../../common/constants'
export default class UserPreferencesService extends BaseService {
    constructor() {
        super(USER_PREFERENCES_REQUESTED, 'userpreferencestatus')
        this.error = false;
    }

    fetch () {
        return this.getClient('marinV2').get('/userpreferences');
    }
    
    handleEvent(eventName, event) {
        if (event) {
            const resposne = this.getClient('marinV2').put('/userpreferences', event.payload);
            return resposne;
        } else {
            const resposne = this.getClient('marinV2').get('/userpreferences');
            return resposne;
        }
    }

    handleResponse(response, event) {
        // GET API call only gives return data repsonse
        if (!event) {
            this.error = false;
            const { data } = response;
            if (data) {
                Framework.getEventManager().publish(USER_PREFERENCES_SUCESS, data);
            }
        }
    }

    handleError(error) {
        this.error = true;
        Framework.getEventManager().publish(USER_PREFERENCES_FAIL);
        console.log(error, 'USER_PREFERENCES_SUCESS Content service error');
    }
}