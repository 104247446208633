/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * React component for Otp Input
 *
 * @file OtpInput.jsx
 * @author Infant Diana Sebastian
 */

import React from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import {withStyles} from '@material-ui/core';

const styles = () => ({
    containerStyle: {
        '& div': {
            width: 40,
            height: 48,
            marginRight: 12
        }        
    }
});

const DeviceOtpInput = ({ classes, otp, onChange, isError, isDisabled }) => {
    return (
        <OtpInput
            value={isDisabled? '' : otp}
            onChange={onChange}
            numInputs={6}
            isInputNum
            inputStyle={{
                width: 40,
                height: '100%',
                background: '#FEFEFE',
                border: '1px solid #9BA1AD',
                borderRadius: 8,
                color: isError ? '#7E031A' : null
            }}
            separator={<span>&nbsp;</span>}
            containerStyle={classes.containerStyle}
            style={{width: '100%'}}
            hasErrored={isError}
            errorStyle={{
                animation: 'shake 0.2s ease-in-out 0s 2',
                border: '2px solid #7E031A'
            }}
            isDisabled={isDisabled}
            disabledStyle={{
                border: '2px solid #7E031A'
            }}
            placeholder={isDisabled ? 'XXXXXX' : ''}
      />
    )
}

DeviceOtpInput.propTypes = {
    classes: PropTypes.object.isRequired,
    otp: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isError: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DeviceOtpInput);
