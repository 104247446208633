/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to ChannelRecommendationsByBook
 *
 * @file ChannelRecommendationsByBook.js
 * @author Manimaran S
 */

import { types } from 'mobx-state-tree';

const ChannelAssets = types.model('ChannelAssets', {
  assetContentType: types.maybeNull(types.string),
  assetDuration: types.maybeNull(types.string),
  assetTitle: types.maybeNull(types.string),
  assetType: types.maybeNull(types.string),
  chapterTitle: types.maybeNull(types.string),
  thumbUrl: types.maybeNull(types.string),
  topicTitle: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  completed: types.optional(types.boolean, false)
});

export default ChannelAssets;
