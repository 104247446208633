/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Mohamed yasar arabath M
 */

import Framework from '@greenville/framework';
import { getSnapshot } from 'mobx-state-tree';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';
import HeroDashboardPlugin from './HeroDashboardPlugin';

export default class StudyHelpPlugin {
  constructor(context) {
    this.context = context;
    this.init();
  }

  /**
   * Plugin initial
   *
   */
  init = () => {
    Framework.getEventManager().on(constants.LASTLOCATION_DATA_REQUESTED, this.setStudyChannelBooks);
    Framework.getEventManager().on(constants.FETCH_HERO_MAPPED_CHANNEL, props => this.fetchHeroMappedChannel(props));
    Framework.getEventManager().on(constants.CHANNEL_RECOMMENDATIONS_BY_BOOK_FETCHED, () => {
      this.getBookAssets();
    });
    Framework.getEventManager().on(constants.CHANNEL_RECOMMENDATIONS_BY_CHANNEL_FETCHED, this.getChannelOnlyAssets);
  }

  /**
   * Feeding books to study channel
   *
   * @param {'*'} param
   */
  setStudyChannelBooks = ({ bookshelfData }) => {
    let studyChannelBooks = [];
    const activeBooks = CommonUtils.getActiveBooks(bookshelfData && bookshelfData.books);
    if (activeBooks && activeBooks.length > 0) {
      studyChannelBooks = activeBooks.map((book) => {
        const constructStudyChannelBook = {
          bookId: book.book_id || null,
          bookTitle: book.title || null,
          channelId: book.channel_id || null,
          productId: book.product_id || null,
          thumbnailUrl: book.cover_image_url || null
        };

        return constructStudyChannelBook;
      });
    }

    this.context.setState({ studyChannelBooks });
  }

  /**
   * Initialize channel api based on book or channel
   *
   */
  fetchHeroMappedChannel = () => {
    const { herobanner, channelRecommendationsByBook, channelRecommendationsByChannel } = this.context.props;
    const heroBanner = getSnapshot(herobanner);
    const {
      componentsToRender
    } = heroBanner.config;
    const {
      CHANNEL, BOOK_WITH_CHANNEL
    } = constants.HERO_BANNER_TYPES;

    if (componentsToRender?.showAssets) {
      switch (heroBanner.bannerType) {
        case BOOK_WITH_CHANNEL: {
          const heroBook = this.context.heroDashboardPlugin.findHeroBook();
          const maxAssetsByBook = this.context.getContainerData(
            'heroView',
            constants.CHANNEL_BYBOOK_MAX_ASSET_B
          ) || constants.MAX_CHANNEL_CARD;
          const payLoad = {
            bookId: heroBook.book_id,
            productId: heroBook.product_id,
            channelId: heroBook.channel_id,
            chapterName: heroBanner.tableOfContents?.parentChapterTitle || '',
            subChapterName: StudyHelpPlugin.getSubchapterName(),
            maxAssets: maxAssetsByBook
          };

          if (heroBook.channel_id) {
            herobanner.resetAssets();
            channelRecommendationsByBook.fetch(payLoad);
          }
          break;
        }
        case CHANNEL: {
          const courseId = heroBanner.channelId;
          const topicId = StudyHelpPlugin.getSubchapterId();

          if (courseId && topicId) {
            herobanner.resetAssets();
            channelRecommendationsByChannel.fetch(courseId, topicId);
          }
          break;
        }
        default:
          break;
      }
    }
  }

  /**
   * Construct mapped channel for hero banner book
   *
   */
  getBookMappedChannel = () => {
    const {
      herobanner,
      channelRecommendationsByBook,
      language,
      user
    } = this.context.props;
    const heroBanner = getSnapshot(herobanner);
    const { config } = heroBanner || {};
    const { variant } = config || {};
    const isHeroVariant2 = variant === constants.HERO_BANNER_V1_VARIANTS.V2;
    const maxAssets = this.context.getContainerData(
      'heroView',
      constants.CHANNEL_BYBOOK_MAX_ASSET_B
    ) || constants.MAX_CHANNEL_CARD;
    const recommendedChannelAssets = channelRecommendationsByBook.getBookMappedChannel(maxAssets, isHeroVariant2);
    const channelTitle = channelRecommendationsByBook.getChannelTitle();
    const heroHeading = language.getMessage('hero.heroHeading').replace('{channel_title}', channelTitle);
    const heroHeadingBundleUser = language.getMessage('hero.heroHeadingForBundleUser');
    const isBundleUser = CommonUtils.hasSubscription(user, [constants.ACTIVE], [constants.BUNDLE]);

    if (channelTitle && user?.gpsSubscriptions && !isHeroVariant2) {
      const heroHeadingText = isBundleUser ? heroHeadingBundleUser : heroHeading;
      // herobanner.setHeroHeading(heroHeadingText);
    }
    if (channelTitle && isHeroVariant2 && !heroBanner?.heroHeading) {
      const heroHeadingText = language.getMessage('hero.heroHeadingV1').replace('{replace_value}', channelTitle);
      // herobanner.setHeroHeading(heroHeadingText);
    }
    if (recommendedChannelAssets) {
      // herobanner.setHeroBannerMappedChannel(recommendedChannelAssets);
    } else {
      // StudyHelpPlugin.clearMappedChannel();
      channelRecommendationsByBook.setByBookError();
    }
  }

  /**
   * Construct mapped channel for hero banner channel
   *
   */
  getMappedChannel = () => {
    const { herobanner, channelRecommendationsByChannel } = this.context.props;
    const maxAssets = this.context.getContainerData(
      'heroView',
      constants.CHANNEL_BYBOOK_MAX_ASSET_B
    ) || constants.MAX_CHANNEL_CARD;
    const channelTopics = channelRecommendationsByChannel.getMappedChannel(maxAssets);

    if (channelTopics) {
      // herobanner.setHeroBannerMappedChannel(channelTopics);
    }
  }

  /**
   * Clearing the mapped channel in hero banner
   *
   */
  static clearMappedChannel = () => {
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const heroBanner = getSnapshot(heroBannerStore);

    if (heroBanner.mappedChannel) {
      // heroBannerStore.setHeroBannerMappedChannel(null);
    }
  }

  /**
   * To get supchapter name from hero banner TOC
   *
   * @returns
   */
  static getSubchapterName = () => {
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const language = Framework.getStoreRegistry().getStore('language');
    const heroBanner = getSnapshot(heroBannerStore);
    const isTocFetched = HeroDashboardPlugin.isTocFetched();
    let subChapterName = '';

    if (isTocFetched
      || (!isTocFetched
      && heroBanner.tableOfContents
      && (heroBanner.tableOfContents.chapterTitle !== language.getMessage('hero.toc.defaultPlacehoder')))) {
      subChapterName = heroBanner.tableOfContents.chapterTitle;
    }

    return subChapterName;
  }

  /**
   * To get supchapter name from hero banner TOC
   *
   * @returns
   */
  static getSubchapterId = () => {
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const heroBanner = getSnapshot(heroBannerStore);
    const isTocFetched = HeroDashboardPlugin.isTocFetched();
    let chapterId = '';

    if (isTocFetched
      || (!isTocFetched
      && heroBanner.tableOfContents
      && heroBanner.tableOfContents.chapterId)) {
      chapterId = heroBanner.tableOfContents.chapterId;
    }

    return chapterId;
  }

  /**
   * Overrides hero banner's mapped channel config if there is an error in either byBook or byChannel API
   *
   * @param {*} config
   * @returns
   */
  overRideMappedChannelConfig = (config) => {
    const {
      channelRecommendationsByBook,
      channelRecommendationsByChannel,
      userStatus,
      herobanner
    } = this.context.props;
    const overRideConfig = { ...config };
    const { bannerType } = getSnapshot(herobanner);
    const { BOOK } = constants.HERO_BANNER_TYPES;

    if (channelRecommendationsByBook.isError || channelRecommendationsByChannel.isError) {
      // overRideConfig.showMappedChannel = false;
    }

    if (channelRecommendationsByBook.isError && !channelRecommendationsByBook.channelData) {
      // overRideConfig.showGeneralMappedChannel = false;
    }
    /**
   * We do not show heading in the hero when either byBook API or userProfile API fails
   *
   */
    overRideConfig.showHeroHeading = !(
      (channelRecommendationsByBook.isError && !channelRecommendationsByBook.channelData)
      || (bannerType === BOOK && userStatus.isError)
    );

    return overRideConfig;
  }

  getBookAssets = () => {
    const { herobanner, channelRecommendationsByBook } = this.context.props;
    const maxAssets = this.context.getContainerData(
      'heroView',
      constants.CHANNEL_BYBOOK_MAX_ASSET_B
    ) || constants.MAX_CHANNEL_CARD;
    const assets = channelRecommendationsByBook.getAssets(maxAssets);

    if (assets && !CommonUtils.isEmpty(assets || {})) {
      herobanner.setAssets(assets);
    }
  }

  getChannelOnlyAssets = () => {
    const { herobanner, channelRecommendationsByChannel } = this.context.props;
    const maxAssets = this.context.getContainerData(
      'heroView',
      constants.CHANNEL_BYBOOK_MAX_ASSET_B
    ) || constants.MAX_CHANNEL_CARD;
    const assets = channelRecommendationsByChannel.getAssets(maxAssets);

    if (assets && !CommonUtils.isEmpty(assets || {})) {
      herobanner.setAssets(assets);
    } else {
      herobanner.setAssets({});
    }
  }
}
