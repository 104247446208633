/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Product utilities
 *
 * @file ProductUtils.js
 * @author Prithviraj K
 */

import PathUtils from './PathUtils';
import * as constants from '../constants';

export default class ProductUtils {
  /**
   * Method to get product description
   *
   * @param {Object} data
   * @param {boolean} requestProductModel
   */
  static getProductDescription(data, entitlementLevel, requestProductModel = false) {
    const {
      format,
      model,
      platform,
      subscription: { businessModelCode }
    } = data;
    const {
      BUNDLED_PLATFORM,
      COLLECTIONS,
      CUSTOM_COLLECTIONS
    } = constants;
    const isBundled = platform === BUNDLED_PLATFORM;
    let productModel = '';
    const bookFormat = ProductUtils.getProductTypeFormat(format);

    if (isBundled) {
      productModel = this.getProductModel(businessModelCode, entitlementLevel, isBundled);
    } else {
      productModel = model === CUSTOM_COLLECTIONS
        ? COLLECTIONS
        : this.getProductModel(businessModelCode, entitlementLevel, isBundled);
    }
    const productDescription = `${productModel}, ${bookFormat}`;

    if (requestProductModel) {
      return productModel;
    }
    return productDescription;
  }

  /**
   * Method to get product model
   *
   * @param {string} businessModelCode
   * @param {boolean} isBundled
   */
  static getProductModel(businessModelCode, entitlementLevel, isBundled) {
    const {
      BUNDLE_SINGLE,
      CG_RENEWALS,
      D2L_INTEGRATED_RENEWAL,
      D2L_STANDALONE,
      D2L_STANDALONE_RENEWAL,
      DIRECT_TO_LEARNER,
      DIRECT_TO_LEARNER_RENEWAL,
      STANDALONE_ETEXT,
      INTEGRATED_SMS,
      INTEGRATED_RUMBA,
      DIRECT_TO_CONSUMER,
      DIRECT_TO_CONSUMER_IA,
      INCLUSIVE_ACCESS_DISPLAY_NAME,
      INTEGRATED_SMS_ETEXT,
      INTEGRATED_RUMBA_ETEXT,
      PEARSON_PLUS_ETEXTBOOK,
      PEARSON_PLUS_MULTI,
      INTEGRATED_ETEXT_3L,
      MOJO_US_SINGLE,
      DIGITAL_SAMPLE_MARKETING,
      DIGITAL_SAMPLE_SELF,
      DIGITAL_SAMPLE_REP,
      SAMPLE_FROM_MARKETING,
      SAMPLE_FROM_REP,
      SAMPLE_FROM_SELF
    } = constants;
    const tpiValue = PathUtils.getQueryParameterbyName('isTpi');
    const singleSubscriptions = [BUNDLE_SINGLE, MOJO_US_SINGLE];

    switch (businessModelCode) {
      case CG_RENEWALS:
        return D2L_INTEGRATED_RENEWAL;
      case DIRECT_TO_LEARNER_RENEWAL:
        return D2L_STANDALONE_RENEWAL;
      case DIRECT_TO_LEARNER:
        return D2L_STANDALONE;
      case INTEGRATED_SMS:
        return tpiValue && tpiValue.toLowerCase() === 'y' ? INTEGRATED_ETEXT_3L : INTEGRATED_SMS_ETEXT;
      case INTEGRATED_RUMBA:
        return INTEGRATED_RUMBA_ETEXT;
      case DIRECT_TO_CONSUMER:
        return singleSubscriptions.includes(entitlementLevel) ? PEARSON_PLUS_ETEXTBOOK : PEARSON_PLUS_MULTI;
      case DIRECT_TO_CONSUMER_IA:
        return INCLUSIVE_ACCESS_DISPLAY_NAME;
      case DIGITAL_SAMPLE_REP:
        return SAMPLE_FROM_REP;
      case DIGITAL_SAMPLE_SELF:
        return SAMPLE_FROM_SELF;
      case DIGITAL_SAMPLE_MARKETING:
        return SAMPLE_FROM_MARKETING;
      default:
        return isBundled ? INTEGRATED_ETEXT_3L : STANDALONE_ETEXT;
    }
  }

  /**
   * Method to get legacyBookId for user data migration
   *
   * @param {string} businessModelCode
   * @param {object} legacyIds : {bookId, productId}
   */
  static getlegacyBookId(businessModelCode, legacyIds) {
    const { bookId, productId } = legacyIds;
    let legacyBookId = productId;
    const {
      CG_RENEWALS,
      INTEGRATED_SMS
    } = constants;
    const MLM_BMC_LIST = [CG_RENEWALS, INTEGRATED_SMS];
    if (MLM_BMC_LIST.includes(businessModelCode)) {
      legacyBookId = bookId;
    }
    return legacyBookId;
  }

    /**
   * To get product type format
   *
   * @param {*} bookType
   * @returns
   */
    static getProductTypeFormat = (bookType) => {
      const {
        BRONTE_VIEWER,
        BRONTE_EPUB,
        CONTENT_TYPE_EPUB,
        CONTENT_TYPE_BV_BRONTE,
        CONTENT_TYPE_BV_VIEWER
      } = constants;
      const renderBookType = bookType && bookType.toUpperCase();
      let format = bookType;

      switch (renderBookType) {
        case CONTENT_TYPE_BV_BRONTE:
        case CONTENT_TYPE_BV_VIEWER:
          format = BRONTE_VIEWER;
          break;
        case CONTENT_TYPE_EPUB:
          format = BRONTE_EPUB;
          break;
        default:
          format = bookType;
          break;
      }

      return format;
    }

    /**
     * Is bronte viewer book
     *
     * @param {*} bookType
     * @returns
     */
    static isBVBronteBook = (bookType) => {
      const renderBookType = bookType && bookType.toUpperCase();
      return ((renderBookType === constants.CONTENT_TYPE_BV_BRONTE) || (renderBookType === constants.CONTENT_TYPE_BV_VIEWER));
    }
}
