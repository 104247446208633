/* eslint-disable class-methods-use-this */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Eligibility Service
 *
 * @file EligibilityService.js
 * @author Gladson Samuel S
 */

import Framework, { BaseService } from '@greenville/framework';
import CommonUitls from '../../../common/utils/CommonUtils';
import env from '../../../common/env';
import {
  ELIGIBILITY_REQUESTED,
  X_RULEGROUP_ID,
  X_TRANSACTION_ID,
  ELIGIBILITY_SUCCESS,
  ELIGIBILITY_FAILURE
} from '../../../common/constants';

export default class EligibilityService extends BaseService {
  constructor() {
    super(ELIGIBILITY_REQUESTED, 'eligiblityStatus');
  }

  /**
   * Triggers eligibility GET call
   *
   * @param {*} eventName
   * @param {*} param1
   * @returns
   */
  handleEvent(eventName, { userId }) {
    const headerParams = {
      headers: {
        [X_RULEGROUP_ID]: `${env.ELIGIBILITY_RULE_GROUP}`,
        [X_TRANSACTION_ID]: CommonUitls.getUUID()
      }
    };
    return this.getClient('eligibility').get(`/eligibility/user/${userId}?authtype=user`, headerParams);
  }

  /**
   * Stores studyLevel, dob, userCountry from eligibilityService
   *
   * @param {*} response
   */
  handleResponse(response) {
    const { data } = response;

    if (data && data.eligiblity) {
      const {
        studyLevel,
        dob,
        userCountry
      } = data;

      // storing initial details received from elibility response
      Framework.getStoreRegistry().getStore('userdetails').setIntialDetails({
        studyLevel: studyLevel || null,
        dob: dob || null,
        userCountry: userCountry || null
      });
    }

    Framework.getEventManager().publish(ELIGIBILITY_SUCCESS);
  }

  /**
   * Handles eligibility call failure
   *
   * @param {*} response
   */
  handleError(error) {
    Framework.getEventManager().publish(ELIGIBILITY_FAILURE);
    console.log(error, 'Eligibility service error');
  }
}
