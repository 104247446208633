/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { useEffect, useState } from 'react';
import CommonUtils from '../../../common/utils/CommonUtils';

/**
 * useBackgroundImage
 *
 * @file useBackgroundImage.js
 * @author Gladson Samuel S
 */

const useBackgroundImage = (backgroundImage) => {
  const isBackgroundImageInList = Array.isArray(backgroundImage);

  /**
   * Fucntion to get resolution based image source
   * @returns
   */
  const getSrcBasedOnResolution = () => {
    const currentWidth = window.innerWidth || document.documentElement.clientWidth;
    const currentImgSrc = backgroundImage.findLast(background => background.breakPoint <= currentWidth);

    return (currentImgSrc && currentImgSrc.imageURL) || null;
  };

  /**
   * Function to get campaign background image
   * @returns
   */
  const getBackgroundImage = () => (isBackgroundImageInList ? getSrcBasedOnResolution() : backgroundImage);
  const [imgSrc, setImgSrc] = useState(getBackgroundImage());

  /**
   * Function to handle resize
   */
  function handleResize() {
    if (backgroundImage && isBackgroundImageInList) {
      setImgSrc(getSrcBasedOnResolution());
    }
  }

  useEffect(() => {
    window.addEventListener('resize', CommonUtils.debounce(handleResize));

    return () => window.removeEventListener('resize', CommonUtils.debounce(handleResize));
  }, []);

  return imgSrc;
};

export default useBackgroundImage;
