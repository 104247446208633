/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Playlist utility for collection PDF
 *
 * @file PlaylistUtils.js
 * @author Prithviraj K
 */

import {
  PLAYLIST_CHAPTER,
  PLAYLIST_SLATE
} from '../constants';

export default class PlaylistUtils {
  /**
   * Method to traverse playlist
   *
   * @param {Array} playlist
   * @param {Array} updatedPlaylist
   */
  static playlistMapper = (playlist, updatedPlaylist, parentId) => {
    const collectionPlaylist = updatedPlaylist || [];

    playlist.forEach((item) => {
      const pageData = item;
      let chapterId;

      if (item.type === PLAYLIST_CHAPTER && item.children) {
        chapterId = item.id;
      }
      if (item.type === PLAYLIST_SLATE) {
        pageData.parentid = parentId;
        collectionPlaylist.push(pageData);
      } else if (item.children && item.children.length) {
        this.playlistMapper(item.children, collectionPlaylist, chapterId);
      }
    });

    return collectionPlaylist;
  }

  /**
   * Flatten the TOC structure to process the mapping between
   * slates and children
   *
   * @param {Array} tocDataList
   */
  static flattenToc = (tocDataList) => {
    const tocFlatten = [];

    const tocMapper = ((tocData) => {
      tocData.forEach((toc) => {
        const tocList = {
          chapterId: toc.id,
          chapterTitle: toc.title,
          id: toc.id,
          href: toc.uri,
          uri: toc.uri,
          type: 'page',
          title: toc.title,
          playOrder: toc.playOrder
        };
        tocFlatten.push(tocList);
        if (toc.children) {
          tocMapper(toc.children);
        }
      });
    });
    tocMapper(tocDataList);

    return tocFlatten;
  }

  /**
   * Method to traverse epub playlist
   *
   * @param {Array} tocDataList
   * @param {string} slateUri
   */
  static epubPlaylist = (tocDataList, slateUri) => {
    let mappedToc = {};

    const mappedAsset = (tocList, uri) => {
      tocList.forEach((tocData) => {
        let assetUri;

        if (tocData.uri.includes('#')) {
          const tocUri = tocData.uri.split('#');
          assetUri = uri.concat('#', tocUri[1]);
        } else {
          assetUri = uri;
        }

        if (tocData.uri === assetUri) {
          mappedToc = {
            id: tocData.id,
            title: tocData.title,
            itemUri: assetUri,
            tocPlayOrder: tocData.playOrder
          };
        }
      });
    };
    mappedAsset(tocDataList, slateUri);

    return mappedToc;
  }
}
