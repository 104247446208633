/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * useCountDownTimer custom hook
 *
 * @file useCountDownTimer.jsx
 * @author Mohamed yasar arabath M
 */
import { useEffect, useState, useRef } from 'react';

import CommonUtils from '../utils/CommonUtils';

const useCountDownTimer = (seconds) => {
  const timer = useRef(null);
  const [countDownSeconds, setCountDownSeconds] = useState(seconds);
  // eslint-disable-next-line func-names
  const getTimeFormat = function () {
    const { h: hours, m: minutes, s: sec } = this.time;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(sec).padStart(2, '0');

    return `${hours ? `${formattedHours}:` : ''}${formattedMinutes}:${formattedSeconds}`;
  };
  const [data, setData] = useState({ time: CommonUtils.secondsToTime(seconds), getTimeFormat });

  const countDown = () => {
    setCountDownSeconds((currentSecond) => {
      const remainSeconds = currentSecond - 1;
      setData(state => ({ ...state, time: CommonUtils.secondsToTime(remainSeconds) }));
      if (remainSeconds === 0) {
        clearInterval(timer.current);
      }

      return remainSeconds;
    });
  };

  const startTimer = () => {
    if (!timer.current && countDownSeconds > 0) {
      timer.current = setInterval(() => {
        countDown();
      }, 1000);
    }
  };

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(timer.current);
    };
  }, [seconds]);

  return [data];
};

export default useCountDownTimer;
