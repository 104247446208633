/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

 import React from 'react';
 import PropTypes from 'prop-types';
 import Skeleton from '@material-ui/lab/Skeleton';
 
 /**
  * Loading Card skeleton
  *
  * @author Poornima N
  */
 const LoadingCardSkeleton = ({
   height, width, skeletonStyle
 }) => (
   <Skeleton
     animation="wave"
     variant="rect"
     height={height}
     width={width}
     className={skeletonStyle}
   />
 );
 
 LoadingCardSkeleton.defaultProps = {
   width: '',
   height: ''
 };
 
 LoadingCardSkeleton.propTypes = {
   height: PropTypes.oneOfType([
     PropTypes.string,
     PropTypes.number]),
   width: PropTypes.oneOfType([
     PropTypes.string,
     PropTypes.number]),
   skeletonStyle: PropTypes.string.isRequired
 };
 
 export default LoadingCardSkeleton;
 