import React from 'react';
import { render } from 'react-dom';
import customProviderWithOrWithoutLD from './common/components/CustomProviderWithOrWithoutLD';

import App from './App';

(async () => {
  const CustomProvider = await customProviderWithOrWithoutLD();
  render(
    <CustomProvider>
      <App />
    </CustomProvider>,
    document.getElementById('root')
  );
})();
