/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateB
 *
 * @file TemplateB.jsx
 * @author Manimaran S
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Card, CardContent, Typography, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TemplateBStyles from './styles/templateBStyles';

const TemplateB = ({ data, callbackForBraze }) => {
  const {
    id, cardId, styles, title, description, imageUrl, action
  } = data;
  const {
    onDismiss
  } = callbackForBraze;
  const classes = TemplateBStyles(styles)();
  const dismiss = () => {
    onDismiss();
  };
  const handleCtaClick = (actionData) => {
    const {
      target, launchURL
    } = actionData;
    if (launchURL) {
      window.open(launchURL, target || '_self');
    }
  };

  return (
    data && (
      <Card
        classes={{ root: classes.card }}
        id={id}
        data-card-id={cardId}
      >
        <CardContent className={classes.rootCardContent}>
          <IconButton onClick={dismiss} size="small" classes={{ root: classes.iconRoot }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <div className={classes.contentCardContanier}>
            {imageUrl && (
              <img className={classes.bannerImage} alt="" src={imageUrl} />
            )}
            <div className={classes.contentArea}>
              {title && (
              <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              )}
              {description && (
              <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
              )
              }
              {action.map((actionData, index) => (
                <Button
                  classes={{ root: classes.buttonContainer }}
                  aria-label={`${actionData.text}`}
                  role="link"
                  key={index}
                  onClick={() => handleCtaClick(actionData)}
                >
                  {actionData.text}
                </Button>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    )
  );
};

TemplateB.defaultProps = {
  data: {
    styles: {},
    imageUrl: ''
  },
  callbackForBraze: {
    onDismiss: () => {}
  }
};

TemplateB.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cardId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    styles: PropTypes.string,
    imageUrl: PropTypes.string
  }).isRequired,
  callbackForBraze: PropTypes.shape({
    onDismiss: PropTypes.func
  }).isRequired
};

export default memo(TemplateB);
