/* eslint-disable class-methods-use-this */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Popular channels service
 *
 * @file PopularChannelsService.js
 * @author Gladson Samuel S
 */

import Framework, { BaseService } from '@greenville/framework';
import PopularChannelsSTGData from '../../../assets/data/popularChannelsSTG.json';
import PopularChannelsPRODData from '../../../assets/data/popularChannelsPROD.json';
import * as constants from '../../../common/constants';
import env from '../../../common/env';

export default class PopularChannelsService extends BaseService {
  constructor() {
    super(constants.POPULAR_CHANNELS_REQUESTED, 'popularChannelsStatus');
  }

  /**
   * Trigger the study channel recommendations by book api call
   *
   * @returns
   */
  handleEvent() {
    return this.getClient('studyChannel').get('/courses/popular?courseTypes=highed&imageOrientation=landscape');
  }

  constructPopularChannelsData(courses) {
    let popularChannels = [];

    if (courses && courses.length > 0) {
      popularChannels = courses.map(({
        details: {
          title,
          thumbnail,
          courseId,
          url,
          learnUrl,
          showExams,
          aiTutorUrl,
          exploreUrl
        }
      }) => ({
        title,
        thumbnailUrl: thumbnail,
        channelId: courseId,
        url,
        active: true,
        learnUrl,
        showExams,
        aiTutorUrl,
        exploreUrl
      }));
    }

    return popularChannels;
  }

  /**
   * Handle study channel recommendations by book api response and setting ChannelRecommendationsByBook store
   *
   * @param {*} response
   */
  handleResponse(response) {
    const responseData = response.data;
    const { courses } = responseData;
    const popularChannels = this.constructPopularChannelsData(courses);

    Framework.getStoreRegistry().getStore('herobanner').setPopularChannels(popularChannels);
  }

  /**
   * Error handling
   *
   * @param {*} error
   */
  handleError(error) {
    let response = PopularChannelsSTGData;

    if (env.ENVIRONMENT === 'prod') {
      response = PopularChannelsPRODData;
    }
    const { courses } = response;
    const popularChannels = this.constructPopularChannelsData(courses);

    Framework.getStoreRegistry().getStore('herobanner').setPopularChannels(popularChannels);
    console.log('Popular channels service error', error, response);
  }
}
