/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Framework from '@greenville/framework';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    withStyles,
    ListItem,
    List,
    IconButton,
    Collapse,
    Divider,
    ListItemText,
    ListItemIcon
} from '@material-ui/core';
import ExpandIcon from './icons/ExpandIcon';
import CollapseIcon from './icons/CollapseIcon';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
    launchCodeTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "TT Commons",
        fontSize: 30,
        lineHeight: '110%',
        color: '#000000',
        paddingBottom: 10,
        paddingTop: 41,
        borderBottom: '1px solid #E8EEFA'
    },
    launchList: {
        padding: 0
    },
    launchCodeDesc: {
        fontFamily: 'TT Commons',
        fontSize: 16,
        lineHeight: '110%',
        color: '#4F5561',
        paddingBottom: 14,
        fontWeight: 600,
        display: 'inline-block'
    },
    launchCodeRightDesc: {
        fontFamily: 'Hind',
        fontSize: 14,
        fontStyle: 'normal',
        letterSpacing: 0,
        display: 'inline-block',
        float: 'right;',
        paddingTop: 6,
        width: 165,
        height: 21,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    divider: {
        marginTop: 6,
        marginBottom: 0,
        background: '#E8EEFA'
    },
    launchCodeListDesc: {
        display: 'inline-block',
        fontFamily: 'TT Commons',
        fontSize: 18,
        color: '#4F5561',
        paddingBottom: 14,
        paddingTop: 14,
        fontWeight: 600
    },
    launchCodeListRightDesc: {
        fontFamily: 'Hind',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: 0,
        display: 'inline-block',
        float: 'right;',
        paddingTop: 15
    },
    collapseListRightDesc: {
        fontFamily: 'Hind',
        fontSize: 14,
        fontStyle: 'normal',
        letterSpacing: 0,
        float: 'right',
        paddingTop: 4,
        width: '17%'
    },
    closeButton: {
        width: 14,
        height: 14,
        padding: 0,
        position: 'absolute',
        top: 26,
        right: 24
    },
    contentDiv: {
        background: 'white',
        paddingTop: 0,
        paddingBottom: 0
    },
    menuItem: {
        color: 'black',
        fontSize: 14,
        height: 12,
        marginLeft: -27
    },
    listItem: {
        borderBottom: '1px solid #E8EEFA',
        paddingBottom: 26,
        paddingTop: 15,
        height: 45
    },
    iconButton: {
        '&.MuiIconButton-root': {
            color: '#05112A'
        },
        fontSize: 9,
        paddingTop: 11
    },
    collapseListItem: {
        background: '#F7F9FD',
        border: '1px solid #E8EEFA',
        height: 45
    },
    createBy: {
        display: 'inline-block',
        fontSize: 14,
        paddingLeft: 38
    },
    createByDiv: {
        width: '100%'
    }
};

const LaunchCode = ({ classes, open, email, onClose, history }) => {
    const language = Framework.getStoreRegistry().getStore('language');
    const logTimes = JSON.parse(JSON.stringify(history));
    const logLength = logTimes.length;
    const [selectedIndex, setSelectedIndex] = useState('');

    const handleMenuClick = (e, indexValue) => {
        let selectedInd = ''
        logTimes.map((log, index) => {
            if (indexValue === index) {
                selectedInd = index
            }
        })
        if (selectedIndex !== selectedInd) {
            setSelectedIndex(selectedInd);
        } else {
            setSelectedIndex('');
        }
    }

    const getTime = (localtime) => {
        const time = new Date(localtime).toLocaleTimeString('en', {
            timeStyle: 'short',
            hour12: true,
            timeZone: 'EST'
        });
        return `${time} EST`
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="launchcode-modal-title"
                aria-describedby="launchcode-modal-description"
                PaperProps={{
                    style: {
                        height: 611,
                        width: 429,
                        background: '#F7F9FD',
                        paddingBottom: 38
                    },
                }}
                className="dialog-zindex"
            >
                <DialogTitle id="launchcode-modal-title">
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" component="h2" className={classes.launchCodeTitle}>
                        {
                            <FormattedMessage {...language.getText('launchcode.LAUNCHDIALOGTITLE')} />
                        }
                    </Typography>
                </DialogTitle>
                <DialogContent id="launchcode-modal-description">
                    <div >
                        <Typography className={classes.launchCodeDesc}>
                            {
                                <FormattedMessage {...language.getText('launchcode.ACCOUNTEMAIL')} />
                            }
                        </Typography>
                        <Typography className={classes.launchCodeRightDesc}>
                            {email}
                        </Typography>
                    </div>
                    <Divider className={classes.divider} />
                    <div>
                        <Typography className={classes.launchCodeListDesc}>
                            {
                                <FormattedMessage {...language.getText('launchcode.CODEINLAST')} />
                            }
                        </Typography>
                        <Typography className={classes.launchCodeListRightDesc}>
                            {logLength}
                        </Typography>
                    </div>
                    <List
                        id="log-list"
                        role="menu"
                        className={classes.contentDiv}
                    >
                        {
                            logTimes.map((logTime, index) => (
                                <>
                                    <ListItem
                                        id={logTime.deviceId}
                                        key={logTime.deviceId}
                                        className={classes.listItem}
                                        button
                                        onClick={(e) => {handleMenuClick(e, index)}}
                                    >
                                        <ListItemIcon >
                                            <IconButton className={classes.iconButton} >
                                                {selectedIndex === index ? <CollapseIcon /> : <ExpandIcon />}
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText >
                                            <Typography className={classes.menuItem}>
                                                {
                                                    <FormattedMessage {...language.getText('launchcode.TIMESENT')} />
                                                }
                                                {getTime(logTime.serverUpdatedDateTime)}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <Collapse
                                        in={selectedIndex === index}
                                        timeout='auto'
                                        unmountOnExit
                                    >
                                        <List
                                            component='li'
                                            className={classes.launchList}
                                        >
                                            <ListItem
                                                id={logTime.deviceId}
                                                key={logTime.deviceId}
                                                className={classes.collapseListItem}
                                            >
                                                <div className={classes.createByDiv}>
                                                    <Typography className={classes.createBy}>
                                                        {
                                                            <FormattedMessage {...language.getText('launchcode.CREATEBY')} />
                                                        }
                                                    </Typography>
                                                    <Typography className={classes.collapseListRightDesc}>
                                                        {logTime.isImpersonated ? 'CS Team' : 'User'}
                                                    </Typography>
                                                </div>
                                            </ListItem>
                                            <ListItem
                                                id={logTime.deviceId}
                                                key={logTime.deviceId}
                                                className={classes.collapseListItem}
                                            >
                                                <div className={classes.createByDiv}>
                                                    <Typography className={classes.createBy}>
                                                        {
                                                            <FormattedMessage {...language.getText('launchcode.sentvia')} />
                                                        }
                                                    </Typography>
                                                    <Typography className={classes.collapseListRightDesc}>
                                                        {logTime.deliveryChannel}
                                                    </Typography>
                                                </div>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </>
                            ))
                        }
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(LaunchCode);