/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Phonenumber textfield
 *
 * @file TeleInput.jsx
 * @author Poornima N
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Framework from '@greenville/framework';
import { MenuItem, Select, TextField } from '@material-ui/core';
import USFlag from './icons/Flags/USFlag';
import { StylesProvider } from "@material-ui/core/styles";
import './OTPDialog.scss';

const Usicon = [{
  flag: <USFlag />, countryCode: '+1', countryName: 'United States'
}]
const PhoneNumberInput = ({ isValid, isOtpSent, onTextChange, phoneNo }) => {
  const language = Framework.getStoreRegistry().getStore('language');
  let formattedPhoneNumber = '';
  let countryCode = '';
  if (phoneNo) {
    const phoneNumber = (phoneNo.split(' ')[1]).replaceAll('-', '');
    formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    countryCode = phoneNo.split(' ')[0];
  }
  const [country, setCountry] = useState(phoneNo ? countryCode : '+1');
  const [phoneInput, setPhoneInput] = useState(phoneNo ? formattedPhoneNumber : '');
  const [errorMessage, setErrorMessage] = React.useState("");
  const [clear, setClear] = React.useState(false);
  const [successMessage, setSucessMessage] = useState("");
  const [otpClear, setOtpClear] = React.useState(false);

  useEffect(() => {
    // Set errorMessage only if phonenumber not valid
    if (!isValid) {
      setErrorMessage(
        <FormattedMessage {...language.getText('textmessage.INVALID_NUMBER')} />
      );
    }

    if (isOtpSent) {
      setSucessMessage(
        <FormattedMessage {...language.getText('textmessage.SUCCESS_MESSAGE')} />
      );
    }
  }, [isValid, isOtpSent]);

  useEffect(() => {
    // Set empty erroMessage only if we change
    // and errorMessage is not empty.
    // avoids setting empty errorMessage if the errorMessage is already empty
    if (!isValid && errorMessage) {
      setErrorMessage("");
      setClear(true);
    }
    if (isOtpSent && successMessage) {
      setSucessMessage("");
      setOtpClear(true);
    }
  }, [phoneInput]);

  const handleChange = (e) => {
    setCountry(e.target.value)
  }

  const handleChangeInput = (e) => {
    let inputvalue = e.target.value.replace(/[^\d]/g, '');
    if (inputvalue.length < 4) {
      setPhoneInput(inputvalue);
    }
    else if (inputvalue.length < 7) {
      setPhoneInput(`(${inputvalue.slice(0, 3)}) ${inputvalue.slice(3)}`);
    }
    else {
      setPhoneInput(`(${inputvalue.slice(0, 3)}) ${inputvalue.slice(3, 6)}-${inputvalue.slice(6, 10)}`);
    }
    onTextChange(country, inputvalue)
  };
  const handleRenderValue = (option) => {
    const items = Usicon.filter((item) => item.countryCode === option);
    return (
      <span>
        {items[0].flag} {items[0].countryCode}
      </span>
    );
  }
  const MenuProps = {
    disablePortal: true,
    PaperProps: {
      style: {
        width: '219px',
        marginTop: '34px',
        marginLeft: '-13px',
        borderRadius: '4px',
        border: '1px solid #878D99',
        background: '#F7F9FD',
        boxShadow: 'none'
      }
    },
    MenuListProps: {
      disablePadding: true
    }
  };
  return (
    <StylesProvider injectFirst>
       <div className={(isValid || clear) ? `countryContainer ${(isOtpSent && !otpClear) ? 'sentOtp' : ''}` : "invalidCountryContainer"}>
        <Select
          labelId="county-code-select"
          id="country-select"
          disableUnderline
          value={country}
          label="country"
          displayEmpty
          onChange={handleChange}
          renderValue={handleRenderValue}
          classes={{
            root: "phoneSelect",
            icon: "phoneSelectIcon"
          }}
          MenuProps={MenuProps}
        >
          {
            Usicon.map(res => {
              return <MenuItem label={res.countryName} value={res.countryCode} classes={{ root: "menuItemRoot", selected: "selectRoot" }}>
                <div className="menuItemClass" >
                  {res.flag}
                  <div className="menuItemText">{res.countryName}</div>
                  <div className="menuItemText">{res.countryCode}</div>
                </div></MenuItem>
            })
          }
        </Select>
        <hr className="selectHr" />
        <TextField placeholder="(201) 555-5555" className="selectInput" id="standard-basic" variant="standard" autoFocus value={phoneInput} fullWidth onChange={handleChangeInput} inputProps={{ maxLength: 14 }} InputProps={{ disableUnderline: true }} />
        {(!isValid || isOtpSent) &&
          <div className={isOtpSent ? 'successHelperText' : "errorHelperText"}>
            {isOtpSent ? successMessage : errorMessage}
          </div>
        }
      </div>
    </StylesProvider>
  );
}
export default PhoneNumberInput;
