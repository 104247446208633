/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to users last location
 *
 * @file LastLocation.js
 * @author Infant Diana Sebastian
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';

import { ChannelLocation } from './ChannelLocation';
import { LASTLOCATION_DATA_FETCHED } from '../../../common/constants';

/**
 * A mobx model for location details
 *
 */
const LocationDetails = types.model(
  'LocationDetails',
  {
    id: types.maybeNull(types.string),
    activity: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    displayTitle: types.maybeNull(types.string),
    updatedTime: types.maybeNull(types.Date),
    offset: types.maybeNull(types.string),
    audioIndex: types.maybeNull(types.string),
    duration: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for location
 *
 */
const Location = types.model(
  'Location',
  {
    id: types.string,
    userId: types.maybeNull(types.string),
    bookId: types.maybeNull(types.string),
    lastActivity: types.maybeNull(types.string),
    tenantId: types.maybeNull(types.string),
    tenantKey: types.maybeNull(types.string),
    createdTime: types.maybeNull(types.Date),
    locations: types.maybeNull(types.array(LocationDetails)),
    courseId: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for last location
 *
 */
const LastLocation = types.model(
  'LastLocation',
  {
    userLocation: types.maybeNull(types.array(Location)),
    userChannelLocation: types.maybeNull(types.array(ChannelLocation)),
    status: types.maybeNull(types.string),
    message: types.maybeNull(types.string)
  }
).actions(self => ({
  set(data) {
    applySnapshot(self, data);
    Framework.getEventManager().publish(LASTLOCATION_DATA_FETCHED, {});
  }
}));

export default LastLocation;
