/* eslint-disable */
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Bread crumbs component
 *
 * @file BackToHome.jsx
 * @author Vignesh Selvam
 */

import React from 'react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import BackIcon from './icons/BackIcon';
import LibraryRightSideTabs from './LibraryRightSideTabs';


const BackToHome = ({
  onBackClick,
  showLibraryTabs,
  showMondlyTabs,
  libraryL2Tabs,
  activeLibraryL2Tab,
  handleLibraryTabClick
}) => {
  const language = Framework.getStoreRegistry().getStore('language');
  const buttonText = (showLibraryTabs || showMondlyTabs)
    ? language.getMessage('libraryL2.back')
    : language.getMessage('device.CLOSE');
  return (
    <div id="backtohome-etext" className="common-wrapper back-etext-alignment">
      <IconButton aria-label={buttonText} id='backToHome' className='backtohome' onClick={() => onBackClick(buttonText)} style={{ backgroundColor: 'transparent' }}>
        <BackIcon type='mojo' />
        <span
          style={{
            fontWeight: '700'
          }}
        >{buttonText}</span>
      </IconButton>

      {showLibraryTabs
        ?
        <LibraryRightSideTabs
          libraryL2Tabs={libraryL2Tabs}
          activeLibraryL2Tab={activeLibraryL2Tab}
          handleLibraryTabClick={handleLibraryTabClick}
        />
        :
        null
      }
    </div>
  );
};

BackToHome.defaultProps = {
  onBackClick: () => { },
  showLibraryTabs: false,
  showMondlyTabs: false,
  libraryL2Tabs: [],
  activeLibraryL2Tab: 'My Books',
  handleLibraryTabClick: () => { }
};

BackToHome.propTypes = {
  onBackClick: PropTypes.func,
  showLibraryTabs: PropTypes.bool,
  showMondlyTabs: PropTypes.bool,
  libraryL2Tabs: PropTypes.array,
  activeLibraryL2Tab: PropTypes.string,
  handleLibraryTabClick: PropTypes.func
};

export default BackToHome;

