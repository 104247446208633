/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to LdUserHash
 *
 * @file LdUserHash
 * @author Jagathi Chennareddy
 */

import { applySnapshot, types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const LdUserHash = types.model(
  'LdUserHash',
  {
    ldUserHash: types.maybeNull(types.string)
  }
).actions(self => ({
  /**
   * Initiate lite weight user api profile call
   *
   */
  fetch() {
    Framework.getEventManager().publish(constants.LD_USER_HASH_REQUESTED, {});
  },
  /**
   * Apply LD unique user hash value for identify the LD user
   *
   * @param {*} ldUserHash
   */
  set(ldUserHash) {
    applySnapshot(self, { ldUserHash });
    Framework.getEventManager().publish(constants.LD_USER_HASH_FETCHED, {});
  },
  setError(err) {
    console.log(err, 'error in LDHash');
  }
}));

export default LdUserHash;
