/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { makeStyles } from '@material-ui/core';
import CommonStyles from './CommonStyles';
/**
 * TemplateDStyles
 *
 * @file TemplateDStyles.jsx
 * @author Manimaran S
 */

const TemplateDStyles = (styles = {}) => makeStyles({
  popper: {
    zIndex: 1299,
    left: '10rem',
    ...CommonStyles(styles).arrowStyles
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    zIndex: 1299,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  cardMedia: {
    minHeight: 160,
    minWidth: 400,
    height: `${styles.height || 'auto'}`,
    width: `${styles.width || 'auto'}`,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 0.25)',
    ...styles.cardMedia || {}
  },
  iconRoot: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    color: '#000',
    background: '#d9d9d9A6',
    '&:hover': {
      color: '#fff',
      background: '#000'
    },
    '& svg': {
      fontSize: 12
    }
  },
  campaignHoverStyle: {
    cursor: 'pointer'
  },
  contentCardContanier: {
    display: 'flex'
  },
  rootCardContent: {
    padding: '15px !important'
  },
  title: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '16px',
    color: '#fefefe',
    margin: 0
  },
  description: {
    color: '#fefefe',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: 0.24
  },
  contentArea: {
    wordWrap: 'break-word',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '75%'
  },
  positionCenter: {
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    bottom: 'auto',
    right: 'auto'
  },
  positionBottomRight: {
    top: 'auto',
    right: 0,
    left: 'auto',
    bottom: 0
  },
  positionTopRight: {
    top: 0
  },
  buttonContainer: {
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    borderRadius: 40,
    background: 'linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%)',
    width: 'fit-content',
    '& span.MuiButton-label': {
      color: '#FEFEFE',
      fontSize: 14,
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.4px'
    },
    '&:hover, &.Mui-focusVisible': {
      background: 'linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%)'
    },
    '&.Mui-focusVisible': {
      background: 'linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%)',
      border: '2px dashed #DDE3EE',
      boxShadow: '0px 0px 0px 2px #B41863',
      '&:after': {
        border: 'none'
      }
    },
    ...styles.buttonContainer || {}
  }
});
export default TemplateDStyles;
