/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Onboarding
 *
 * @file Onboarding.jsx
 * @author Mohamed yasar arabath M
 */

import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CommonUtils from '../../common/utils/CommonUtils';
import OnboardStyles from './styles/OnboardStyles';
import * as constants from '../../common/constants';

const Marquee = CommonUtils.lazyWithPreload(() => import('@authhome/marquee')
  .then(module => ({ default: module.Marquee })));

const Onboarding = ({
  openOnBoard, onboardSlideData, gotoSlide, callbacks, showCloseButton
}) => {
  const classes = OnboardStyles()();
  const {
    onClose,
    onBackBtnClick,
    onNextBtnClick,
    onSlideView,
    onButtonClick,
    onSubStatusCardLinksClick
  } = callbacks;

  return (
    <Suspense fallback={<Fragment />}>
      <Dialog
        scroll="body"
        open={openOnBoard}
        maxWidth={false}
        classes={{ root: classes.dialogRoot, paper: classes.paperRoot }}
        id={constants.ONE_READER_MODAL_ONBOARD_POPUP}
      >
        {showCloseButton
          && (
          <IconButton onaria-label="close" color="primary" className={`${classes.iconButton} ${constants.PENDO_IDENTIFICATION_CLASS.ONBOARDING_X_CLOSE}`} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        }
        <Box classes={{ root: classes.root }}>
          <Marquee
            slides={onboardSlideData}
            onClose={onClose}
            onBackBtnClick={onBackBtnClick}
            onNextBtnClick={onNextBtnClick}
            onSlideView={onSlideView}
            onButtonClick={onButtonClick}
            gotoSlide={gotoSlide}
            onSubStatusCardLinksClick={onSubStatusCardLinksClick}
          />
        </Box>
      </Dialog>
    </Suspense>
  );
};

Onboarding.defaultProps = {
  openOnBoard: false,
  onboardSlideData: [],
  gotoSlide: 0,
  showCloseButton: false
};

Onboarding.propTypes = {
  openOnBoard: PropTypes.bool,
  onboardSlideData: PropTypes.array,
  gotoSlide: PropTypes.number,
  callbacks: PropTypes.object.isRequired,
  showCloseButton: PropTypes.bool
};

export default Onboarding;
