/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 **/
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * A React component for  Expand icon.
 *
 * 
 */

const ExpandIcon = () => (
  <SvgIcon width="5" height="5" viewBox="0 0 5 8" fill="#4F5561" fillRule="evenodd" fontSize="inherit">
    <path d="M1.49561 7.7496C1.15347 8.08347 0.598746 8.08347 0.256606 7.7496C-0.0855352 7.41573 -0.0855352 6.87442 0.256606 6.54056L2.88489 4L0.256606 1.45944C-0.0855352 1.12558 -0.0855352 0.584269 0.256606 0.250401C0.598746 -0.0834665 1.15347 -0.0834665 1.49561 0.250401L4.74339 3.39548C5.08554 3.72935 5.08554 4.27065 4.74339 4.60452L1.49561 7.7496Z" />
  </SvgIcon>
);

export default ExpandIcon;
