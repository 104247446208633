/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateE
 *
 * @file TemplateE.jsx
 * @author Manimaran S
 */

import React, { memo, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  IconButton, CardContent, Typography, CardMedia, Button, Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TemplateEStyles from './styles/templateEStyles';
import CommonUtils from '../../../common/utils/CommonUtils';

const TemplateE = ({ data, callbackForBraze }) => {
  const {
    id, cardId, styles, title, description, backgroundColor, backgroundImage, maxCardHeight, action, showDismiss, showDescription
  } = data;
  const {
    onDismiss
  } = callbackForBraze;
  const classes = TemplateEStyles(styles, backgroundColor, maxCardHeight)();
  const isBackgroundImageInList = Array.isArray(backgroundImage);

  /**
   * Function to dismss the campaign
   * @param {*} event
   */
  const dismiss = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onDismiss();
  };

  /**
   * Function to handle campaign click action
   * @param {*} actionData
   */
  const handleCtaClick = (actionData) => {
    const {
      target, launchURL
    } = actionData;
    if (launchURL) {
      window.open(launchURL, target || '_self');
    }
  };

  /**
   * Fucntion to get resolution based image source
   * @returns
   */
  const getSrcBasedOnResolution = () => {
    const currentWidth = window.innerWidth || document.documentElement.clientWidth;
    const currentImgSrc = backgroundImage.findLast(background => background.breakPoint <= currentWidth);

    return (currentImgSrc && currentImgSrc.imageURL) || null;
  };

  /**
   * Function to get campaign background image
   * @returns
   */
  const getBackgroundImage = () => (isBackgroundImageInList ? getSrcBasedOnResolution() : backgroundImage);
  const [imgSrc, setImgSrc] = useState(getBackgroundImage());

  /**
   * Function to handle resize
   */
  function handleResize() {
    if (backgroundImage && isBackgroundImageInList) {
      setImgSrc(getSrcBasedOnResolution());
    }
  }

  useEffect(() => {
    window.addEventListener('resize', CommonUtils.debounce(handleResize));

    return () => window.removeEventListener('resize', CommonUtils.debounce(handleResize));
  }, []);


  return (
    data && (
      <Box
        className={classes.cardWrapper}
        id={id}
        data-card-id={cardId}
      >
        {showDismiss
          && (
          <IconButton onClick={event => dismiss(event)} size="small" classes={{ root: classes.iconRoot }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )
        }
        <CardMedia
          classes={{ root: classes.cardMedia }}
          image={imgSrc}
        >
          <CardContent className={classes.rootCardContent}>
            <div className={classes.contentArea}>
              <div className={classes.copyTextWrapper}>
                {title && (
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                  {ReactHtmlParser(title)}
                </Typography>
                )}
                {(showDescription && description) && (
                <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
                  {ReactHtmlParser(description)}
                </Typography>
                )}
              </div>
              <div className={classes.actionBtnWrapper}>
                {action && action.map((actionData, index) => (
                  <Button
                    classes={{ root: classes.primaryCtaBtn }}
                    aria-label={`${actionData.text}`}
                    role="link"
                    key={index}
                    onClick={() => handleCtaClick(actionData)}
                  >
                    {actionData.text}
                  </Button>
                ))}
              </div>
            </div>
          </CardContent>
        </CardMedia>
      </Box>
    )
  );
};

TemplateE.defaultProps = {
  data: {
    description: null,
    styles: {},
    backgroundImage: '',
    action: [],
    showDismiss: false,
    showDescription: false
  },
  callbackForBraze: {
    onDismiss: () => {}
  }
};

TemplateE.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    styles: PropTypes.object,
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    maxCardHeight: PropTypes.string,
    action: PropTypes.array,
    showDismiss: PropTypes.bool,
    showDescription: PropTypes.bool
  }).isRequired,
  callbackForBraze: PropTypes.shape({
    onDismiss: PropTypes.func
  }).isRequired
};

export default memo(TemplateE);
