/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework, { Utils } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CourseInfoItem from './CourseInfoItem';

/**
* Author Saravanan Kandasamy
*/
const CourseListData = types.model(
  'CourseListData',
  {
    items: types.array(CourseInfoItem)
  }
).views(self => ({
  getData(courseId) {
    const course = Framework.getStoreRegistry().getStore('course');
    const courseData = {
      contextId: courseId,
      courseName: course.getProduct().title
    };
    if (courseId) {
      let index = 0;
      const courseInfo = [...self.items];
      Utils.treeWalk(courseInfo, null, '', -1, (node) => {
        index += 1;
        if (node.contextId === courseId) {
          courseData.currentCourseInfo = node;
          courseInfo.splice(index - 1, 1);
          courseData.annotationCount = node.annotationExists;
          courseData.courseInfo = courseInfo;
          return false; // false stops the iteration
        }
        return true; // we need to continue to walk the tree since we did not find a match
      });
    }
    return courseData;
  }
})).actions(self => ({
  fetch(productId) {
    const course = Framework.getStoreRegistry().getStore('course');
    Framework.getEventManager().publish(constants.COURSE_COLLECTION_REQUESTED, {
      courseId: course.id,
      productId: productId || course.getProduct().id,
      courseName: course.getProduct().title
    });
  },
  set(courseItems) {
    applySnapshot(self, courseItems);
  }
}));
export default CourseListData;
