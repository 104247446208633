/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * JSON Constants used for GA Events
 *
 * @file GAConstants.js
 * @since 3.30.0
 */
/**
 * Constant JSON for GA Events
 */
export const SCRUBBER_HISTORY = 'scrubberHistory';
export const SCRUBBER_BOOKMARK = 'scrubberBookmark';
export const SCRUBBER_FORWARD = 'scrubberForward';
export const SCRUBBER_BACK = 'scrubberBackward';
export const SCRUBBER_GOTO = 'scrubberGoToPage';
export const GOTO_PAGE = 'goToPage';
export const CONTENT_SELECTOR_SEARCH_EVENT = 'contentSelectorEventSearch';
export const CONTENT_CHANNEL_EVENT = 'contentChannelIconEvent';
export const CHANNEL_ICON_CLICK = 'contentChannelIconEventClick';
export const ADD_BOOKMARK_EVENT = 'addBookmark';
export const NAVIGATE_BOOKMARK = 'navigateRecentBookmark';
export const HISTORY = 'History';
export const CLEAR_ALL_RECENT = 'Clear All Recent Locations';
export const CLEAR_ALL_RECENT_LOCATIONS = 'clearallrecentlocations';
export const DRAWER_NOTEBOOK_EXPAND = 'drawerNotebookExpand';
export const AUDIO_CLOSE = 'audiobookClose';
export const STUDY_CLOSE = 'studyClose';
export const STUDY_EVENT = 'flashcardevent';
export const STUDY_EVENT_CATEGORY = 'Flashcard';
export const STUDY_CLOSE_LABEL = 'X';
export const FLASHCARD_DISMISS_STUDY_DECK = 'Flashcard Dismiss Study Deck';
export const FLASHCARD_DISMISS_STUDY_CARDS = 'Flashcard Dismiss Study Cards';
export const DECK_LIST_VIEW = 'decklistview';
export const DRAWER_HIDE = 'drawerHide';
export const PREFERNCE_OPEN = 'preferenceOpen';
export const PREFERNCE_CLOSE = 'preferenceClose';
export const DRAWER_EXPAND = 'drawerExpandedMenu';
export const DISMISS_SEARCH = 'dismisssearch';
export const X = 'X';
export const CONTENT_SEARCH = 'Content Search';
export const CLOSE_SEARCH = 'Close eReader Search';
export const UNDO_REMOVE = 'undoRemoveBookmark';
export const NAVIGATE_PAGE = 'navigateTopage';
export const SOCIAL_EVENT = 'socialevent';
export const REMOVE_ALL_BOOKMARK_EVENT = 'removeAllBookmark';
export const REMOVE_BOOKMARK_EVENT = 'removeBookmark';
export const SOCIAL = 'Social';
export const SOCIAL_ENTRY_ACTION = 'Click Entry Point';
export const LAUNCH_HOME = 'launchHome';
export const LAUNCH_HOME_ACTION = 'launch home';
export const LAUNCH_HOME_LABEL = 'Launch from: Navigation';
export const LAUNCH_HOME_CATEGORY = 'Home';
export const LAUNCH_AUDIO = 'audioevent';
export const LAUNCH_AUDIO_CATEGORY = 'Audio Book';
export const LAUNCH_AUDIO_ACTION = 'Launch Audiobook';
export const LAUNCH_SCRUBBER = 'launchScrubber';
export const LAUNCH_SCRUBBER_CATEGORY = 'Scrubber';
export const LAUNCH_SCRUBBER_ACTION = 'Launch scrubber';
export const LAUNCH_SEARCH = 'LaunchSearch';
export const LAUNCH_SEARCH_CATEGORY = 'Search';
export const LAUNCH_SEARCH_ACTION = 'Launch Search';
export const LAUNCH_CHANNELS = 'channelsevent';
export const LAUNCH_CHANNELS_CATEGORY = 'Channels';
export const LAUNCH_CHANNELS_ACTION = 'Launch Channels Miniplayer';
export const LAUNCH_STUDY_CHANNEL = 'ereaderevent';
export const LAUNCH_STUDY_CHANNEL_CATAEGORY = 'eReader';
export const LAUNCH_STUDY_CHANNEL_ACTION = 'eReader AB Test Experience';
export const LAUNCH_STUDY_EVENT_LABEL = 'Redirect to Channels after x videos';
export const LAUNCH_STUDY = 'flashcardevent';
export const LAUNCH_STUDY_CATEGORY = 'Flashcard';
export const LAUNCH_STUDY_ACTION = 'Launch Flashcard';
export const LAUNCH_NOTEBOOK = 'notebookevent';
export const LAUNCH_NOTEBOOK_CATEGORY = 'Notebook';
export const LAUNCH_NOTEBOOK_ACTION = 'Launch Notebook';
export const SCRUBBER_LABEL_EVENT = 'scrubber';
export const SCRUBBER_CATEGORY_HISTORY = 'Scrubber';
export const SCRUBBER_ACTION_HISTORY = 'Navigate Via Recent History Icon';
export const SCRUBBER_ACTION_BOOKMARK = 'Navigate Via Bookmark';
export const SCRUBBER_ACTION_GOTO = 'Navigate Via Page Number';
export const SCRUBBER_ACTION_BACKWARD = 'Backward';
export const SCRUBBER_ACTION_FORWARD = 'Forward';
export const NAVIGATION = 'Navigation';
export const GO_TO_ACTION = 'Enter Page Number';
export const REMOVE_ALL_BOOKMARK = 'Remove All Bookmark';
export const REMOVE_BOOKMARK = 'Remove Individual Bookmark';
export const UNDO_BOOKMARK = 'Undo Remove Bookmark';
export const ADD_BOOKMARK = 'Add Bookmark';
export const BOOKMARK_CATEGORY = 'TOC - Bookmarks';
export const BOOKMARK_EVENT = 'tocbookmarks';
export const TOC_CONTENTS_EVENT = 'toccontentsnavigation';
export const TOC_CONTENTS_CATEGORY = 'TOC - Contents';
export const TOC_CONTENTS_ACTION = 'Navigate to Page';
export const TOC_HISTORY_CATEGORY = 'TOC - History';
export const TOC_HISTORY_EVENT = 'tochistorynavigation';
export const TOC_HISTORY_ACTION = 'Navigate From Recent TOC';
export const PREFERENCES_EVENT_CLOSE = 'displaypreference_close';
export const PREFERENCES_EVENT_OPEN = 'displaypreference_open';
export const PREFERENCES_EVENT = 'Display Preferences';
export const OPEN = 'Open';
export const OPEN_DISPLAY_PREFERENCE = 'Launch Display Preferences Menu';
export const CLOSE = 'Close';
export const CLOSE_DISPLAY_PREFERENCE = 'Close Display Preferences Menu';
export const HIDE = 'Hide';
export const DRAWER_HIDE_ACTION = 'Hide Drawer';
export const DRAWER_EVENT = 'drawerevent';
export const DRAWER_LABEL = 'Drawer';
export const DRAWER_EXPAND_ACTION = 'Expand Drawer';
export const DRAWER_EXPAND_LABEL = 'Expand Menu Options';
export const AUDIO_CLOSE_ACTION = 'Close Playlist';
export const AUDIO_EVENT = 'audioevent';
export const AUDIO_EVENT_CATEGORY = 'Audio Book';
export const NOTEBOOK_EVENT = 'notebookevent';
export const NOTEBOOK_EVENT_CATEGORY = 'Notebook';
export const NOTEBOOK_EVENT_ACTION = 'Resize Window';
export const NOTEBOOK_EXPAND_ACTION = 'Resize Type: Expand';
export const EXPAND = 'Expand';
export const CONTENT_SELECTOR_EVENT = 'contentselectorevent';
export const CONTENT_SELECTOR = 'Content Selector';
export const CHANNEL_ICON_A_CLICKED = 'ChannelIconAClicked';
export const CHANNEL_ICON_B_CLICKED = 'ChannelIconBClicked';
export const DRAWER_CHANNEL_ICON = 'Drawer Icon 7';
export const DRAWER_AB_CHANNEL_ICON = 'Drawer Icon 2';
export const CONTENT_SEARCH_SELECTION_ACTION = 'Execute eReader Search from Content Selector';
export const OPEN_CHANNEL_ACTION = 'Open Miniplayer from Content Margin';
export const MARGIN = 'Margin';
export const CLICK = 'Click';
export const HOVER = 'Hover';
export const LAUNCH_TOC_EVENT = 'launchTOC';
export const LAUNCH_TOC = 'Launch TOC';
export const TOC = 'TOC';
export const BOT_EVENTS = 'BOT_EVENTS';
export const GPT = 'GPT';
export const MINI_PLAYER_VIDEO_CLICKED = 'MiniPlayerVideoClicked';
export const LOCATION_IN_APP_TOC = 'TOC';
export const LOCATION_IN_APP_FOOTER = 'footer';
export const LOCATION_IN_APP_PLAYLIST = 'Playlist';
export const CHANNELS_ICON_BACKGROUND_COLOR = 'Channels icon background color';
export const TRANSLATE_CONTENTSELECTOR = 'Content Selector';
export const CLICK_TRANSLATE_LANGUAGE = 'AI Generated Response';
export const GAEvents = {
  [CLEAR_ALL_RECENT_LOCATIONS]: {
    event_name: CLEAR_ALL_RECENT_LOCATIONS,
    event: CLEAR_ALL_RECENT_LOCATIONS,
    event_category: HISTORY,
    event_action: CLEAR_ALL_RECENT,
    event_label: CLEAR_ALL_RECENT,
    event_value: CLEAR_ALL_RECENT
  },
  [LAUNCH_TOC_EVENT]: {
    event_name: LAUNCH_TOC_EVENT,
    event: LAUNCH_TOC_EVENT,
    event_category: TOC,
    event_action: LAUNCH_TOC,
    event_label: LAUNCH_HOME_LABEL,
    event_value: TOC
  },
  [SOCIAL_EVENT]: {
    event_name: SOCIAL_EVENT,
    event: SOCIAL_EVENT,
    event_category: SOCIAL,
    event_action: SOCIAL_ENTRY_ACTION,
    event_label: '',
    event_value: null
  },
  [LAUNCH_HOME]: {
    event_name: LAUNCH_HOME,
    event: LAUNCH_HOME,
    event_category: LAUNCH_HOME_CATEGORY,
    event_action: LAUNCH_HOME_ACTION,
    event_label: LAUNCH_HOME_LABEL,
    event_value: LAUNCH_HOME_CATEGORY
  },
  [LAUNCH_AUDIO]: {
    event_name: LAUNCH_AUDIO,
    event: LAUNCH_AUDIO,
    event_category: LAUNCH_AUDIO_CATEGORY,
    event_action: LAUNCH_AUDIO_ACTION,
    event_label: null,
    event_value: null
  },
  [LAUNCH_SCRUBBER]: {
    event_name: LAUNCH_SCRUBBER,
    event: LAUNCH_SCRUBBER,
    event_category: LAUNCH_SCRUBBER_CATEGORY,
    event_action: LAUNCH_SCRUBBER_ACTION,
    event_label: LAUNCH_HOME_LABEL,
    event_value: LAUNCH_SCRUBBER_CATEGORY
  },
  [LAUNCH_SEARCH]: {
    event_name: LAUNCH_SEARCH,
    event: LAUNCH_SEARCH,
    event_category: LAUNCH_SEARCH_CATEGORY,
    event_action: LAUNCH_SEARCH_ACTION,
    event_label: LAUNCH_HOME_LABEL,
    event_value: LAUNCH_SEARCH_CATEGORY
  },
  [LAUNCH_CHANNELS]: {
    event_name: LAUNCH_CHANNELS,
    event: LAUNCH_CHANNELS,
    event_category: LAUNCH_CHANNELS_CATEGORY,
    event_action: LAUNCH_CHANNELS_ACTION,
    event_label: null,
    event_value: null
  },
  [LAUNCH_STUDY_CHANNEL]: {
    event_name: LAUNCH_STUDY_CHANNEL,
    event: LAUNCH_STUDY_CHANNEL,
    event_category: LAUNCH_STUDY_CHANNEL_CATAEGORY,
    event_action: LAUNCH_STUDY_CHANNEL_ACTION
  },
  [LAUNCH_STUDY]: {
    event_name: LAUNCH_STUDY,
    event: LAUNCH_STUDY,
    event_category: LAUNCH_STUDY_CATEGORY,
    event_action: LAUNCH_STUDY_ACTION,
    event_label: LAUNCH_HOME_LABEL,
    event_value: null
  },
  [LAUNCH_NOTEBOOK]: {
    event_name: LAUNCH_NOTEBOOK,
    event: LAUNCH_NOTEBOOK,
    event_category: LAUNCH_NOTEBOOK_CATEGORY,
    event_action: LAUNCH_NOTEBOOK_ACTION,
    event_label: null,
    event_value: null
  },
  [SCRUBBER_HISTORY]: {
    event_name: SCRUBBER_LABEL_EVENT,
    event: SCRUBBER_LABEL_EVENT,
    event_category: SCRUBBER_CATEGORY_HISTORY,
    event_action: SCRUBBER_ACTION_HISTORY,
    event_value: null
  },
  [SCRUBBER_BOOKMARK]: {
    event_name: SCRUBBER_LABEL_EVENT,
    event: SCRUBBER_LABEL_EVENT,
    event_category: SCRUBBER_CATEGORY_HISTORY,
    event_action: SCRUBBER_ACTION_BOOKMARK,
    event_value: null
  },
  [SCRUBBER_FORWARD]: {
    event_name: SCRUBBER_LABEL_EVENT,
    event: SCRUBBER_LABEL_EVENT,
    event_category: SCRUBBER_CATEGORY_HISTORY,
    event_action: SCRUBBER_ACTION_FORWARD,
    event_value: null
  },
  [SCRUBBER_BACK]: {
    event_name: SCRUBBER_LABEL_EVENT,
    event: SCRUBBER_LABEL_EVENT,
    event_category: SCRUBBER_CATEGORY_HISTORY,
    event_action: SCRUBBER_ACTION_BACKWARD,
    event_value: null
  },
  [SCRUBBER_GOTO]: {
    event_name: SCRUBBER_LABEL_EVENT,
    event: SCRUBBER_LABEL_EVENT,
    event_category: SCRUBBER_CATEGORY_HISTORY,
    event_action: SCRUBBER_ACTION_GOTO,
    event_value: null
  },
  [GOTO_PAGE]: {
    event_name: 'navigation',
    event: 'navigation',
    event_category: NAVIGATION,
    event_action: GO_TO_ACTION,
    event_value: null
  },
  [REMOVE_ALL_BOOKMARK_EVENT]: {
    event_name: BOOKMARK_EVENT,
    event: BOOKMARK_EVENT,
    event_category: BOOKMARK_CATEGORY,
    event_action: REMOVE_ALL_BOOKMARK,
    event_value: null
  },
  [REMOVE_BOOKMARK_EVENT]: {
    event_name: BOOKMARK_EVENT,
    event: BOOKMARK_EVENT,
    event_category: BOOKMARK_CATEGORY,
    event_action: REMOVE_BOOKMARK,
    event_value: null
  },
  [UNDO_REMOVE]: {
    event_name: BOOKMARK_EVENT,
    event: BOOKMARK_EVENT,
    event_category: BOOKMARK_CATEGORY,
    event_action: UNDO_BOOKMARK,
    event_value: null
  },
  [NAVIGATE_PAGE]: {
    event_name: TOC_CONTENTS_EVENT,
    event: TOC_CONTENTS_EVENT,
    event_category: TOC_CONTENTS_CATEGORY,
    event_action: TOC_CONTENTS_ACTION
  },
  [ADD_BOOKMARK_EVENT]: {
    event_name: BOOKMARK_EVENT,
    event: BOOKMARK_EVENT,
    event_category: BOOKMARK_CATEGORY,
    event_action: ADD_BOOKMARK,
    event_value: null
  },
  [NAVIGATE_BOOKMARK]: {
    event_name: TOC_HISTORY_EVENT,
    event: TOC_HISTORY_EVENT,
    event_category: TOC_HISTORY_CATEGORY,
    event_action: TOC_HISTORY_ACTION,
    event_value: null
  },
  [PREFERNCE_CLOSE]: {
    event_name: PREFERENCES_EVENT_CLOSE,
    event: PREFERENCES_EVENT_CLOSE,
    event_category: PREFERENCES_EVENT,
    event_action: CLOSE_DISPLAY_PREFERENCE,
    event_value: null,
    event_label: null
  },
  [PREFERNCE_OPEN]: {
    event_name: PREFERENCES_EVENT_OPEN,
    event: PREFERENCES_EVENT_OPEN,
    event_category: PREFERENCES_EVENT,
    event_action: OPEN_DISPLAY_PREFERENCE,
    event_value: null,
    event_label: null
  },
  [DRAWER_HIDE]: {
    event_name: DRAWER_EVENT,
    event: DRAWER_EVENT,
    event_category: DRAWER_LABEL,
    event_action: DRAWER_HIDE_ACTION,
    event_value: HIDE,
    event_label: HIDE
  },
  [AUDIO_CLOSE]: {
    event_name: AUDIO_EVENT,
    event: AUDIO_EVENT,
    event_category: AUDIO_EVENT_CATEGORY,
    event_action: AUDIO_CLOSE_ACTION,
    event_value: CLOSE,
    event_label: CLOSE
  },
  [STUDY_CLOSE]: {
    event_name: STUDY_EVENT,
    event: STUDY_EVENT,
    event_category: STUDY_EVENT_CATEGORY,
    event_value: null,
    event_label: STUDY_CLOSE_LABEL
  },
  [DRAWER_NOTEBOOK_EXPAND]: {
    event_name: NOTEBOOK_EVENT,
    event: NOTEBOOK_EVENT,
    event_category: NOTEBOOK_EVENT_CATEGORY,
    event_action: NOTEBOOK_EVENT_ACTION,
    event_value: EXPAND,
    event_label: NOTEBOOK_EXPAND_ACTION
  },
  [CONTENT_SELECTOR_SEARCH_EVENT]: {
    event_name: CONTENT_SELECTOR_EVENT,
    event: CONTENT_SELECTOR_EVENT,
    event_category: CONTENT_SELECTOR,
    event_action: CONTENT_SEARCH_SELECTION_ACTION,
    event_label: 'pageId'
  },
  [CONTENT_CHANNEL_EVENT]: {
    event_name: CONTENT_SELECTOR_EVENT,
    event: CONTENT_SELECTOR_EVENT,
    event_category: CONTENT_SELECTOR,
    event_action: OPEN_CHANNEL_ACTION
  },
  [CHANNEL_ICON_CLICK]: {
    event_name: CONTENT_SELECTOR_EVENT,
    event: CONTENT_SELECTOR_EVENT,
    event_category: CONTENT_SELECTOR,
    event_action: OPEN_CHANNEL_ACTION
  },
  [DRAWER_EXPAND]: {
    event_name: DRAWER_EVENT,
    event: DRAWER_EVENT,
    event_category: DRAWER_LABEL,
    event_label: DRAWER_EXPAND_LABEL,
    event_action: DRAWER_EXPAND_ACTION,
    event_value: EXPAND
  },
  [BOT_EVENTS]: {
    event_category: GPT
  },
  [DISMISS_SEARCH]: {
    event_name: DISMISS_SEARCH,
    event: DISMISS_SEARCH, // GA event and event_name should be same
    event_category: CONTENT_SEARCH,
    event_label: X,
    event_action: CLOSE_SEARCH,
    event_value: null
  },
  [TRANSLATE_CONTENTSELECTOR]: {
    event_action: CLICK_TRANSLATE_LANGUAGE,
    event_category: TRANSLATE_CONTENTSELECTOR,
    event_value: null,
    event: CONTENT_SELECTOR_EVENT,
    event_name: CONTENT_SELECTOR_EVENT
  }
};
