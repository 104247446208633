/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Sarathkumar Venkatachalam
*/

import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class ProductTokenService extends BaseService {
  constructor() {
    super(constants.PRODUCT_TOKEN_REQUESTED);
  }

  mapResponse(response) {
    const { name, value } = response.data;
    const index1 = value.indexOf('=');
    const index2 = value.indexOf('~');
    const expirationTime = (index1 >= 0 && index2 > index1) ? value.substring(index1 + 1, index2) * 1000 : 0;
    return {
      id: this.id,
      name,
      value,
      expirationTime,
      verified: true
    };
  }

  handleEvent(eventName, event) {
    this.id = event.id; // TODO: This is not a great way to pass data to response handler. Need framework change.
    return this.getClient('marin').get(`/products/${event.id}/token`);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    const expireTime = CommonUtils.getExpireTime();
    window.document.cookie = `etext-cdn-token=${response.data.value}; path=/; domain=.pearson.com; Expires=${expireTime}`;
    Framework.getStoreRegistry().getStore('productToken').set(this.mapResponse(response));    
  }
  
  handleError(error) { // eslint-disable-line class-methods-use-this
    console.log(error, 'error');
    const customFields = { event_value: error.response.data.message };
    CommonUtils.dispatchGaEvent(constants.LOAD_ERROR, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_NAME, constants.LOAD_ERROR_LABEL, customFields);
  }
}
