/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Book collection service
 *
 * @file BookCollectionService.js
 * @author Gladson Samuel S
 */


import Framework, { BaseService } from '@greenville/framework';
import {
  BOOK_COLLECTION_SERVICE_FAILED,
  BOOK_COLLECTION_SERVICE_FETCHED,
  BOOK_COLLECTION_SERVICE_REQUESTED,
  HTTP_REQUEST_METHODS
} from '../../../common/constants';

export default class BookCollectionService extends BaseService {
  constructor() {
    super(BOOK_COLLECTION_SERVICE_REQUESTED, 'bookcollectionstatus');
  }

  /**
   * Handles book collection service event
   *
   * @param {*} eventName
   * @param {*} eventData
   * @returns
   */
  handleEvent(eventName, eventData) {
    const { method, payload } = eventData;
    let response = null;

    if (payload && payload.length > 0) {
      switch (method) {
        case HTTP_REQUEST_METHODS.POST:
          response = this.getClient('marin').post('/user/bookcollection', payload);
          break;
        case HTTP_REQUEST_METHODS.DELETE:
          response = this.getClient('marin').delete('/user/bookcollection', { data: payload });
          break;
        default:
          break;
      }
    }

    return response;
  }

  /**
   * Handles book collection service response
   *
   * @param {*} response
   */
  // eslint-disable-next-line class-methods-use-this
  handleResponse(response, event) {
    Framework.getEventManager().publish(BOOK_COLLECTION_SERVICE_FETCHED, {
      response: response.data,
      selectedBookIds: event?.payload.map(({ bookId }) => bookId),
      isArchived: event?.method.toLowerCase() === HTTP_REQUEST_METHODS.POST.toLowerCase()
    } || {});
  }

  /**
   * Handles book collection service error
   *
   * @param {*} response
   */
  // eslint-disable-next-line class-methods-use-this
  handleError(error) {
    Framework.getEventManager().publish(BOOK_COLLECTION_SERVICE_FAILED, error);
    // eslint-disable-next-line no-console
    console.log(error, 'Book collection service error');
  }
}
