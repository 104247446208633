/*
* PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
* Copyright © 2023 Pearson Education, Inc.
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Pearson Education, Inc. The intellectual and technical concepts contained
* herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
* patent applications, and are protected by trade secret or copyright law.
* Dissemination of this information, reproduction of this material, and copying or distribution of this software
* is strictly forbidden unless prior written permission is obtained
* from Pearson Education, Inc.
*/

/**
 * This will return the provider with LD or not based on integrated MLM launch
 *
 * @file CustomProviderWithOrWithoutLD.jsx
 * @author Mohamed yasar arabath M
 */
import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import CommonUtils from '../utils/CommonUtils';
import env from '../env';


const customProviderWithOrWithoutLD = () => (
  CommonUtils.isIntergratedMLMLaunch()
    ? new Promise(
      resolve => resolve(
        ({ children }) => (<>{ children }</>)
      )
    )
    : asyncWithLDProvider({
      clientSideID: env.LD_CLIENT_SIDE_ID,
      context: {
        kind: 'user',
        key: env.LD_ANONYMOUS_USER_ID,
        anonymous: true
      },
      options: {
        hash: env.LD_ANONYMOUS_HASH,
        sendEventsOnlyForVariation: true
      }
    })
);

export default customProviderWithOrWithoutLD;
