/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Print Message
 *
 * @file PrintMessage.jsx
 * @author Gladson Samuel S
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import PrintMessageStyles from './styles/PrintMessageStyles';

const PrintMessage = ({
  classes,
  title,
  description
}) => (
  <div id="one-reader-print-message" className={classes.container}>
    {title && <Typography variant="h2" classes={{ root: classes.title }}>{title}</Typography>}
    {description && <Typography classes={{ root: classes.description }}>{description}</Typography>}
  </div>
);

PrintMessage.defaultProps = {
  title: '',
  description: ''
};

PrintMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
};

export default withStyles(PrintMessageStyles)(PrintMessage);
