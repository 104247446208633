/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types } from 'mobx-state-tree';

/**
* Author Sarathkumar Venkatachalam
*/

const Subscription = types.model(
  'Subscription',
  {
    allowAccess: types.boolean,
    businessModelCode: types.optional(types.string, ''),
    entitlementTier: types.maybeNull(types.string),
    entitlementLevel: types.maybeNull(types.string),
    endDate: types.optional(types.integer, 0),
    registrationUri: types.optional(types.string, ''),
    startDate: types.optional(types.number, 0),
    startDateUTC: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    subscriptionId: types.optional(types.string, '')
  }
);
export default Subscription;
