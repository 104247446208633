/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { makeStyles } from '@material-ui/core';

/**
 * templateFV1Styles
 *
 * @file templateFV1Styles.jsx
 * @author Manimaran S
 */

const defaultStyles = {
  cardWrapper: {
    position: 'relative',
    borderRadius: 16,
    overflow: 'hidden',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: '#272D39',
    height: 'auto'
  },
  iconRoot: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: '#FEFEFE',
    background: '#272D39',
    padding: 6,
    border: '2px solid #272D39',
    boxShadow: '0px 0px 0px 2px #272D39',
    zIndex: 2,

    '&:hover': {
      color: '#FEFEFE',
      background: '#000'
    },
    '& svg': {
      fontSize: 24
    },
    '&:focus-visible': {
      background: '#272D39',
      color: '#FEFEFE',
      border: '2px dashed #FEFEFE',
      '&::after': {
        border: 'none'
      }
    }
  },
  rootCardContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px !important',
    alignItems: 'center',
    flexDirection: 'column-reverse',

    '@media (min-width: 1024px)': {
      flexDirection: 'row'
    }
  },
  title: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '40px',
    color: '#0CC0AD',
    letterSpacing: '0.4px',
    margin: 0
  },
  subTitle: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '28px',
    color: '#FEFEFE',
    margin: 0,
    letterSpacing: 0.4
  },
  description: {
    color: '#989BA3',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0.24,
    fontFamily: 'TT Commons',
    display: 'flex',
    flexDirection: 'column'
  },
  contentArea: {
    wordWrap: 'break-word',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    justifyContent: 'center',
    flexShrink: 0,
    padding: 16,
    flex: 1,
    width: '100%',
    boxSizing: 'border-box',

    '@media (min-width: 768px)': {
      padding: '64px 32px 32px 32px'
    },
    '@media (min-width: 1024px)': {
      padding: 32
    },
    '@media (min-width: 1920px)': {
      padding: 64
    }
  },
  copyTextWrapper: {
    wordWrap: 'break-word',
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  },
  actionBtnWrapper: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap'
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 232,
    width: 360,
    position: 'relative',

    '@media (min-width: 768px)': {
      height: 312,
      width: 768
    },
    '@media (min-width: 1024px)': {
      height: '100%',
      width: 504
    },
    '@media (min-width: 1280px)': {
      width: 720
    }
  },
  contentImage: {
    top: -128,
    left: -8,
    width: 408,
    height: 374,
    position: 'absolute',

    '@media (min-width: 768px)': {
      top: -224,
      left: 64,
      width: 640,
      height: 586
    },
    '@media (min-width: 1024px)': {
      top: -202,
      width: 606,
      height: 554,
      left: 'auto'
    },
    '@media (min-width: 1280px)': {
      top: -306,
      width: 864,
      height: 790
    }
  }
};

/**
 * Function to override the default styles with braze styles
 * @param {*} brazeStyles
 * @returns
 */
const templateFV1Styles = (brazeStyles = {}) => {
  Object.keys(defaultStyles).forEach((key) => {
    if (brazeStyles[key]) {
      defaultStyles[key] = { ...defaultStyles[key], ...brazeStyles[key] };
    }
  });

  return makeStyles(defaultStyles);
};

export default templateFV1Styles;
