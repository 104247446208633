/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to Channel Mapping For books
 *
 * @file ChannelMaping.js
 * @author N V V S Naidu Kanchumarthi
 */

import { types } from 'mobx-state-tree';


const Info = types.model(
  'Info',
  {
    type: types.maybeNull(types.string),
    number: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    iconName: types.maybeNull(types.string)
  }
);

const Action = types.model(
  'Action',
  {
    type: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    launchURL: types.maybeNull(types.string),
    target: types.maybeNull(types.string),
    hasIgnoreAction: types.maybeNull(types.boolean),
    role: types.maybeNull(types.string),
    ariaLabel: types.maybeNull(types.string),
    ariaDesc: types.maybeNull(types.string)
  }
);


/**
 * A mobx model for Channel
 *
 */
const Banner = types.model(
  'Banner',
  {
    id: types.maybeNull(types.string),
    template: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    secondaryTitle: types.maybeNull(types.string),
    isDismissed: types.optional(types.boolean, false),
    description: types.maybeNull(types.string),
    courseId: types.maybeNull(types.string),
    thumbnail: types.maybeNull(types.string),
    info: types.maybeNull(types.array(Info)),
    action: types.maybeNull(types.array(Action))
  }
);


export default Banner;
