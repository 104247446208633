/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';
/**
 * Mobx State Tree Model for Content Token store
 *
 * @author Hari Gangadharan
 */


const ProductToken = types.model(
  'ProductToken',
  {
    id: types.string,
    name: types.string,
    value: types.string,
    expirationTime: types.optional(types.integer, 0),
    verified: types.boolean
  }
).views(
  self => ({
    isRefreshNeeded() {
      // return true if token is not availabe in browser
      if(!CommonUtils.getCookie(constants.ETEXT_CDN_TOKEN)){
        return true
      }
      // return true if token does not have ebookassets value
      if (!(self.value.includes('eplayer/ebookassets' && 'eplayer/pdfassets'))) {
        return true;
      }
      // refresh token if expiration time is within 5 hours.
      return (self.expirationTime - Date.now() < 5 * constants.MS_IN_AN_HOUR);
    }
  })
).actions(self => ({

  fetch(id) {
    if (self.id !== id || (self.isRefreshNeeded() && !self.loading)) {
      Framework.getEventManager().publish(constants.PRODUCT_TOKEN_REQUESTED, { id });
      self.loading = true; // eslint-disable-line no-param-reassign
    } else {
      self.setVerified(true);
    }
  },

  setVerified(verified) {
    self.verified = verified; // eslint-disable-line no-param-reassign
  },

  set(authData) {
    applySnapshot(self, authData);
  }
}));
export default ProductToken;
