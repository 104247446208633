/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Authhome container
 *
 * @file FloatingButton.jsx
 * @author VIJAYAGOPALAN V
 */
import React from 'react';
import {
  withStyles,
  IconButton,
  Typography,
  Tooltip
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import PropTypes from 'prop-types';
import PathUtils from '../utils/PathUtils';
import * as constants from '../constants';

const styles = {
  mlmFloatingIcon: {
    position: 'fixed',
    right: '50px',
    bottom: '10px',
    width: '40px',
    height: '40px',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
    borderRadius: '50%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  arrow: {
    color: '#05112A'
  },
  tooltip: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    backgroundColor: '#05112A',
    color: '#F7F9FD',
    textAlign: 'center',
    padding: '6px 12px !important',
    borderRadius: '8px !important',
    maxWidth: '120px'
  },
  floatingIconButton: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
};
const FloatingButton = ({ classes }) => {
  const mlmLaunchUrl = () => {
    const pPlusUrl = PathUtils.removeQueryParameterbyName(constants.MLM_LAUNCH_EREADER);
    if (pPlusUrl) {
      window.open(pPlusUrl);
    }
  };

  return (
    <Typography component="div" className={classes.mlmFloatingIcon}>
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Open the reading in the new tab"
        placement="top-end"
        arrow
      >
        <IconButton
          className={classes.floatingIconButton}
          aria-label="open the book in a new tab"
          onClick={() => mlmLaunchUrl()}
        >
          <LaunchIcon />
        </IconButton>
      </Tooltip>
    </Typography>
  );
};

FloatingButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FloatingButton);
