/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * container
 *
 * @file Container.jsx
 * @author Mohamed yasar arabath M
 */

import React, { forwardRef, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const Container = forwardRef(({
  className,
  children,
  enableGutters,
  enableTopAndBottomPadding,
  enableWrapperBgAndPadding,
  enableTopPadding,
  showHeroHeading,
  heroHeading,
  ...props
}, ref) => (
  <div
    ref={ref}
    className={
      `${className} ${enableGutters ? 'common-wrapper' : ''} 
      ${enableTopAndBottomPadding ? 'common-wrapper-v1' : ''} 
      ${enableTopPadding ? 'common-wrapper-v2' : ''} 
        `
    }
    {...props}
  >
    {enableWrapperBgAndPadding ? (
      <Fragment>
        {showHeroHeading && heroHeading
          && (
            <Box className="hero-header-container">
              <Typography component="h1" className="hero-heading">{heroHeading}</Typography>
            </Box>
          )
        }
        <div className="main-container-wrapper main-container-background">
          {children}
        </div>
      </Fragment>
    )
      : children
    }
  </div>
));

Container.defaultProps = {
  className: '',
  enableGutters: false,
  enableTopAndBottomPadding: true,
  enableWrapperBgAndPadding: false,
  enableTopPadding: false,
  showHeroHeading: false
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  enableGutters: PropTypes.bool,
  enableTopAndBottomPadding: PropTypes.bool,
  enableWrapperBgAndPadding: PropTypes.bool,
  enableTopPadding: PropTypes.bool,
  showHeroHeading: PropTypes.bool,
  heroHeading: PropTypes.string.isRequired
};

Container.displayName = 'Container';

export default Container;
