/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
*  Copyright © 2024 Pearson Education, Inc.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Pearson Education, Inc.  The intellectual and technical concepts contained
* herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
* patent applications, and are protected by trade secret or copyright law.
* Dissemination of this information, reproduction of this material, and copying or distribution of this software
* is strictly forbidden unless prior written permission is obtained
* from Pearson Education, Inc.
* */

/**
 * Session Modal Styles
 *
 * @file SessionModalStyles.jsx
 * @author Ram Suresh Yejju
 * @since 1.0.0
 */

import { makeStyles } from '@material-ui/core';
import mixins from '../../../../assets/styles/mixins.scss';
import { EREADER } from '../../../constants';

const getSessionModalStyles = ({ isEreader = false }) => ({
  backdrop: {
    background: isEreader ? 'rgba(254, 254, 254, 0.64)' : 'rgba(2, 9, 23, 0.64)'
  },
  dialogPaper: {
    padding: 24,
    borderRadius: 16,
    boxShadow: `0px 0px 32px 0px ${isEreader ? mixins.greyBoxShadow : mixins.darkThemeColor}`,
    background: mixins.$whiteFont,
    display: 'flex',
    gap: 32,
    maxWidth: 'unset',
    '&:focus-visible': {
      outline: 'none'
    },
    '@media screen and (min-width: 768px)': {
      width: 640
    },
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      minWidth: 344,
      padding: 16,
      gap: 16
    }
  },
  dialogtitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    alignSelf: 'stretch',
    padding: 0
  },
  dialogtitle: {
    flex: '1 0 0',
    color: mixins.dialogInfoSubheaderColor,
    fontFamily: mixins.fontFamilyTT,
    fontSize: mixins.fontSize24,
    fontWeight: mixins.fontWeight700,
    lineHeight: mixins.lineHeight28,
    letterSpacing: '0.4px'
  },
  dialogCloseBtn: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    '& svg': {
      color: mixins.swatchDark800
    },
    '&:hover': {
      background: mixins.darkBgBorder,
      '& svg': {
        color: mixins.whiteFont
      }
    },
    '&:focus-visible': {
      background: mixins.darkBgBorder,
      outline: 'none',
      border: `2px dashed ${mixins.greyColor}`,
      boxShadow: `0px 0px 0px 2px ${mixins.darkBgBorder}`,
      '& svg': {
        color: mixins.whiteFont
      }
    }
  },
  dialogContentContainer: {
    padding: '0px'
  },
  dialogContentText: {
    color: mixins.darkThemeColor,
    fontFamily: mixins.fontFamilyTT,
    fontSize: mixins.fontSize18,
    fontWeight: mixins.fontWeight400,
    lineHeight: mixins.lineHeight24,
    letterSpacing: '0.24px',
    '& b': {
      fontWeight: mixins.fontWeight700,
      minWidth: '48px',
      display: 'inline-block'
    }
  },
  dialogActionsContainer: {
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
    alignSelf: 'stretch',
    padding: 0
  },
  actionBtn: {
    marginLeft: '0 !important',
    '& span': {
      minWidth: 'auto',
      display: 'flex',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      padding: '8px 24px',
      borderRadius: 40,
      background: mixins.swatchDark800,
      color: mixins.whiteFont,
      fontFamily: mixins.fontFamilyTT,
      fontSize: mixins.fontSize18,
      fontWeight: mixins.fontWeight700,
      lineHeight: mixins.lineHeight24,
      letterSpacing: '0.4px',
      '&:hover': {
        background: mixins.darkBgBorder
      },
      '&:focus-visible': {
        background: mixins.darkBgBorder,
        outline: 'none',
        border: `2px dashed ${mixins.greyColor}`,
        boxShadow: `0px 0px 0px 2px ${mixins.darkBgBorder}`
      }
    }
  },
  signOutBtn: {
    '& span': {
      minWidth: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 24px',
      borderRadius: 40,
      background: 'transparent',
      whiteSpace: 'nowrap',
      border: `2px solid ${mixins.swatchDark800}`,
      color: mixins.darkThemeColor,
      fontFamily: mixins.fontFamilyTT,
      fontSize: mixins.fontSize18,
      fontWeight: mixins.fontWeight700,
      lineHeight: mixins.lineHeight24,
      letterSpacing: '0.4px',
      '&:hover': {
        background: mixins.darkBgBorder,
        borderColor: mixins.darkBgBorder,
        color: mixins.whiteFont
      },
      '&:focus-visible': {
        background: mixins.darkBgBorder,
        outline: 'none',
        border: `2px dashed ${mixins.greyColor}`,
        boxShadow: `0px 0px 0px 2px ${mixins.darkBgBorder}`,
        color: mixins.whiteFont
      }
    }
  }
});

/**
 * Returns Session Modal make styles
 *
 * @param {Object} param0
 * @returns
 */
const SessionModalStyles = (props) => {
  const { location } = props || {};
  const isEreader = location === EREADER;
  const styles = getSessionModalStyles({ isEreader });

  return makeStyles(styles);
};

export default SessionModalStyles;
