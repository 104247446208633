/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import Framework from '@greenville/framework';
import newrelic from '../modules/newrelic/newrelic';
import { PDF_CONSTANTS, PEARSONED_DOMAIN } from './constants';

/**
 * This is the container for authHandler.
 *
 * @author Arish Kumar
 */
export default class AuthHandler {
  constructor(context) {
    this.context = context;
  }

  get(loggerName) {
    const log = Framework.getLogger(loggerName);
    const {
      user, course, productToken, product
    } = this.context.props;
    newrelic.setCustomAttribute('Pi-Id', user.id);
    newrelic.setCustomAttribute('Course-Id', course ? course.id : product.id);
    const authHandler = {
      getTokenConfig: () => ({
        id: user.id,
        type: 'PI',
        token: user.token,
        userRole: course ? course.role : PDF_CONSTANTS.USER_ROLE.STUDENT
      }),
      getToken: () => new Promise((resolve) => {
        window.piSession.getToken((status, accessToken) => {
          resolve({
            id: user.id,
            type: 'PI',
            token: accessToken,
            userRole: course ? course.role : PDF_CONSTANTS.USER_ROLE.STUDENT
          });
        });
      }),
      getUserId: () => user.id,
      onError: () => { /* captures error */ },
      getCdnToken: () => {
        const tokenResponse = {
          name: productToken.name,
          value: productToken.value,
          expirationTime: productToken.expirationTime,
          domain: PEARSONED_DOMAIN
        };
        log.debug('getCdnToken call', tokenResponse);
        return tokenResponse;
      }
    };
    return authHandler;
  }
}
