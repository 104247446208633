/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * TemplateF
 *
 * @file TemplateF.jsx
 * @author Manimaran S
 */

import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  IconButton, CardContent, Typography, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TemplateFStyles from './styles/templateFStyles';
import TemplateFV1Styles from './styles/templateFV1Styles';
import {
  DIVIDER,
  PRIMARY,
  SECONDARY_FILLED,
  BRAZE_STYLE_VARIANTS
} from '../../../common/constants';
import useBackgroundImage from '../hooks/useBackgroundImage';

const TemplateF = ({ data, callbackForBraze }) => {
  const {
    id,
    cardId,
    styles,
    title,
    subTitle,
    description,
    image,
    action,
    showDismiss,
    showCTA,
    styleVariant
  } = data;
  const {
    onDismiss,
    logContentCardClick
  } = callbackForBraze;
  const imageSrc = useBackgroundImage(image);
  /**
   * Function to get stylesheet based on braze style variant
   * @returns
   */
  const getStyles = () => {
    const variant = styleVariant.toUpperCase();

    return variant === BRAZE_STYLE_VARIANTS.A ? TemplateFV1Styles(styles)() : TemplateFStyles(styles)();
  };
  const classes = getStyles();
  /**
   * get cta button styles
   * @param {*} style
   * @returns
   */
  const getCtaButtonStyle = (style = '') => {
    switch (style.toLowerCase()) {
      case PRIMARY:
        return classes.primaryCtaBtn;
      case SECONDARY_FILLED:
        return classes.secondaryFilledCtaBtn;
      case DIVIDER:
        return classes.divider;
      default:
        return classes.secondaryBtn;
    }
  };

  /**
   * Function to dismss the campaign
   * @param {*} event
   */
  const dismiss = (event = null) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    onDismiss();
  };

  /**
   * Function to handle campaign click action
   * @param {*} actionData
   */
  const handleCtaClick = (actionData) => {
    const {
      target,
      launchURL,
      allowDismiss,
      logClickEvent
    } = actionData;

    if (logClickEvent) {
      logContentCardClick();
    }

    if (launchURL) {
      window.open(launchURL, target || '_self');
    }
    if (allowDismiss) {
      dismiss();
    }
  };

  /**
   * Return button with image as background
   * @param {*} actionData
   * @param {*} index
   * @returns
   */
  const renderButtonAsImage = (actionData, index) => {
    const {
      image: {
        url = null,
        height = 40,
        width = 136,
        altText = ''
      } = {}
    } = actionData;

    return (
      <Button classes={{ root: classes.buttonAsImage }} onClick={() => handleCtaClick(actionData)}>
        <img
          style={{ height, width }}
          aria-label={altText}
          key={index}
          src={url}
          alt={altText}
        />
      </Button>
    );
  };

  /**
   * Returns button with default style
   * @param {*} actionData
   * @param {*} index
   * @returns
   */
  const renderDefaultButton = (actionData, index) => {
    const { style, text = null } = actionData;

    return (
      <Button
        classes={{ root: getCtaButtonStyle(style) }}
        aria-label={`${text}`}
        role="link"
        key={index}
        onClick={() => handleCtaClick(actionData)}
      >
        {text}
      </Button>
    );
  };

  /**
   * Renders buttons based on variant
   * @param {*} actionData
   * @param {*} index
   * @returns
   */
  const renderCTAButtons = (actionData = {}, index) => {
    const { variant } = actionData;

    switch (variant) {
      case 'buttonAsImage':
        return renderButtonAsImage(actionData, index);
      default:
        return renderDefaultButton(actionData, index);
    }
  };

  return (
    data && (
      <div
        className={classes.cardWrapper}
        id={id}
        data-card-id={cardId}
      >
        {showDismiss
          && (
            <IconButton onClick={event => dismiss(event)} size="small" classes={{ root: classes.iconRoot }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        <CardContent className={classes.rootCardContent}>
          <div className={classes.contentArea}>
            <div className={classes.copyTextWrapper}>
              {title && (
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                  {ReactHtmlParser(title)}
                </Typography>
              )}
              {subTitle && (
                <Typography className={classes.subTitle} gutterBottom variant="p" component="p">
                  {ReactHtmlParser(subTitle)}
                </Typography>
              )}
              {(description) && (
                <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
                  {ReactHtmlParser(description)}
                </Typography>
              )}
            </div>
            {showCTA && (
              <div className={classes.actionBtnWrapper}>
                {action && action.map((actionData, index) => (
                  renderCTAButtons(actionData, index)
                ))}
              </div>
            )}
          </div>
          <div className={classes.imageWrapper}>
            <img
              src={imageSrc}
              className={classes.contentImage}
              alt=""
            />
          </div>
        </CardContent>
      </div>
    )
  );
};

TemplateF.defaultProps = {
  data: {
    description: null,
    subTitle: null,
    image: null,
    styles: {},
    action: [],
    showDismiss: false,
    showCTA: false,
    styleVariant: ''
  },
  callbackForBraze: {
    onDismiss: () => { }
  }
};

TemplateF.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    description: PropTypes.string,
    styles: PropTypes.object,
    image: PropTypes.string,
    action: PropTypes.array,
    showDismiss: PropTypes.bool,
    showCTA: PropTypes.bool,
    styleVariant: PropTypes.string
  }).isRequired,
  callbackForBraze: PropTypes.shape({
    onDismiss: PropTypes.func
  }).isRequired
};

export default memo(TemplateF);
