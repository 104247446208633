/* eslint-disable class-methods-use-this */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Channel recommendations by channel service
 *
 * @file ChannelRecommenationsByChannelService.js
 * @author Manimaran S
 */

import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class ChannelRecommenationsByChannelService extends BaseService {
  constructor() {
    super(constants.CHANNEL_RECOMMENDATIONS_BY_CHANNEL_REQUESTED, 'channelRecommemdationsByChannelStatus');
  }

  /**
   * Trigger the study channel recommendations by channel api call
   *
   * @returns
   */
  handleEvent(eventName, eventData) {
    const { courseId, topicId } = eventData;

    const url = this.getClient('studyChannel').get(`/courses/${courseId}/topics/${topicId}/learn`);
    return url;
  }

  /**
   * Handle study channel recommendations by channel api response and setting ChannelRecommendationsByChannel store
   *
   * @param {*} response
   */
  handleResponse(response) {
    const recommendations = response.data;
    Framework.getStoreRegistry().getStore('channelRecommendationsByChannel').set(recommendations);
  }

  /**
   * Error handling
   *
   * @param {*} error
   */
  handleError(error) {
    Framework.getStoreRegistry().getStore('channelRecommendationsByChannel').setError(error);
  }
}
