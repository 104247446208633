/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Text message Dialog
 *
 * @file TextMessage.jsx
 * @author Poornima N
 */

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Framework from '@greenville/framework';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Popover,
    ClickAwayListener
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PhoneNumberInput from './PhoneNumberInput';
import * as constants from './constants';
import env from './env';
import { StylesProvider } from "@material-ui/core/styles";
import './OTPDialog.scss';

const TextMessage = ({ open, anchorEl, onClose, phoneNo }) => {
    let formattedPhoneNumber = '';
    if (phoneNo) {
        const replacePhoneNumber = phoneNo.replaceAll('-', '');
        formattedPhoneNumber = replacePhoneNumber.replace(' ', '');
    }
    const language = Framework.getStoreRegistry().getStore('language');
    const [phoneNumber, setPhoneNumber] = useState(phoneNo ? formattedPhoneNumber : '');
    const [isValidNumber, setIsValidNumber] = useState(true);
    const [isOtpSent, setIsOtpSent] = useState(false);
    function telephoneCheck(str) {
        var phone = new RegExp(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/);
        return phone.test(str);
      }
      
    const handleClickSend = () => {
        const slicedPhoneNo = (phoneNumber.slice(2)).replaceAll('-', '')
        const isValidNumber = telephoneCheck(parseInt(slicedPhoneNo));
        if (phoneNumber.length === 12 && isValidNumber) {
            setIsValidNumber(true);
            Framework.getEventManager().publish(constants.OTPREQUESTED, {
                type: 'text'
             });
            const deviceId = window.piSession && window.piSession.getDeviceId();
            Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                type: 'generateOTP',
                data: {
                    clientId: env.CLIENT_ID,
                    deviceId,
                    phoneNumber: phoneNumber
                }
            });
            Framework.getEventManager().on('DEVICE_OTP_GENERATE_SUCCESS', () => {
                setIsOtpSent(true);
              });
        } else {
            setIsValidNumber(false);
        }
    }
    const handleTextChange = (country, phoneInput) => {
        setIsOtpSent(false);
        setIsValidNumber(true);
        setPhoneNumber(country + phoneInput);
    }
    function getClass(){
        let locale = Framework.getStoreRegistry().getStore('language').currentLanguage;
        let className = '';
        if ((locale === 'fr-fr') || (locale === 'fr-ca')  || (locale === 'it-it')) {
            className = 'otpSmsTitleLocale'
        } else {
            className = 'OtpSmsTitle'
        }
        return className
    }
    function getButtonClass(){
        let locale = Framework.getStoreRegistry().getStore('language').currentLanguage;
        let className = '';
        if ((locale === 'fr-fr') || (locale === 'fr-ca') || (locale === 'ru')) {
            className = 'sendCodeLocale'
        } else {
            className = 'sendCode'
        }
        return className
    }
    return (
        <StylesProvider injectFirst>
            <div>
                <Popover
                    open={open}
                    aria-labelledby="otp-sms-modal-title"
                    aria-describedby="otp-sms-modal-description"
                    classes={{
                        paper: "paperOtpPopover"
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    className="dialog-zindex"
                >
                    <ClickAwayListener onClickAway={onClose}>
                        <div>
                            <DialogTitle id="otp-sms-modal-title">
                                <IconButton aria-label="close" className="closeButtonOtp" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                                <div className={getClass()}>
                                    {
                                        <FormattedMessage {...language.getText('textmessage.TITLE')} />
                                    }
                                </div>
                            </DialogTitle>
                            <hr className="dividerClass" />
                            <DialogContent id="otp-sms-modal-description">
                                <div >
                                    <div className="otpSmsDesc">
                                        {
                                            <FormattedMessage {...language.getText('textmessage.PHONE_NUMBER')} />
                                        }
                                    </div>
                                </div>
                                <div>
                                    <PhoneNumberInput phoneNo={phoneNo} isOtpSent={isOtpSent} isValid={isValidNumber} onTextChange={handleTextChange} />
                                </div>

                            </DialogContent>
                            <DialogActions classes={{ root: "dialogactionOtp" }}>
                                <Button
                                    className="sendCodeBtn"
                                    id="otp-sms-button"
                                    color="default"
                                    size="medium"
                                    onClick={handleClickSend}
                                >
                                    <div className={getButtonClass()}>
                                        <FormattedMessage
                                            {...language.getText('textmessage.SEND_CODE')}
                                        />
                                    </div>
                                </Button>
                            </DialogActions>
                        </div>
                    </ClickAwayListener>
                </Popover >
            </div >
        </StylesProvider>
    );
}

export default TextMessage;