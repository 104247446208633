/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * AuthHome Chat Component Styles
 *
 * @file AuthHomeChatComponentStyles.jsx
 * @author Mohamed yasar arabath M
 */

const AuthHomeChatComponentStyles = () => ({
  botContainer: {
    height: 'auto',
    position: 'sticky',
    bottom: '0',
    zIndex: '1301',

    '& #bot_container_id': {
      height: 'calc(100vh - 124px)'
    }
  }
});
export default AuthHomeChatComponentStyles;
