/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { parse } from 'query-string';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import pearsonTheme from '@greenville/mui-theme';
import Framework, { Container } from '@greenville/framework';

import LanguageContext from './contexts/LanguageContext';
import modules from './modules';
import languages from './common/languages';
import env from './common/env';
import * as constants from './common/constants';
import './App.css';
import CommonUtils from './common/utils/CommonUtils';

const intlBuilder = Framework.getIntlBuilder();

const isIntergratedMLMLaunch = CommonUtils.isIntergratedMLMLaunch();
export default class App extends PureComponent {
  constructor(props) {
    super(props);

    this.favIcon = `${env.CONTENT_HOST}/favicon.ico`;
    this.title = constants.PEARSON_ETEXT_TITLE;
    this.metaData = constants.PEARSON_ETEXT_META;
    if (window.location.hostname.includes(constants.ETEXT_PERF)) {
      window.location.href= window.location.href.replace(constants.ETEXT_PERF,constants.PLUS_PPE);
    }
    if (window.location.hostname.includes(constants.PLUS)) {
      this.favIcon = `${env.CONTENT_HOST}/mojo_favicon_v1.png`;
      this.title = constants.PEARSON_PLUS_TITLE;
      this.metaData = constants.PEARSON_PLUS_META;
    }
    if (window.location.hostname.includes(constants.ETEXT)) {
      window.location.href= window.location.href.replace(constants.ETEXT,constants.PLUS);
    }
    const queryString = parse(window.location.search);
    let locale = constants.DEFAULT_LOCALE;
    const isLocaleAvailable = queryString.locale && languages.find(language => language.locale === queryString.locale);

    if (queryString.locale && isLocaleAvailable) {
      locale = queryString.locale;
    }
    this.state = {
      locale,
      browserLocale: true
    };
    languages.forEach(language => intlBuilder.add(language.locale, language.messages));
    intlBuilder.setDefaultLang(locale).addPseudoLocale(env.PSEUDO_LOCALE_CODE).build();
    const moduleBuilder = Framework.getModuleBuilder();

    if (modules.length > 0) {
      modules.forEach(module => moduleBuilder.add(module));
      modules.length = 0;
      this.modules = moduleBuilder.build();
    }
  }

  /**
   * Handles locale change
   *
   * @param {string} locale - locale to change
   */
  handleLocaleChange = (locale) => {
    // updating the default language in the IntlBuilder
    intlBuilder
      .setDefaultLang(locale)
      .addPseudoLocale(env.PSEUDO_LOCALE_CODE)
      .build();
    this.setState({
      locale,
      browserLocale: false
    });
  }

  render() {
    const subscription = 'gpsSubscriptions:all&study=true';
    const subscriptionFilter = CommonUtils.isLMSQueryParamAvailable()
      ? `${subscription}&lms=true` : subscription;

    return (
      <MuiThemeProvider theme={createMuiTheme(pearsonTheme)}>
        <LanguageContext.Provider
          value={{
            onLocaleChange: this.handleLocaleChange,
            locale: this.state.locale
          }}
        >
          <Helmet>
            <link rel='shortcut icon' id='favicon' href={this.favIcon} />
            <meta
              name="description"
              content={this.metaData}
            />
            <title>{this.title}</title>
          </Helmet>
          <Container
            authClientId={window.isSSIEnabled ? env.SSI_CLIENT_ID : env.CLIENT_ID}
            browserLocale={this.state.browserLocale}
            browserRequirementsUrl={env.BROWSER_REQUIREMENTS_URL}
            correlationIdPrefix="GVET"
            subscriptionFilter={subscriptionFilter}
            enableGetDeviceList={!isIntergratedMLMLaunch}
            enableSessionTimedOutEvent={isIntergratedMLMLaunch}
            gtmArgs={{
              gtmId: env.GTM_CONTAINER_ID,
              auth: env.GTM_AUTH,
              preview: env.GTM_PREVIEW
            }}
            delayedGTM
            mojoMVP
            browserCheck={false}
            sessionDuration={172800} // sessionDuration set for 48 hours
            noAuthRoutes={['/errors/SUBSCRIPTION_REGISTRATION']}
            modules={this.modules}
            product={env.PRODUCT}
            partnerEnv={env.PARTNER_ENV}
            partnerEnvConfig={{ USER_PROFILE_BASE_URL: env.MARIN_API_BASE_URL_V2 }}
            version="1.0.7"
          />
        </LanguageContext.Provider>
      </MuiThemeProvider>
    );
  }
}
