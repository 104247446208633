/* eslint-disable no-param-reassign */
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to UserDetails
 *
 * @file UserDetails.js
 * @author Srinivasan Bakthavatchalam
 */

import { types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { ELIGIBILITY_REQUESTED } from '../../../common/constants';

const IntialDetails = types.model(
  'IntialDetails',
  {
    dob: types.maybeNull(types.string),
    studyLevel: types.maybeNull(types.string),
    userCountry: types.maybeNull(types.string)
  }
);

const UserDetails = types.model(
  'UserDetails',
  {
    intialDetails: types.maybeNull(IntialDetails)
  }
).actions(self => ({
  fetchEligibility() {
    const { id: userId } = Framework.getStoreRegistry().getStore('user');

    Framework.getEventManager().publish(ELIGIBILITY_REQUESTED, { userId });
  },
  setIntialDetails(data) {
    self.intialDetails = data;
  }
}));

export default UserDetails;
