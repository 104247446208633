/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { makeStyles } from '@material-ui/core';

/**
 * OnboardStyles
 *
 * @file OnboardStyles.jsx
 * @author Mohamed yasar arabath M
 */

const OnboardStyles = () => makeStyles({
  root: {
    display: 'flex',
    width: 344,
    height: 640,
    '@media screen and (min-width: 980px)': {
      width: 960
    }
  },
  paperRoot: {
    background: 'transparent',
    overflowX: 'hidden',
    margin: 0,
    maxWidth: 'unset'
  },
  dialogRoot: {
    '& .MuiBackdrop-root': {
      background: 'rgba(2, 9, 23, 0.64)'
    }
  },
  iconButton: {
    position: 'absolute',
    right: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    zIndex: '999999',
    padding: '14px',
    textAlign: 'center',
    '& svg': {
      fontSize: '26px'
    },
    '@media screen and (max-width: 980px)': {
      padding: '10px',
      '& svg': {
        fontSize: '22px'
      }
    }
  }
});

export default OnboardStyles;
