/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  withStyles
} from '@material-ui/core';
import Framework from '@greenville/framework';
import CloseIcon from '@material-ui/icons/Close';
import waveShape from '../../assets/images/wave.png';
import deleteModals from '../../assets/images/deleteModals.png';

const RemoveTitleDialogStyles = () => ({
  removePopupBackgroundImage: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 400
    },
    '& .MuiPaper-root': {
      width: 422,
      height: 397,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 5,
      overflow: 'hidden',
      margin: 0,
      '@media screen and (max-width: 480px)': {
        width: '100%',
        height: '100%',
        maxHeight: 'unset'
      },
      '& .MuiDialogContent-root': {
        padding: '10px 102px 0 102px'
      }
    }
  },
  removeTitleContainer: {
    width: '100%',
    float: 'left',
    '& p': {
      fontFamily: 'Hind',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '150%',
      marginTop: 0,
      marginBottom: 10
    }
  },
  shape: {
    height: 263,
    padding: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    '@media screen and (max-width: 480px)': {
      backgroundSize: 'contain',
      height: 524
    }
  },
  shape1: {
    width: 422,
    height: 500,
    backgroundSize: '60%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    position: 'absolute',
    top: '-25%',
    left: '0%',
    justifyContent: 'center',
    '@media screen and (max-width: 480px)': {
      backgroundSize: 'contain',
      top: '25%',
      left: '11%'
    }
  },
  alertDialogTitle: {
    padding: '16px 24px 0px',
    '& h2': {
      textAlign: 'center',
      marginBottom: 0,
      fontFamily: 'Hind',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '120%'
    }
  },
  alertContinueButton: {
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    '& button': {
      zIndex: 2,
      background: 'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
      borderRadius: 35,
      fontSize: 16,
      padding: '6px 16px',
      fontFamily: 'Hind',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%',
      textAlign: 'center',
      color: '#FEFEFE',
      width: 90
    }
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#333333',
    zIndex: 999,
    '& .MuiSvgIcon-root': {
      width: 17,
      height: 17
    }
  }
});

const RemoveTitleDialog = ({
  classes, open, onClose, onRemoveTitle
}) => {
  const language = Framework.getStoreRegistry().getStore('language');
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.removePopupBackgroundImage}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title" className={classes.alertDialogTitle}>
        <div>
          <h2><FormattedMessage {...language.getText('remove.title')} /></h2>
        </div>
      </DialogTitle>
      <div>
        <DialogContent>
          <div className={classes.removeTitleContainer}>
            <p><FormattedMessage {...language.getText('remove.subTitle')} /></p>
          </div>
        </DialogContent>
      </div>
      <DialogActions className={classes.alertContinueButton}>
        <Button onClick={onRemoveTitle} color="primary">
          <FormattedMessage {...language.getText('remove.buttonText')} />
        </Button>
      </DialogActions>
      <div
        className={classes.shape}
        style={{
          backgroundImage: `url(${waveShape})`
        }}
      />
      <div
        className={classes.shape1}
        style={{
          backgroundImage: `url(${deleteModals})`
        }}
      />
    </Dialog>
  );
};


RemoveTitleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveTitle: PropTypes.func.isRequired
};

export default withStyles(RemoveTitleDialogStyles)(RemoveTitleDialog);
