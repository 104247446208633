/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * CommonStyles
 *
 * @file CommonStyles.jsx
 * @author Manimaran S
 */

import PropTypes from 'prop-types';

const CommonStyles = (styles) => {
  const arrowColor = styles.arrowColor || 'black';

  return {
    arrowStyles: {
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        zIndex: 1299,
        '&::before': {
          borderWidth: '0 2em 2em 2em',
          borderColor: `transparent transparent ${arrowColor} transparent`
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        zIndex: 1299,
        '&::before': {
          borderWidth: '2em 2em 0 2em',
          borderColor: `${arrowColor} transparent transparent transparent`
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        zIndex: 1299,
        '&::before': {
          borderWidth: '2em 2em 2em 0',
          borderColor: `transparent ${arrowColor} transparent transparent`,
          margin: '-7px !important'
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        zIndex: 1299,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '2em 0 2em 2em',
          borderColor: `transparent transparent transparent ${arrowColor}`
        }
      }
    }
  };
};

CommonStyles.defaultProps = {
  style: {}
};

CommonStyles.propTypes = {
  styles: PropTypes.object
};

export default CommonStyles;
