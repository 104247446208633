/* eslint-disable no-tabs */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * SessionModal component
 *
 * @file SessionModal.jsx
 * @author Ram Suresh Yejju
 * @since 1.0.0
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button
} from '@material-ui/core';
import SessionModalStyles from './styles/SessionModalStyles';
import CloseModalIcon from '../../icons/CloseModalIcon';

const SessionModal = ({
  open,
  data,
  location
}) => {
  const {
    title,
    description,
    actions,
    onClose
  } = data;

  const classes = SessionModalStyles({ location })();

  const getActionClassName = isSecondary => (isSecondary ? classes.signOutBtn : classes.actionBtn);
  const handleCloser = (event, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      open={open}
      role="dialog"
      aria-modal="true"
      disableEscapeKeyDown
      disableBackdropClick
      onClose={handleCloser}
      PaperProps={{
        tabIndex: 0
      }}
    >
      <DialogTitle disableTypography classes={{ root: classes.dialogtitleContainer }}>
        {
          title && (<Typography className={classes.dialogtitle}>{title}</Typography>)
        }
        <IconButton className={classes.dialogCloseBtn} onClick={handleCloser}><CloseModalIcon /></IconButton>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.dialogContentContainer }}
      >
        <Typography className={classes.dialogContentText}>
          {ReactHtmlParser(description)}
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsContainer }}>
        {
          actions && actions.length > 0 && actions.map((action, index) => (
            <Button onClick={action.callback} className={getActionClassName(action.isSecondary)} key={index}>
              {action.text}
            </Button>
          ))
        }
      </DialogActions>
    </Dialog>
  );
};

SessionModal.defaultProps = {
  open: false
};

SessionModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    actions: PropTypes.array,
    onClose: PropTypes.func
  }).isRequired,
  location: PropTypes.string.isRequired
};

export default SessionModal;
