/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

import BackLinkService from '../modules/courses/services/BackLinkService';
/**
 * This is the container for authHandler.
 *
 * @author Saravanan kandasamy
 */
export default class BackLinkClient {
  constructor(context) {
    this.context = context;
    this.client = new BackLinkService(this);
  }

  post(data) {
    const { id, productId } = this.context.props.match.params;
    const payLoad = {
      custom_bookid: productId,
      custom_course_id: id
    };
    return new Promise((resolve, reject) => {
      this.client.handleEvent(`${data.guid}`, payLoad).then((response) => {
        const res = { ...response, endPoint: response.data.end_point_uri };
        resolve(res);
      }, (error) => {
        reject(error);
      });
    });
  }
}
