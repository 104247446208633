/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */
/* eslint-disable */

import React from 'react';
/**
 * A React component for Print watermark
 * 
 * @author Gowtaman Madan
 */

const printIcon = props => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style={{ enableBackground: "new 0 0 1000 1000" }} xmlSpace="preserve" >
    <path id="SVGID_x5F_1_x5F_" className="st0" style={{ fillRule: 'evenodd', clipRule: "evenodd", fill: "none" }} d="M224.3,620.6c-65.4-153.5,6-330.9,159.4-396.3s330.9,6,396.3,159.4"></path>
    <text><textPath xlinkHref="#SVGID_x5F_1_x5F_" startOffset="2.295%">
      <tspan style={{ fontFamily: 'Hind-SemiBold', fontSize: "57px" }}>©</tspan><tspan style={{ fontFamily: 'Hind-SemiBold', fontSize: "57px" }}>PEARSON - DO NOT DISTRIBUTE</tspan></textPath>
    </text>
    <path id="SVGID_x5F_2_x5F_" className="st0" style={{ fillRule: 'evenodd', clipRule: "evenodd", fill: "none" }} d="M188,636.8C262,810.3,462.5,891,636,817s254.2-274.5,180.2-448"></path>
    <text><textPath xlinkHref="#SVGID_x5F_2_x5F_" startOffset="2.018%">
      <tspan style={{ fontFamily: 'Hind-SemiBold', fontSize: "43px" }}>{props}</tspan></textPath>
    </text>
    <path className="st1" style={{ fillRule: 'evenodd', clipRule: "evenodd" }} d="M490.7,634.2c-3.3-2.6-7-4.4-10.8-5.1c-1.3-0.2-2.6-0.4-3.9-0.4c-7.1,0-14,3.9-18.5,10.5
	c-3.8,5.5-5.3,12-4.3,18.3c0.6,4,2.1,7.5,4.4,10.1c2.5,3,6.3,5.2,11,6.6c2.7,0.8,5.5,1.2,8.3,1.2h0c6.1,0,11.6-1.8,15.8-5.3
	c4.9-4.1,7.6-9.8,7.6-16.3C500.3,646.2,496.7,638.9,490.7,634.2 M627.9,373.5c21.2,22.8,27.9,57.5,24.2,85.9
	c-4.2,32.4-23.7,62.4-56.2,82.3c-25.7,15.8-59.5,22.1-92.8,22.4c-0.3,5.3-1.3,23.6-1.8,26.5c-0.8,4.3-2.3,6.6-4.1,9
	c-3.6,4.7-9.8,7.7-15.9,9.5c-4.9,1.4-9.7,2-13.2,2c-0.6,0-1.1,0-1.7-0.1c-3.6-0.4-7.6-2.1-10.1-4.9c-2.5-2.8-3.9-6.3-4.2-10.3
	l-0.3-3.6c-0.7-3.1-4.6-160.6-3.8-157.8c0.6-18.9,30.8-22.2,47.2-18.8c16.4,3.3,12.3,23.5,11.8,48.2c0,0-0.8,32.5-2.3,66.9
	c20.9-0.8,37.6-6.8,53.1-14.6c31.4-15.7,48.1-39.8,48.1-75.2s-34.3-75.2-97.5-75.2c-63.2,0-90.4,16.1-121,41.6
	c-6.9,5.8-12.2,11.5-17.2,15.4c-5,3.8-18.4,4.4-22-4.5c-4.3-10.8,3.7-22.8,8.5-28.6c7.4-9.1,25.8-27.5,46.1-38.1
	c23.3-12.2,51.8-25.4,91.4-26.4c35.9-0.9,92.5,2,133.3,48.4"></path>
  </svg>
)

export default printIcon;