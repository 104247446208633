/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * Device Account Lock component
 *
 * @file AccountLock.jsx
 * @author Infant Diana Sebastian
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles
} from '@material-ui/core';
import Framework from '@greenville/framework';


const styles = () => ({
  dialogPaper: {
    backgroundImage: 'url(/Lock.png)',
    backgroundRepeat: 'no-repeat',
    minWidth: 600,
    height: 418,
    background: '#F7F9FD',
    borderRadius: 16,
    '@media screen and (max-width: 700px)': {
      height: 525,
      width: '80%',
      minWidth: 250,
      backgroundPositionX: 'left',
      backgroundPositionY: 'bottom',
      backgroundSize: '50%'
    }
  },
  accountlockdialogtitle: {
    textAlign: 'left',
    padding: 0,
    margin: '0px 36px 0px 265px',
    '@media screen and (max-width: 700px)': {
      textAlign: 'center',
      margin: '0px 18px 6px'
    }
  },

  accountlocktitle: {
    fontFamily: 'TT Commons',
    fontWeight: 'bold',
    fontSize: 28,
    textAlign: 'center',
    lineHeight: '110%',
    color: '#05112A',
    marginTop: 85,
    marginBottom: 8,
    '@media screen and (max-width: 700px)': {
      margin: 'unset',
      marginTop: 40
    }
  },
  accountlockcontent: {
    fontFamily: 'Hind',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '150%',
    color: '#333333'
  },
  accountlockcontentTimer: {
    fontFamily: 'Hind',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '150%',
    color: '#333333',
    width: 298,
    height: 24
  },
  accountlockactions: {
    justifyContent: 'center',
    margin: 15,
    '@media screen and (max-width: 700px)': {
      margin: 0
    }
  },
  accountlockbutton: {
    background: 'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
    borderRadius: 40,
    width: 78,
    height: 40,
    border: 'none',
    padding: '4px 20px',
    '&:hover': {
      background: 'linear-gradient(135deg, #D6492A 0%, #BA015A 63.44%, #940159 100%)',
    }
  },
  accountlockdismiss: {
    fontFamily: 'Hind',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#F7F9FD',
    lineHeight: '150%',
    width: 38,
    height: 24,
  }
});

const AccountLock = ({
  open, onClose, classes
}) => {
  const language = Framework.getStoreRegistry().getStore('language');
  const initialMinute = 0, initialSeconds = 20;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          onClose();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Dialog
      open={open}
      id="account-lock-dialog"
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="account-lock-dialog-title"
      aria-describedby="account-lock-dialog-description"
      role="dialog"
      aria-modal="true"
      className="dialog-zindex"
    >
      <DialogTitle
        disableTypography
        id="account-lock-dialog-title"
        classes={{ root: classes.accountlockdialogtitle }}
      >
        <Typography variant="h3" component="h2" className={classes.accountlocktitle} >
          <FormattedMessage {...language.getText('device.ACCOUNT_LOCK_TITLE')} />
        </Typography>
      </DialogTitle>
      <DialogContent id="account-lock-dialog-description" classes={{ root: classes.accountlockdialogtitle }}>
        <Typography variant="subtitle2" component="p" className={classes.accountlockcontent} >
          <FormattedMessage {...language.getText('device.ACCOUNT_LOCK_DESC')} />
        </Typography>
        <DialogActions classes={{ root: classes.accountlockactions }}>
          <Button
            color="default"
            variant="outlined"
            id="dismiss"
            size="small"
            className={classes.accountlockbutton}
            onClick={onClose}
          >
            <Typography variant="body2" className={classes.accountlockdismiss}>
              <FormattedMessage {...language.getText('device.OKAY')} />

            </Typography>
          </Button>

        </DialogActions>
        <Typography variant="subtitle2" component="p" className={classes.accountlockcontentTimer} >
          <FormattedMessage
            {...language.getText('device.ACCOUNT_LOCK_TIMER')}
            values={
              {
                time: `${minutes}:${seconds < 10 ? '0'+seconds : seconds}`
              }
            }
          />
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
AccountLock.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(AccountLock);
