/* eslint-disable no-param-reassign */
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * User last location service
 *
 * @file LastLocationService.js
 * @author Infant Diana Sebastian
 */

import Framework, { BaseService } from '@greenville/framework';

import {
  LASTLOCATION_DATA_REQUESTED,
  INTEGRATED_SMS,
  INTEGRATED_RUMBA,
  CG_RENEWALS,
  NEW_BOOK_MAX_DAYS,
  READ
} from '../../../common/constants';
import env from '../../../common/env';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class LastLocationService extends BaseService {
  constructor() {
    super(LASTLOCATION_DATA_REQUESTED, 'lastLocationStatus');
    this.bookshelfData = { books: [] };
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    this.bookshelfData = event.bookshelfData;
    headerParams = {
      headers: {
        'x-tenantKey': env.TENANT_KEY_ETEXT
      }
    };

    // TODO: includeChannels to be called when it required
    return this.getClient('spectrumV4').post('/user/location/search?includeChannels=true', { bookIds: event.bookIds }, headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    const initialBookshelfData = {
      books: []
    };
    const bookshelf = this.bookshelfData;
    const updatedBookshelf = {};

    if (bookshelf.books.length > 0) {
      if (response.data && response.data.userLocation && response.data.userLocation.length > 0) {
        const integratedBMC = [INTEGRATED_SMS, INTEGRATED_RUMBA, CG_RENEWALS];
        const locations = response.data.userLocation;
        const isNewlyAdded = (date) =>{
          let difference = new Date().getTime() - new Date(date).getTime();
          let daysInDiff = Math.ceil(difference / (1000 * 3600 * 24));
          return daysInDiff <= NEW_BOOK_MAX_DAYS && daysInDiff >= 0 ? true : false;
        }
        const updatebookshelf = (bookIndex, item) => {
          if (bookIndex !== -1) {
            const lastLocation = locations[bookIndex].locations && locations[bookIndex].locations.length > 0
              && locations[bookIndex].locations.find(
                location => location.activity === locations[bookIndex].lastActivity
              );
            const lastReadLocation = locations[bookIndex].locations && locations[bookIndex].locations.length > 0
            && locations[bookIndex].locations.find(
              location => location.activity === READ
            );
            item.last_activity = locations[bookIndex].lastActivity; // eslint-disable-line no-param-reassign
            item.last_location = lastLocation && lastLocation.displayTitle; // eslint-disable-line no-param-reassign
            item.last_location_id = lastLocation && lastLocation.id; // eslint-disable-line no-param-reassign
            item.last_location_read = lastReadLocation && lastReadLocation.displayTitle;
            item.last_location_read_id = lastReadLocation && lastReadLocation.id;
            item.last_accessed_print_page_number = lastReadLocation && lastReadLocation.printPageNo;
            item.userLocations = lastLocation && locations[bookIndex]; // eslint-disable-line no-param-reassign
            item.last_accessed_time = lastLocation && lastLocation.updatedTime;
          }
        };
        bookshelf.books.forEach((book) => {
          const titleId = book.hasMultipleCourse ? book.course_id : book.product_id;
          const id = integratedBMC.includes(book.product_entitlements.business_model_code) ? book.book_id : titleId;
          const locationAttributeCheck =  (book.hasMultipleCourse && book.course_id && book.course_id === id) ? 'courseId' : 'bookId';
          const index = locations.findIndex(location => (location[locationAttributeCheck] === id));
          updatebookshelf(index, book);
        });
        if (bookshelf.books && bookshelf.books.length > 0) {
          const lastAccessedBooks = bookshelf.books.filter(book => book.last_accessed_time);
          const notAccessedBooks = bookshelf.books.filter(book => !book.last_accessed_time);
          lastAccessedBooks.sort((a, b) => b.last_accessed_time - a.last_accessed_time);
          notAccessedBooks.sort((a, b) => a.book_title?.localeCompare(b.book_title));
  
          const lastAccessedActiveBooks = lastAccessedBooks.filter(book => !book.course_expired && !book.product_entitlements.expired);
          const lastAccessedInactiveBooks = lastAccessedBooks.filter(book => book.course_expired || book.product_entitlements.expired);
          const notAccessedActiveBooks = notAccessedBooks.filter(book => !book.course_expired && !book.product_entitlements.expired);
          const notAccessedInactiveBooks = notAccessedBooks.filter(book => book.course_expired || book.product_entitlements.expired);
          const newAndNotAccessedActiveBooks = [];
          const oldAndNotAccessedActiveBooks = [];
          notAccessedActiveBooks.forEach(book => {
            isNewlyAdded(book.product_entitlements.start_date) ? newAndNotAccessedActiveBooks.push(book) : oldAndNotAccessedActiveBooks.push(book);
          });
          updatedBookshelf.books = [...newAndNotAccessedActiveBooks, ...lastAccessedActiveBooks, ...oldAndNotAccessedActiveBooks, ...lastAccessedInactiveBooks, ...notAccessedInactiveBooks];
        }
        Framework.getStoreRegistry().getStore('lastlocation').set(response.data);
        Framework.getStoreRegistry().getStore('bookshelf').set(updatedBookshelf);
      } else {
        // handles no response from the API
        const updatedBookshelfData = CommonUtils.sortBooksOnStartDateAndStatus(this.bookshelfData);
        Framework.getStoreRegistry().getStore('lastlocation').set(response.data);
        Framework.getStoreRegistry().getStore('bookshelf').set(updatedBookshelfData);
      }
    } else {
      Framework.getStoreRegistry().getStore('lastlocation').set(response.data);
      Framework.getStoreRegistry().getStore('bookshelf').set(initialBookshelfData);
    }
  }

  /**
   * Sorts books based on start date, active and inactive status if there
   * are errors in the API
   */
  handleError() {
    const defaultLastLocations = {
      status: '',
      message: ''
    };
    Framework.getStoreRegistry().getStore('lastlocation').set(defaultLastLocations);
    const updatedBookshelfData = CommonUtils.sortBooksOnStartDateAndStatus(this.bookshelfData);
    Framework.getStoreRegistry().getStore('bookshelf').set(updatedBookshelfData);
  }
}
