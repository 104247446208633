/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2022 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * QR code popup style
 *
 * @file L2ModalStyles.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import mixins from '../../../../assets/styles/mixins.scss';

const L2ModalStyles = () => ({
  scrollBarContainer: {
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: `${mixins.swatchDark800} transparent`,
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      background: mixins.swatchDark800
    },
    '@media screen and (min-width: 1440px)': {
      height: 844
    }
  },
  dialogpaper: {
    display: 'flex',
    borderRadius: 16,
    background: '#161C29',
    boxShadow: '0px 0px 32px 0px #020917',
    width: '100%',
    padding: 24,

    '@media screen and (min-width: 1440px)': {
      maxWidth: 1376,
      height: 960,
      marginTop: 147
    }
  },
  dialogContentContainer: {
    order: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    padding: '32px 9px 32px 32px !important',

    '@media screen and (min-width: 1440px)': {
      width: 1334
    }
  },
  dialogTitleInfoContanier: {
    order: 0,
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    alignItems: 'center',
    gap: 24,
    alignSelf: 'stretch',
    '@media screen and (min-width: 1440px)': {
      width: 1334
    }
  },
  dialogtitle: {
  },
  dialogheader: {
    fontFamily: mixins.fontFamilyTT,
    fontSize: mixins.fontSize24,
    fontStyle: 'normal',
    fontWeight: mixins.fontWeight700,

    flex: '1 0 0',
    color: '#FEFEFE',
    lineHeight: 28,
    letterSpacing: '0.4px'
  },
  dialogaction: {
    padding: 0,
    margin: 0,
    width: 40,
    height: 40
  },
  closeIcon: {
    display: 'flex',
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: 40,
    border: '0px solid #272D39',
    '& span> svg': {
      color: '#fff'
    }
  },
  dialoginfotitle: {
    fontFamily: mixins.fontFamilyTT,
    fontSize: mixins.fontSize40,
    fontStyle: 'normal',
    fontWeight: mixins.fontWeight700,
    lineHeight: mixins.fontSize40,
    color: mixins.indigoDefaultColor,
    wordBreak: 'break-word'
  },
  infodescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  infoImageContainer: {
    display: 'flex',
    gap: 24
  }
});
export default L2ModalStyles;
