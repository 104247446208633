/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../constants';

/**
* Author Poornima N
*/

export default class LaunchLogService extends BaseService {
  constructor() {
    super(constants.LAUNCH_LOG_REQUESTED, 'logstatus');
  }
  handleEvent(eventName, event) {
    const logHistoryEndPoint = '/dm/log?action=otprequest&days=7';
    const headerParams = { headers: { 'X-TenantId': 'ETEXT' } };
    return this.getClient('marin').get(logHistoryEndPoint, headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('launchLog').set(response.data.data);
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getStoreRegistry().getStore('launchLog').setCodeHistoryAsEmpty(error);
  }
}
