/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Print Message styles
 *
 * @file PrintMessageStyles.jsx
 * @author Gladson Samuel S
 */

const PrintMessageStyles = () => ({
  container: {
    display: 'none',
    padding: 24,
    gap: 24,
    flexDirection: 'column',

    '@media print': {
      display: 'flex',
      visibility: 'visible'
    }
  },
  title: {
    color: '#020917',
    fontSize: 40,
    fontFamily: 'TT Commons',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: 0.4
  },
  description: {
    color: '#020917',
    fontSize: 24,
    fontFamily: 'TT Commons',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: 0.24
  }
});

export default PrintMessageStyles;
