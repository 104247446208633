/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to ChannelRecommendationsByBook
 *
 * @file ChannelRecommendationsByBook.js
 * @author Manimaran S
 */

import { applySnapshot, types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import ChannelAssets from './ChannelAssets';
import RecommendedTopic from './RecommendedTopic';
import CommonUtils from '../../../common/utils/CommonUtils';

const ChannelRecommendationsByChannel = types.model(
  'ChannelRecommendationsByChannel',
  {
    startWithTopics: types.maybeNull(types.array(ChannelAssets)),
    jumpBackIn: types.maybeNull(types.array(RecommendedTopic)),
    recommendedNext: types.maybeNull(types.array(RecommendedTopic)),
    isError: types.optional(types.boolean, false)
  }
).views(self => ({
  /**
   * Constructs and returns necessary data for mapped channel for Hero channel
   *
   * @param {*} MAX_ASSET_COUNT
   * @returns
   */
  getMappedChannel(MAX_ASSET_COUNT) {
    const { startWithTopics, jumpBackIn, recommendedNext } = self;
    const language = Framework.getStoreRegistry().getStore('language');
    const {
      MAPPEDCHANNELASSET,
      LAST_ACCESSED_CHANNEL_ASSETS
    } = constants.MAPPED_CHANNEL_TEMPLATES;
    const {
      CHANNEL_TYPES,
      PRACTICE_SET_ICON,
      CHANNEL_TYPE_RESPONSE
    } = constants;
    let mappedChannel = null;
    const channelType = {
      EXAM_PREP: CHANNEL_TYPE_RESPONSE.PRACTICE_SET,
      GUIDED: CHANNEL_TYPE_RESPONSE.TOPIC
    };

    /**
     * Check for statWithTopics first
     * if that is not found we will check for jump in or recommended next
     * and take the first value from it
     */
    if (startWithTopics && startWithTopics.length > 0) {
      const displayHeading = language.getMessage('hero.heroChannel.cardsHeading');

      const cards = startWithTopics.slice(0, MAX_ASSET_COUNT).map((response, index) => {
        const {
          description,
          icon,
          section,
          title,
          thumbnailUrl
        } = CommonUtils.getRecommendedAssetsData(response);

        const card = {
          ariaLabel: response.assetContentType,
          callForAction: {
            type: CHANNEL_TYPES.START_WITH_TOPICS,
            cardIndex: index
          },
          description,
          icon,
          section,
          thumbnailUrl,
          title
        };

        return card;
      });

      mappedChannel = {
        template: LAST_ACCESSED_CHANNEL_ASSETS.TEXT,
        heading: displayHeading,
        cards
      };
    } else if (jumpBackIn && jumpBackIn.length > 0) {
      const displayHeading = language.getMessage('hero.heroChannel.cardsHeading');
      const hasRecommendedNext = recommendedNext && recommendedNext.length > 0;
      const jumpBackInAndUpNext = [...jumpBackIn, ...(hasRecommendedNext ? recommendedNext : [])];

      const cards = jumpBackInAndUpNext.slice(0, MAX_ASSET_COUNT).map((response, index) => {
        const card = {
          ariaLabel: response.type,
          callForAction: {
            type: CHANNEL_TYPES.JUMPBACK_IN_AND_RECOMMENDED_NEXT,
            cardIndex: index
          },
          icon: response.type === CHANNEL_TYPES.EXAM_PREP
            ? MAPPEDCHANNELASSET.ICONS.STACKICON : MAPPEDCHANNELASSET.ICONS.LISTICON,
          section: channelType[response.type] || '',
          numOfAssets: response.numOfAssets,
          thumbnailUrl: response.type === CHANNEL_TYPES.EXAM_PREP
            ? PRACTICE_SET_ICON : response.thumbUrl,
          title: response.topicTitle
        };

        return card;
      });

      mappedChannel = {
        template: LAST_ACCESSED_CHANNEL_ASSETS.TEXT,
        heading: displayHeading,
        cards
      };
    }

    return mappedChannel;
  },
  getAssets(MAX_ASSET_COUNT) {
    let assets = {};
    const language = Framework.getStoreRegistry().getStore('language');
    const { startWithTopics } = self;

    if (startWithTopics && startWithTopics.length > 0) {
      const cards = startWithTopics.slice(0, MAX_ASSET_COUNT).map((response) => {
        const {
          description,
          section,
          title,
          thumbnailUrl,
          sectionColor
        } = CommonUtils.getRecommendedAssetsData(response);
        const card = {
          label: section,
          title,
          description,
          thumbnailUrl,
          cardProps: {
            url: response.url
          },
          overlayCta: response?.completed ? { text: language.getMessage('hero.watch_it_again') } : null,
          isCompleted: response?.completed,
          styles: {
            color: sectionColor || null
          }
        };

        return card;
      });
      assets = {
        template: 'recommended_assets',
        cards
      };
    }

    return assets;
  },
  /**
   * Constructs and returns general mapped channel banner for hero channel
   *
   * @returns
   */
  getGeneralMappedChannel() {
    const language = Framework.getStoreRegistry().getStore('language');
    const CTAtext = language.getMessage('hero.study.jumpBackIn');
    const { thumbnail, title, numberOfUsers } = CommonUtils.getRecentlyUsedChannel()?.channel || {};
    const description = numberOfUsers
      ? language.getMessage('hero.study.numOfUsers').replace(
        '{num_of_users}', numberOfUsers.toLocaleString()
      )
      : null;

    const generalMappedChannel = {
      description,
      thumbnailUrl: thumbnail,
      title,
      callForAction: {
        text: CTAtext,
        ariaLabel: CTAtext
      }
    };

    return generalMappedChannel;
  }
})).actions(self => ({
  /**
   * fetch recommendation
   *
   */
  fetch(courseId, topicId) {
    this.setByChannelError(false);
    Framework.getEventManager().publish(constants.CHANNEL_RECOMMENDATIONS_BY_CHANNEL_REQUESTED,
      { courseId, topicId });
  },
  /**
   * Function to set recommendations
   *
   * @param {*} recommendations
   */
  set(recommendations) {
    const { topic, assets } = recommendations || {};
    const { assets: assetsList } = topic || {};
    let recommendedAssets = [];

    if (assetsList && assetsList.length > 0) {
      recommendedAssets = assetsList.map((assetId) => {
        if (assets[assetId]) {
          return CommonUtils.constructAssetData(assets[assetId]);
        }

        return null;
      });
    }

    applySnapshot(self, {
      startWithTopics: CommonUtils.filterAssets(recommendedAssets)
    });
    Framework.getEventManager().publish(constants.CHANNEL_RECOMMENDATIONS_BY_CHANNEL_FETCHED, {});
  },
  setByChannelError(isError = true) {
    // eslint-disable-next-line no-param-reassign
    self.isError = isError;
  },
  setError(err) {
    console.log(err, 'error in Channel recommendations by channel');
    this.setByChannelError();
    const herobanner = Framework.getStoreRegistry().getStore('herobanner');
    herobanner.setAssets(herobanner.getAssetsInitialState(), false);
  }
}));

export default ChannelRecommendationsByChannel;
