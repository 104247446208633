/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Session warning timeingout and timeout
 *
 * @file Onboarding.jsx
 * @author Mohamed yasar arabath M
 */

import React from 'react';
import PropTypes from 'prop-types';

import SessionModal from '../../common/components/sessionmodal/SessionModal';
import useCountDownTimer from '../../common/components/useCountDownTimer';
import env from '../../common/env';

const SessionWarning = ({
  data, isSessionTimeOut, onClose, location
}) => {
  const [counter] = useCountDownTimer(env.SESSION__WARNING_SECONDS);

  const renderData = () => {
    const modifyData = { ...data };
    if (!isSessionTimeOut) {
      modifyData.description = modifyData.description.replace('%TIMER%', counter.getTimeFormat());
    }

    return modifyData;
  };

  return (
    <SessionModal
      open
      data={renderData()}
      location={location}
      onClose={onClose}
    />
  );
};

SessionWarning.defaultProps = {
  onClose: () => {},
  isSessionTimeOut: false
};

SessionWarning.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    actions: PropTypes.array
  }).isRequired,
  isSessionTimeOut: PropTypes.bool,
  onClose: PropTypes.func,
  location: PropTypes.string.isRequired
};

export default SessionWarning;
